
import Vue from "vue";
import { AdsTextField, AdsButton } from "@nswdoe/doe-ui-core";
import { isPopulatedArray } from "@/utils/general";

interface Data {
  rules: Array<(val: string) => boolean | string>;
  urlLinks: Array<string>;
  pattern: RegExp;
}

interface Props {
  prefilledLinks: Array<string> | null;
  dialogShowOrHide: boolean;
}

interface Methods {
  addMoreClick(): void;
  removeLink(i: number): void;
  checkAndEmit(): void;
}
export default Vue.extend<Data, Methods, unknown, Props>({
  name: "AddLink",
  components: {
    AdsTextField,
    AdsButton,
  },
  data() {
    return {
      rules: [
        (value: string) => {
          return !!value || "url is required.";
        },
        (value: string) => {
          return this.$data.pattern.test(value) || "invalid url.";
        },
      ],
      urlLinks: [""],
      pattern: new RegExp(
        "^([a-zA-Z]+:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
      ),
    };
  },
  methods: {
    addMoreClick() {
      this.urlLinks.push("");
    },
    removeLink(i) {
      if (i > 0) {
        this.urlLinks.splice(i - 1, 1);
      }
    },
    checkAndEmit() {
      if (isPopulatedArray(this.urlLinks)) {
        const val = this.urlLinks;
        const isValid = val.every(
          (linkVal: string) => linkVal && this.pattern.test(linkVal)
        );
        if (isValid) {
          this.$emit("linksReadyToAdd", val);
        }
      }
    },
  },
  props: {
    prefilledLinks: {
      type: Array,
      default: () => {
        return [""];
      },
    },
    dialogShowOrHide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dialogShowOrHide() {
      this.urlLinks = [];
      if (isPopulatedArray(this.prefilledLinks))
        this.urlLinks = this.prefilledLinks;
    },
  },
});
