var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-image-dialog__content"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex flex-row justify-space-around px-0",attrs:{"cols":"12"}},[_c('AdsTextField',{staticClass:"grow",class:{
          'mr-6': _vm.$vuetify.breakpoint.smAndUp,
          'mr-3': _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"prepend-inner-icon":"search","label":"Search Unsplash.com","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchUnsplash.apply(null, arguments)}},model:{value:(_vm.unsplashQuery),callback:function ($$v) {_vm.unsplashQuery=$$v},expression:"unsplashQuery"}}),_c('AdsButton',{staticClass:"search-button",attrs:{"loading":_vm.loading,"buttonText":_vm.$vuetify.breakpoint.xsOnly ? '' : 'Search',"icon":"search","iconOutlined":true,"secondary":""},on:{"click":_vm.searchUnsplash}})],1)],1),_c('v-card-actions',{staticClass:"pa-0"},[(_vm.$store.getters['unsplash/photos'].length > 0)?_c('v-row',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMoreUnsplashPic),expression:"loadMoreUnsplashPic"}],ref:"images-container",staticClass:"images__container",staticStyle:{"max-height":"30vh"},attrs:{"infinite-scroll-disabled":_vm.infiniteScrollBusy,"infinite-scroll-distance":"20"}},_vm._l((_vm.$store.getters['unsplash/photos']),function(photo,index){return _c('v-col',{key:'photo_' + index,staticClass:"pa-2",attrs:{"cols":_vm.colSize.xs,"lg":_vm.colSize.lg,"md":_vm.colSize.md,"sm":_vm.colSize.sm}},[(!_vm.loading)?_c('v-btn',{staticClass:"image__wrapper",attrs:{"ripple":false,"id":`${photo.id}_${index}`,"aria-label":`${photo.alt_description} - ${
            _vm.selectedPhoto && _vm.selectedPhoto.id === photo.id
              ? 'selected'
              : 'not selected'
          }`},on:{"click":function($event){return _vm.selectImage(photo)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectImage(photo)}}},[_c('v-img',{attrs:{"id":`${photo.id}_${index}`,"aspect-ratio":1.85,"src":photo.urls.small,"width":"100%"}},[(_vm.selectedPhoto && _vm.selectedPhoto.id === photo.id)?_c('v-icon',{staticClass:"selected-photo--icon"},[_vm._v(" check ")]):_vm._e()],1)],1):_c('v-responsive',{attrs:{"aspect-ratio":1.85,"max-width":600,"max-height":600}},[_c('v-skeleton-loader',{attrs:{"type":"image"}})],1)],1)}),1):_c('span',[_vm._v("No results.")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }