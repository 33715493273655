export default [
  {
    id: "Creative arts",
    name: "Creative arts",
  },
  {
    id: "English",
    name: "English",
  },
  {
    id: "HSIE",
    name: "HSIE",
  },
  {
    id: "Languages",
    name: "Languages",
  },
  {
    id: "Mathematics",
    name: "Mathematics",
  },
  {
    id: "PDHPE",
    name: "PDHPE",
  },
  {
    id: "Science",
    name: "Science",
  },
  {
    id: "TAS",
    name: "TAS",
  },
];
