
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { isPopulatedArray, isArray } from "@/utils/general";
import {
  AdsButton,
  AdsSelect,
  AdsTextField,
  AdsTextArea,
  Dialog,
  FeatureIcon,
} from "@nswdoe/doe-ui-core";

import Suggestions from "../../Contents/Suggestions/Suggestions.vue";
import DrhResources from "../../Contents/DRHResources.vue";
import AddLink from "../../Contents/AddLink.vue";

import UploadFile from "../../Contents/UploadFiles.vue";
import GoogleDriveUI from "../../../GoogleDriveUI/GoogleDriveUI.vue";

import { Data, Methods, Computed, Props } from "./Step3.types";

import { Classroom } from "@/types/Classroom.types";
import {
  ContentItems,
  ClassroomResourceType,
} from "@/types/ClassroomContent.types";

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "ClassContent",
  components: {
    AdsButton,
    AdsSelect,
    Suggestions,
    AdsTextField,
    AdsTextArea,
    FeatureIcon,
    Dialog,
    DrhResources,
    AddLink,
    GoogleDriveUI,
    UploadFile,
  },
  data: () => ({
    contentItems: [],
    selectionToAttach: {
      drhResources: [],
      links: [],
      gDrive: [],
      uploadedFiles: [],
    },
    activeTab: 0,
    panelModel: 0,
    activeComponent: DrhResources,
    preFilledLinks: [],
    addResourceClicked: false,
    removedContent: {},
    contentTypes: [
      {
        title: ClassroomResourceType.ASSIGNMENT,
        value: ClassroomResourceType.ASSIGNMENT,
      },
      { title: ClassroomResourceType.QUIZ, value: ClassroomResourceType.QUIZ },
      {
        title: ClassroomResourceType.READING_MATERIAL,
        value: ClassroomResourceType.READING_MATERIAL,
      },
      {
        title: ClassroomResourceType.QUESTION,
        value: ClassroomResourceType.QUESTION,
      },
      {
        title: ClassroomResourceType.TOPIC,
        value: ClassroomResourceType.TOPIC,
      },
    ],
  }),
  computed: {
    classModal() {
      return this.$props.classInfo;
    },
    enableAttach() {
      return (
        this.selectionToAttach.drhResources.length > 0 ||
        this.selectionToAttach.links.length > 0 ||
        this.selectionToAttach.gDrive.length > 0 ||
        this.selectionToAttach.uploadedFiles.length > 0
      );
    },
    contentResources() {
      const content =
        this.contentItems[this.panelModel] &&
        this.contentItems[this.panelModel].content
          ? this.contentItems[this.panelModel].content
          : null;
      return !!content &&
        (content.drhResources.length ||
          content.links.length ||
          content.gDrive.length ||
          content.uploadedFiles.length)
        ? content
        : null;
    },
    contentResourcesAvailable() {
      return (
        this.contentResources &&
        (this.contentResources.drhResources.length > 0 ||
          this.contentResources.links.length > 0 ||
          this.contentResources.gDrive.length > 0 ||
          this.contentResources.uploadedFiles.length > 0)
      );
    },
  },
  methods: {
    attachSuggested(suggestedObj) {
      if (suggestedObj.isNewCategory && !!suggestedObj.categoryType) {
        this.addNewItem(suggestedObj.categoryType);
        this.panelModel = this.contentItems.length - 1;
      }
      const { drhResources } = this.contentItems[this.panelModel].content;
      if (
        isPopulatedArray(drhResources) &&
        drhResources.find(
          (resource) =>
            resource.document.ItemID ===
            suggestedObj.resourceObj[0].document.ItemID
        )
      ) {
        return;
      }
      this.readyForAttachment(suggestedObj.resourceObj);
      this.attachResourceToContent();
    },
    updateclassModalContents() {
      this.$emit("classModalChange", {
        ...this.classModal,
        contents: this.contentItems,
      });
    },
    removeAttached(index, type) {
      this.removedContent = this.contentItems[this.panelModel].content[
        type
      ].splice(index, 1)[0].document;
      this.updateclassModalContents();
    },
    readyForAttachment(resources) {
      this.selectionToAttach.drhResources = resources;
    },
    showModalForAttachment() {
      this.selectionToAttach = {
        drhResources: [],
        links: [],
        gDrive: [],
        uploadedFiles: [],
      };
      this.addResourceClicked = true;
    },
    attachGdrive(files) {
      this.selectionToAttach.gDrive = files;
    },
    attachResourceToContent() {
      this.addResourceClicked = false;
      const attachmentsForContent = Object.assign({}, this.selectionToAttach);
      let contentForPanel = Object.assign(
        {},
        this.contentItems[this.panelModel].content
      );
      Object.keys(attachmentsForContent).forEach((resourceType) => {
        const resourcesToAdd = attachmentsForContent[resourceType];
        if (!(resourcesToAdd && isArray(resourcesToAdd))) return;
        if (
          contentForPanel &&
          isPopulatedArray(contentForPanel[resourceType])
        ) {
          contentForPanel[resourceType] = [
            ...contentForPanel[resourceType],
            ...resourcesToAdd,
          ];
          if (resourceType == "links") {
            contentForPanel[resourceType] = [
              ...new Set(contentForPanel[resourceType]),
            ];
          }
          if (resourceType == "uploadedFiles") {
            contentForPanel[resourceType] = [
              ...new Set(contentForPanel[resourceType]),
            ];
          }
          if (resourceType == "gDrive") {
            contentForPanel[resourceType] = [
              ...new Set(contentForPanel[resourceType]),
            ];
          }
        } else {
          contentForPanel[resourceType] = resourcesToAdd;
        }
        if (resourceType == "links") {
          this.preFilledLinks = [
            ...new Set([...contentForPanel.links, ...resourcesToAdd]),
          ];
        }
      });
      this.contentItems[this.panelModel].content = Object.assign(
        {},
        contentForPanel
      );
      this.updateclassModalContents();
      this.selectionToAttach = {
        drhResources: [],
        links: [],
        gDrive: [],
        uploadedFiles: [],
      };
    },
    attachFromUrl(linksToAttach) {
      linksToAttach.filter((links) => !!links.trim());
      this.selectionToAttach.links = linksToAttach;
    },
    attachFromUploaded(filesToAttach) {
      this.selectionToAttach.uploadedFiles = filesToAttach;
    },
    addNewItem(itemType) {
      const itemsForContent: ContentItems = {
        id: this.contentItems.length + 1,
        content: { drhResources: [], links: [], gDrive: [], uploadedFiles: [] },
        icon: "",
        title: "",
        type: itemType,
        instructions: "",
      };
      this.contentItems.push(itemsForContent);
    },
    removeContentWrapper(e, itemId) {
      e.preventDefault();
      const indexOfContent = this.contentItems.findIndex(
        (item) => item.id == itemId
      );
      if (indexOfContent != -1) this.contentItems.splice(indexOfContent, 1);
    },
  },
  watch: {
    activeTab(val) {
      switch (val) {
        case 0:
          this.activeComponent = DrhResources;
          break;
        case 1:
          this.activeComponent = UploadFile;
          break;
        case 2:
          this.activeComponent = AddLink;
          break;
        case 3:
          this.activeComponent = GoogleDriveUI;
          break;
        default:
          this.activeComponent = DrhResources;
      }
    },
    panelModel() {
      this.preFilledLinks =
        this.contentItems[this.panelModel] &&
        this.contentItems[this.panelModel].content &&
        this.contentItems[this.panelModel].content.links
          ? this.contentItems[this.panelModel].content.links
          : [];
    },
  },
  props: {
    classInfo: {
      type: Object,
      default: () => ({}),
    } as unknown as PropValidator<Classroom>,
  },
});
