import {
  Classroom,
  ClassroomStatus,
  ClassroomType,
} from "@/types/Classroom.types";
import {
  ModalStep,
  ModalStepStatus,
  ModalStepTypes,
} from "./CreateClass.types";

import Step1 from "./Steps/Step1/Step1.vue";
import Step2 from "./Steps/Step2/Step2.vue";
import Step3 from "./Steps/Step3/Step3.vue";
import Step4 from "./Steps/Step4/Step4.vue";

export const initialClassModal: Classroom = {
  classId: "",
  schoolCode: "",
  classYearLevel: "",
  class: "",
  topics: [],
  className: "",
  classPicture: { type: "unsplash", url: "" },
  classPeople: {
    students: [],
    staffs: [],
    autoStudents: [],
    deltaStudents: [],
  },
  classType: ClassroomType.GOOGLE,
  classContents: [],
  classStatus: ClassroomStatus.DRAFT,
  canStudentComments: false,
  klas: [],
  createdBy: "",
};

/**
 * This is the list of steps in the Create Class modal.
 * It dictates the order in which the steps are displayed,
 * and the Vue component that is rendered for each step.
 */
export const appSteps: ModalStep[] = [
  {
    name: "Classroom set-up",
    status: ModalStepStatus.INCOMPLETE,
    type: ModalStepTypes.CLASSROOM_SETUP,
    description: "Class details",
    component: Step1,
    isValid: false,
  },
  {
    name: "People",
    status: ModalStepStatus.INCOMPLETE,
    type: ModalStepTypes.PEOPLE,
    description:
      "Students will be automatically synchronised to your class timetable. All changes will appear in your Google Classroom.",
    component: Step2,
    isValid: true,
  },
  {
    name: "Class Content",
    status: ModalStepStatus.INCOMPLETE,
    type: ModalStepTypes.RESOURCES,
    description: "Add content to your classroom",
    component: Step3,
    isValid: true,
  },
  {
    name: "Summary",
    status: ModalStepStatus.INCOMPLETE,
    type: ModalStepTypes.SUMMARY,
    description: "Summary of your classroom set-up",
    component: Step4,
    isValid: true,
  },
];
