import Vue from "vue";
import Vuex from "vuex";
import alerts from "./modules/alerts";
import classCards from "./modules/classCards";
import drh from "./modules/drh";
import moduleEssentials from "./modules/essentials";
import schoolsLookup from "./modules/schoolsLookup";
import moduleUnsplash from "./modules/unsplash";
import userAttributes from "./modules/userAttributes";
import api from "../api";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    classCards,
    userAttributes,
    moduleEssentials,
    schoolsLookup,
    unsplash: moduleUnsplash,
    drh,
    alerts: alerts,
  },
  state: {
    isLoading: false,
    uploadedFileUrls: [] as unknown as string[],
    googleAuthToken: "",
  },
  getters: {
    uploadedFileUrls: (state) => state.uploadedFileUrls,
    googleAuthToken: (state) => state.googleAuthToken,
  },
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    RESET_UPLOAD(state) {
      state.uploadedFileUrls = [];
    },
    SET_FILES(state, uploadedUrl: string) {
      state.uploadedFileUrls.push(uploadedUrl);
    },
    SET_TOKEN(state, token: string) {
      state.googleAuthToken = token;
    },
  },
  actions: {
    getGoogleAuthToken({ commit, rootState }) {
      if (!rootState.isLoading) commit("SET_IS_LOADING", true);
      api.googleDriveApi
        .getGoogleAuthToken()
        .then((res) => {
          if (res.data && res.data.token) {
            commit("SET_TOKEN", res.data.token);
          }
        })
        .catch((e: Error) => {
          throw e;
        })
        .finally(() => {
          commit("SET_IS_LOADING", false);
        });
    },
    setLoader({ commit }, isLoading) {
      commit("SET_IS_LOADING", isLoading);
    },
    resetUpload({ commit }) {
      commit("RESET_UPLOAD");
    },
  },
});
