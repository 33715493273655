/**
 * These are all the actions a user might be capable of
 * performing on a Classroom, the availability of these actions
 * are determined by business rules though.
 */
export enum ClassroomAction {
  CREATE = "CREATE",
  SHARE = "SHARE",
  EDIT = "EDIT",
  DUPLICATE = "DUPLICATE",
  ARCHIVE = "ARCHIVE",
  DELETE = "DELETE",
}
