
import Vue from "vue";
import { mapGetters } from "vuex";

import { AdsButton, AdsTextField } from "@nswdoe/doe-ui-core";

import { ClassroomType } from "@/types/Classroom.types";
import { ClassroomAction } from "@/types/ClassroomActions.types";

export default Vue.extend({
  watch: {
    searchField(newValue) {
      this.$emit("search:update", newValue);
    },
  },
  data: () => ({
    showMenu: false,
    searchField: "",
    newClassItems: [
      { title: "Google Classroom", type: ClassroomType.GOOGLE },
      { title: "Microsoft Teams Classroom", type: ClassroomType.MICROSOFT },
    ],
    archiveActionItems: [
      {
        title: "Duplicate selected",
        action: ClassroomAction.DUPLICATE,
      },
    ],
  }),
  methods: {
    archiveSelected() {
      this.$emit("classrooms:archive", this.selectedClassCards);
    },
    startCreateClass(type: ClassroomType) {
      this.$emit("classrooms:create", type);
    },
    duplicateClasses() {
      this.$emit("classrooms:duplicate");
    },
    performAction(action: ClassroomAction) {
      this.showMenu = false;
      switch (action) {
        case ClassroomAction.DUPLICATE:
          this.duplicateClasses();
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      hasAdminAccess: "userAttributes/hasAdminAccess",
    }),
    showActions(): boolean {
      return (
        this.hasAdminAccess &&
        this.selectedClassCards &&
        Array.isArray(this.selectedClassCards) &&
        this.selectedClassCards.length > 0
      );
    },
  },
  props: {
    mode: {
      type: String,
      default: () => "",
    },
    selectedClassCards: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AdsTextField,
    AdsButton,
  },
});
