import topics from "./topics";
export default {
  topics,
  classRoll: [
    {
      year: "Year 1",
      rollClass: [
        {
          classId: "1A",
          students: [
            {
              studentGivenName: "Fname4898",
              studentMiddleName: "Oname4898",
              studentFamilyName: "Lname4898",
              studentPreferredGivenName: "Fname4898",
              studentPreferredFamilyName: "Lname4898",
              studentNswEducationIdentifier: "John.Doe",
              studentNswEducationemailaddress: "4898@det.nsw.edu.au",
            },
            {
              studentGivenName: "test",
              studentMiddleName: "test",
              studentFamilyName: "test",
              studentPreferredGivenName: "test",
              studentPreferredFamilyName: "ste",
              studentNswEducationIdentifier: "Jsohn.Doe",
              studentNswEducationemailaddress: "4898439057@det.nsw.edu.au",
            },
          ],

          staff: [
            {
              staffGivenName: "Fname4898",
              staffMiddleName: "Oname4898",
              staffFamilyName: "Lname4898",
              staffPreferredGivenName: "Fname4898",
              staffPreferredFamilyName: "Lname4898",
              staffNswEducationIdentifier: "John.Doe",
              staffNswEducationemailaddress: "4898@det.nsw.edu.au",
            },
          ],
        },
        { classId: "1B", students: [], staff: [] },
        { classId: "1C", students: [], staff: [] },
      ],
    },
    {
      year: "Year 3",
      rollClass: [
        {
          classId: "9A",

          students: [
            {
              studentGivenName: "Fname4898",
              studentMiddleName: "Oname4898",
              studentFamilyName: "Lname4898",
              studentPreferredGivenName: "Fname4898",
              studentPreferredFamilyName: "Lname4898",
              studentNswEducationIdentifier: "John.Doe",
              studentNswEducationemailaddress: "4898@det.nsw.edu.au",
            },
            {
              studentGivenName: "test",
              studentMiddleName: "test",
              studentFamilyName: "test",
              studentPreferredGivenName: "test",
              studentPreferredFamilyName: "ste",
              studentNswEducationIdentifier: "Jsohn.Doe",
              studentNswEducationemailaddress: "4898439057@det.nsw.edu.au",
            },
          ],

          staff: [
            {
              studentGivenName: "Fname4898",
              studentMiddleName: "Oname4898",
              studentFamilyName: "Lname4898",
              studentPreferredGivenName: "Fname4898",
              studentPreferredFamilyName: "Lname4898",
              studentNswEducationIdentifier: "John.Doe",
              studentNswEducationemailaddress: "4898@det.nsw.edu.au",
            },
          ],
        },
        { classId: "9B", students: [], staff: [] },
        { classId: "9C", students: [], staff: [] },
      ],
    },
  ],
  learningArea: [
    {
      id: "Creative arts",
      name: "Creative arts",
    },
    {
      id: "English",
      name: "English",
    },
    {
      id: "HSIE",
      name: "HSIE",
    },
    {
      id: "Languages",
      name: "Languages",
    },
    {
      id: "Mathematics",
      name: "Mathematics",
    },
    {
      id: "PDHPE",
      name: "PDHPE",
    },
    {
      id: "Science",
      name: "Science",
    },
    {
      id: "TAS",
      name: "TAS",
    },
  ],
};
