export default {
  urhResult: {
    meta: { page: "1", itemsReturned: 8, count: 1695, totalPages: 212 },
    dslQuery: {
      from: 0,
      size: 8,
      sort: [{ "document.DateModified": "desc" }],
      query: {
        bool: {
          should: [
            {
              bool: {
                minimum_should_match: 1,
                should: [{ terms: { "record.published": ["true"] } }],
              },
            },
            {
              bool: {
                minimum_should_match: 1,
                should: [{ terms: { "document.isHomePage": ["true"] } }],
              },
            },
          ],
        },
      },
    },
    resources: [
      {
        professionalLearning: [],
        document: {
          KLA: ["PDHPE"],
          Description:
            "<p>This lesson is designed to get students to explore five simple online safety topics using video, peer discussion and student-centred tasks. The activity is designed to improve engagement and online safety learning outcomes.</p>",
          Keywords: [
            "online safety",
            "digital citizenship",
            "PDHPE",
            "stage 2",
            "resilience",
            "risk",
            "online",
            "technology",
            "cyber",
            "security",
            "kind",
            "secure",
            "strong",
            "safe",
            "share",
          ],
          Phase: [
            {
              phase: "Planning and programming",
              id: "tlcp.planningandprogramming",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-07-20T05:06:51",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus:
                    "Personal Development, Health and Physical Education K-10",
                  name: "explains and uses strategies to develop resilience and to make them feel comfortable and safe",
                  id: "PD2-2",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-2",
                },
                {
                  syllabus:
                    "Personal Development, Health and Physical Education K-10",
                  name: "demonstrates self-management skills to respond to their own and others' actions",
                  id: "PD2-9",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-9",
                },
              ],
              name: "Personal Development, Health and Physical Education K-10",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "curriculum",
          Name: "Be a Cybermarvel: Be safe online Stage 2",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Document",
          RandNTopic: [],
          SIPTerms: [],
          LitLearnProgLevelV3: [],
          FocusArea: [
            {
              channel: "Curriculum",
              id: "curriculum.cybersafety",
              ownerid: "curriculum",
              focusArea: "Cyber safety",
            },
          ],
          NumLearnProgLevel: [],
          Stage: ["2"],
          NumLearnProgLevelV3: [],
          QAAssured: true,
          Topic: ["Cyberbullying"],
          PLLinks: [],
          ResourceURL:
            "https://education.nsw.gov.au/content/dam/main-education/inside-the-department/technology/media/documents/Be_a_Cybermarvel_-_be_safe_online.docx",
          DateResCreated: "2022-07-20T05:06:51",
          ResourceType: "lessonplan",
          SubSource: "",
          Publisher: "NSW Department of Education",
          SEFAlignment: [
            { domain: "Learning", id: "learn.wellbeing", element: "Wellbeing" },
            {
              domain: "Learning",
              id: "learn.curriculum",
              element: "Curriculum",
            },
          ],
          ItemID: "CUR-MS220603123756",
          AusCurrContDesc: [],
          RelatedResources: [
            {
              name: "Be a Cybermarvel - be kind online",
              id: "CUR-MS220603121346",
              url: "",
            },
          ],
          Year: ["3", "4"],
          LitLearnProgLevel: [],
          SchoolType: ["Primary Schools", "Central Schools"],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-26T08:13:51",
        },
        archive: [
          {
            collections: [
              {
                rcsId: "ba69fb03-71d4-4ea3-9d8a-a4f7b94d3084",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2022-11-11T04:52:17Z",
              collection: "curriculum",
              id: "CUR-MS220603123756",
              published: true,
              version: 3,
            },
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to explore five simple online safety topics using video, peer discussion and student-centred tasks. The activity is designed to improve engagement and online safety learning outcomes.",
              Keywords: [
                "online safety",
                "digital citizenship",
                "PDHPE",
                "stage 2",
                "resilience",
                "risk",
                "online",
                "technology",
                "cyber",
                "security",
                "kind",
                "secure",
                "strong",
                "safe",
                "share",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: null,
              DateCreated: "2022-07-20T05:06:51",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "explains and uses strategies to develop resilience and to make them feel comfortable and safe",
                      id: "PD2-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "demonstrates self-management skills to respond to their own and others' actions",
                      id: "PD2-9",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-9",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel - be safe online",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_safe_online.docx",
              DateResCreated: "2022-07-20T05:06:51",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603123756",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["3", "4"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-08-01T01:25:06",
            },
            draft: {},
            subResources: [],
            misc: {
              Views: 292,
              SharedToClassroom: 2,
              GoToResource: 172,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "cigdem.basak"],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "ba69fb03-71d4-4ea3-9d8a-a4f7b94d3084",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {},
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to explore five simple online safety topics using video, peer discussion and student-centred tasks. The activity is designed to improve engagement and online safety learning outcomes.",
              Keywords: [
                "online safety",
                "digital citizenship",
                "PDHPE",
                "stage 2",
                "resilience",
                "risk",
                "online",
                "technology",
                "cyber",
                "security",
                "kind",
                "secure",
                "strong",
                "safe",
                "share",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-07-20T05:06:51",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "explains and uses strategies to develop resilience and to make them feel comfortable and safe",
                      id: "PD2-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "demonstrates self-management skills to respond to their own and others' actions",
                      id: "PD2-9",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-9",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be safe online",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["2"],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_safe_online.docx",
              DateResCreated: "2022-07-20T05:06:51",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603123756",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["3", "4"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:45:34",
            },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2022-12-05T03:45:34Z",
              collection: "curriculum",
              id: "CUR-MS220603123756",
              published: true,
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 292,
              SharedToClassroom: 2,
              GoToResource: 172,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "cigdem.basak"],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "ba69fb03-71d4-4ea3-9d8a-a4f7b94d3084",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
              {
                rcsId: "847a780a-27f0-42ba-beec-eed6c2ed5a3f",
                collectionId: {
                  application: "urh",
                  id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
                  source: "collections",
                },
              },
              {
                rcsId: "fef5a213-d2f0-4fb4-9ee9-882a5e26cf06",
                collectionId: {
                  application: "urh",
                  id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
                  source: "collections",
                },
              },
              {
                rcsId: "026e063f-4713-4ee5-9684-4e429449f7d4",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "b69e3d4e-5718-402b-872c-4cf8340e3a4b",
                collectionId: {
                  application: "urh",
                  id: "876e8df2-28bf-4680-805a-be8667575add",
                  source: "collections",
                },
              },
              {
                rcsId: "7434bc4e-ba61-403f-986d-da82ef02c93a",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
              {
                rcsId: "f1a222e4-9b4c-4d4d-84af-d0c00906c8c1",
                collectionId: {
                  application: "urh",
                  id: "5aa5ec8c-b123-4b66-8952-571f12f0cd57",
                  source: "collections",
                },
              },
              {
                rcsId: "73b4d8d6-d712-4cf5-b88f-3478f062d725",
                collectionId: {
                  application: "urh",
                  id: "f8406038-ac54-4a67-a360-d41beafaaed2",
                  source: "collections",
                },
              },
              {
                rcsId: "de465901-22df-4321-beea-99fdd563879d",
                collectionId: {
                  application: "urh",
                  id: "99b08a22-9afe-4b6d-9175-3f3723b8f6ff",
                  source: "collections",
                },
              },
              {
                rcsId: "84b62150-e7ef-4d28-a085-7ccb283eda8a",
                collectionId: {
                  application: "urh",
                  id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
                  source: "collections",
                },
              },
              {
                rcsId: "0d4dceab-7109-4a1d-b251-3e122d3da2cf",
                collectionId: {
                  application: "urh",
                  id: "42c70174-4dc8-4295-a24b-d867f31e4ea2",
                  source: "collections",
                },
              },
              {
                rcsId: "76393c06-6054-4228-9629-d2e0003ea247",
                collectionId: {
                  application: "urh",
                  id: "ea4c07a4-1a1a-47eb-afa1-a0bedd7cf235",
                  source: "collections",
                },
              },
              {
                rcsId: "8eb5198b-764b-441a-bac1-34130eaf9211",
                collectionId: {
                  application: "urh",
                  id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
                  source: "collections",
                },
              },
              {
                rcsId: "0af86a6d-ff64-44b8-aa24-4427ee4ef41b",
                collectionId: {
                  application: "urh",
                  id: "372a9d19-7373-4aa0-a650-1eb24c3447d1",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "michelle.chea", iss: "urh" },
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to explore five simple online safety topics using video, peer discussion and student-centred tasks. The activity is designed to improve engagement and online safety learning outcomes.",
              Keywords: [
                "online safety",
                "digital citizenship",
                "PDHPE",
                "stage 2",
                "resilience",
                "risk",
                "online",
                "technology",
                "cyber",
                "security",
                "kind",
                "secure",
                "strong",
                "safe",
                "share",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-07-20T05:06:51",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "explains and uses strategies to develop resilience and to make them feel comfortable and safe",
                      id: "PD2-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "demonstrates self-management skills to respond to their own and others' actions",
                      id: "PD2-9",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD2-9",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be safe online Stage 2",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["2"],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_safe_online.docx",
              DateResCreated: "2022-07-20T05:06:51",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603123756",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["3", "4"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:51:59",
            },
            draft: { LitLearnProgLevelV3: [], NumLearnProgLevelV3: [] },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2023-03-01T21:26:56Z",
              collection: "curriculum",
              id: "CUR-MS220603123756",
              published: true,
              version: 18,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 659,
              SharedToClassroom: 2,
              GoToResource: 390,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "cigdem.basak"],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "ba69fb03-71d4-4ea3-9d8a-a4f7b94d3084",
            collectionId: {
              application: "urh",
              id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
              source: "collections",
            },
          },
          {
            rcsId: "847a780a-27f0-42ba-beec-eed6c2ed5a3f",
            collectionId: {
              application: "urh",
              id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
              source: "collections",
            },
          },
          {
            rcsId: "fef5a213-d2f0-4fb4-9ee9-882a5e26cf06",
            collectionId: {
              application: "urh",
              id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
              source: "collections",
            },
          },
          {
            rcsId: "026e063f-4713-4ee5-9684-4e429449f7d4",
            collectionId: {
              application: "urh",
              id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
              source: "collections",
            },
          },
          {
            rcsId: "b69e3d4e-5718-402b-872c-4cf8340e3a4b",
            collectionId: {
              application: "urh",
              id: "876e8df2-28bf-4680-805a-be8667575add",
              source: "collections",
            },
          },
          {
            rcsId: "7434bc4e-ba61-403f-986d-da82ef02c93a",
            collectionId: {
              application: "urh",
              id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
              source: "collections",
            },
          },
          {
            rcsId: "f1a222e4-9b4c-4d4d-84af-d0c00906c8c1",
            collectionId: {
              application: "urh",
              id: "5aa5ec8c-b123-4b66-8952-571f12f0cd57",
              source: "collections",
            },
          },
          {
            rcsId: "73b4d8d6-d712-4cf5-b88f-3478f062d725",
            collectionId: {
              application: "urh",
              id: "f8406038-ac54-4a67-a360-d41beafaaed2",
              source: "collections",
            },
          },
          {
            rcsId: "de465901-22df-4321-beea-99fdd563879d",
            collectionId: {
              application: "urh",
              id: "99b08a22-9afe-4b6d-9175-3f3723b8f6ff",
              source: "collections",
            },
          },
          {
            rcsId: "84b62150-e7ef-4d28-a085-7ccb283eda8a",
            collectionId: {
              application: "urh",
              id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
              source: "collections",
            },
          },
          {
            rcsId: "0d4dceab-7109-4a1d-b251-3e122d3da2cf",
            collectionId: {
              application: "urh",
              id: "42c70174-4dc8-4295-a24b-d867f31e4ea2",
              source: "collections",
            },
          },
          {
            rcsId: "76393c06-6054-4228-9629-d2e0003ea247",
            collectionId: {
              application: "urh",
              id: "ea4c07a4-1a1a-47eb-afa1-a0bedd7cf235",
              source: "collections",
            },
          },
          {
            rcsId: "8eb5198b-764b-441a-bac1-34130eaf9211",
            collectionId: {
              application: "urh",
              id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
              source: "collections",
            },
          },
          {
            rcsId: "0af86a6d-ff64-44b8-aa24-4427ee4ef41b",
            collectionId: {
              application: "urh",
              id: "372a9d19-7373-4aa0-a650-1eb24c3447d1",
              source: "collections",
            },
          },
        ],
        audit: { sub: "michelle.chea", iss: "urh" },
        draft: {},
        record: {
          created: "2022-09-15T16:21:00",
          modified: "2023-03-26T08:13:50Z",
          collection: "curriculum",
          id: "CUR-MS220603123756",
          published: true,
          version: 19,
        },
        userAccess: {
          owner: ["michelle.chea"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 0,
        },
        files: [],
        links: [],
        misc: {
          Views: 660,
          SharedToClassroom: 2,
          GoToResource: 392,
          SharedToTeams: 2,
          Likes: 2,
          LikedBy: ["mona.sidhu", "cigdem.basak"],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["PDHPE"],
          Description:
            "<p>This lesson is designed to get students to learn and discuss the signs of cyberbullying and to explore their feelings if they see or experience this behaviour. It is important for students to learn how to identify and manage cyberbullying behaviours, and to build their resilience to cyberbullying. This activity also supports building respectful and kind behaviours through the use of engaging classroom activities.</p>",
          Keywords: [
            "be kind online",
            "cyberbullying",
            "kindness",
            "PDHPE",
            "stage 3",
            "negative online behaviours",
            "upstander",
            "digital citizenship",
            "online safety",
            "resilience",
            "cyber",
            "inclusive",
            "empathy",
          ],
          Phase: [
            {
              phase: "Planning and programming",
              id: "tlcp.planningandprogramming",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-07-20T04:58:47",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus:
                    "Personal Development, Health and Physical Education K-10",
                  name: "investigates information, community resources and strategies to demonstrate resilience and seek help for themselves and others",
                  id: "PD3-2",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-2",
                },
                {
                  syllabus:
                    "Personal Development, Health and Physical Education K-10",
                  name: "evaluates the impact of empathy, inclusion and respect on themselves and others",
                  id: "PD3-3",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-3",
                },
              ],
              name: "Personal Development, Health and Physical Education K-10",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "curriculum",
          Name: "Be a Cybermarvel: Be kind online Stage 3",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Document",
          RandNTopic: [],
          SIPTerms: [],
          LitLearnProgLevelV3: [],
          FocusArea: [
            {
              channel: "Curriculum",
              id: "curriculum.cybersafety",
              ownerid: "curriculum",
              focusArea: "Cyber safety",
            },
          ],
          NumLearnProgLevel: [],
          Stage: ["3"],
          NumLearnProgLevelV3: [],
          QAAssured: true,
          Topic: ["Cyberbullying"],
          PLLinks: [],
          ResourceURL:
            "https://education.nsw.gov.au//content/dam/main-education/inside-the-department/technology/media/documents/Be_a_Cybermarvel_-_be_kind_online.docx",
          DateResCreated: "2022-07-20T04:58:47",
          ResourceType: "lessonplan",
          SubSource: "",
          Publisher: "NSW Department of Education",
          SEFAlignment: [
            { domain: "Learning", id: "learn.wellbeing", element: "Wellbeing" },
            {
              domain: "Learning",
              id: "learn.curriculum",
              element: "Curriculum",
            },
          ],
          ItemID: "CUR-MS220603121346",
          AusCurrContDesc: [],
          RelatedResources: [
            {
              name: "Be a Cybermarvel - be safe online",
              id: "CUR-MS220603123756",
              url: "",
            },
          ],
          Year: ["5", "6"],
          LitLearnProgLevel: [],
          SchoolType: ["Primary Schools", "Central Schools"],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-26T08:13:22",
        },
        archive: [
          {
            collections: [
              {
                rcsId: "32f5e933-8783-4545-b700-f5afa871b359",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
              {
                rcsId: "12f2fc89-3bf8-44e3-9d67-2aeb9161782d",
                collectionId: {
                  application: "urh",
                  id: "11a973a7-9bfb-4c85-8133-1830de0d70a1",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2022-11-22T03:39:59Z",
              collection: "curriculum",
              id: "CUR-MS220603121346",
              published: true,
              version: 3,
            },
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to learn and discuss the signs of cyberbullying and to explore their feelings if they see or experience this behaviour. It is important for students to learn how to identify and manage cyberbullying behaviours, and to build their resilience to cyberbullying. This activity also supports building respectful and kind behaviours through the use of engaging classroom activities.",
              Keywords: [
                "be kind online",
                "cyberbullying",
                "kindness",
                "PDHPE",
                "stage 3",
                "negative online behaviours",
                "upstander",
                "digital citizenship",
                "online safety",
                "resilience",
                "cyber",
                "inclusive",
                "empathy",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: null,
              DateCreated: "2022-07-20T04:58:47",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "investigates information, community resources and strategies to demonstrate resilience and seek help for themselves and others",
                      id: "PD3-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "evaluates the impact of empathy, inclusion and respect on themselves and others",
                      id: "PD3-3",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-3",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel - be kind online",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_kind_online.docx",
              DateResCreated: "2022-07-20T04:58:47",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603121346",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-08-01T01:23:52",
            },
            draft: {},
            subResources: [],
            misc: {
              Views: 324,
              SharedToClassroom: 2,
              GoToResource: 176,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "lisa.dunn16"],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "32f5e933-8783-4545-b700-f5afa871b359",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
              {
                rcsId: "12f2fc89-3bf8-44e3-9d67-2aeb9161782d",
                collectionId: {
                  application: "urh",
                  id: "11a973a7-9bfb-4c85-8133-1830de0d70a1",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {},
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to learn and discuss the signs of cyberbullying and to explore their feelings if they see or experience this behaviour. It is important for students to learn how to identify and manage cyberbullying behaviours, and to build their resilience to cyberbullying. This activity also supports building respectful and kind behaviours through the use of engaging classroom activities.",
              Keywords: [
                "be kind online",
                "cyberbullying",
                "kindness",
                "PDHPE",
                "stage 3",
                "negative online behaviours",
                "upstander",
                "digital citizenship",
                "online safety",
                "resilience",
                "cyber",
                "inclusive",
                "empathy",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-07-20T04:58:47",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "investigates information, community resources and strategies to demonstrate resilience and seek help for themselves and others",
                      id: "PD3-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "evaluates the impact of empathy, inclusion and respect on themselves and others",
                      id: "PD3-3",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-3",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be kind online",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_kind_online.docx",
              DateResCreated: "2022-07-20T04:58:47",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603121346",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:45:04",
            },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2022-12-05T03:45:04Z",
              collection: "curriculum",
              id: "CUR-MS220603121346",
              published: true,
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 324,
              SharedToClassroom: 2,
              GoToResource: 176,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "lisa.dunn16"],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "32f5e933-8783-4545-b700-f5afa871b359",
                collectionId: {
                  application: "urh",
                  id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
                  source: "collections",
                },
              },
              {
                rcsId: "12f2fc89-3bf8-44e3-9d67-2aeb9161782d",
                collectionId: {
                  application: "urh",
                  id: "11a973a7-9bfb-4c85-8133-1830de0d70a1",
                  source: "collections",
                },
              },
              {
                rcsId: "c1e6c7ed-a10e-4d5d-8af3-a3a677efc262",
                collectionId: {
                  application: "urh",
                  id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
                  source: "collections",
                },
              },
              {
                rcsId: "7cc94c0b-fb98-4a44-b2c9-b1f82ad28fbc",
                collectionId: {
                  application: "urh",
                  id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
                  source: "collections",
                },
              },
              {
                rcsId: "35cf3ff6-9407-419e-955e-85540dcef147",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "6546ff41-8ffb-4206-a615-4222f998d53c",
                collectionId: {
                  application: "urh",
                  id: "876e8df2-28bf-4680-805a-be8667575add",
                  source: "collections",
                },
              },
              {
                rcsId: "942886b4-8b0c-47ed-803f-c0c7dcf9410d",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
              {
                rcsId: "d658144a-a9b7-4b4b-9f0d-d27df63d4dea",
                collectionId: {
                  application: "urh",
                  id: "adfeb75b-1d84-4c22-90e4-0e5966232fae",
                  source: "collections",
                },
              },
              {
                rcsId: "901180e3-3ff5-45e7-885d-5bdbb7b92e3a",
                collectionId: {
                  application: "urh",
                  id: "fb5001a7-e7be-47ae-9252-c700ad8a84d1",
                  source: "collections",
                },
              },
              {
                rcsId: "54057da6-1604-480f-83ef-245066ffda27",
                collectionId: {
                  application: "urh",
                  id: "a68ff56b-88c4-414b-8f59-2932a6a41fe0",
                  source: "collections",
                },
              },
              {
                rcsId: "ffab7ce8-feb9-4289-ba22-3983a3289e27",
                collectionId: {
                  application: "urh",
                  id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
                  source: "collections",
                },
              },
              {
                rcsId: "3e848485-467d-4637-9ce0-217b8ef984f7",
                collectionId: {
                  application: "urh",
                  id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
                  source: "collections",
                },
              },
              {
                rcsId: "2b5b0689-c4d3-4ba4-9d09-29bfd3b70a87",
                collectionId: {
                  application: "urh",
                  id: "9ee0482f-7266-4774-9b87-83e9b2591ee1",
                  source: "collections",
                },
              },
              {
                rcsId: "ec5feec6-3451-4b5c-8df5-3daa9fa7d874",
                collectionId: {
                  application: "urh",
                  id: "6449fd1b-047c-4c6e-8c4f-e7be142ddd4b",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "michelle.chea", iss: "urh" },
            document: {
              KLA: ["PDHPE"],
              Description:
                "This lesson is designed to get students to learn and discuss the signs of cyberbullying and to explore their feelings if they see or experience this behaviour. It is important for students to learn how to identify and manage cyberbullying behaviours, and to build their resilience to cyberbullying. This activity also supports building respectful and kind behaviours through the use of engaging classroom activities.",
              Keywords: [
                "be kind online",
                "cyberbullying",
                "kindness",
                "PDHPE",
                "stage 3",
                "negative online behaviours",
                "upstander",
                "digital citizenship",
                "online safety",
                "resilience",
                "cyber",
                "inclusive",
                "empathy",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-07-20T04:58:47",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "investigates information, community resources and strategies to demonstrate resilience and seek help for themselves and others",
                      id: "PD3-2",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-2",
                    },
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "evaluates the impact of empathy, inclusion and respect on themselves and others",
                      id: "PD3-3",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD3-3",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be kind online Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: [],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: ["Cyberbullying"],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_be_kind_online.docx",
              DateResCreated: "2022-07-20T04:58:47",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
              ],
              ItemID: "CUR-MS220603121346",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a Cybermarvel - be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: ["Primary Schools", "Central Schools"],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:51:37",
            },
            draft: { LitLearnProgLevelV3: [], NumLearnProgLevelV3: [] },
            record: {
              created: "2022-09-15T16:21:00",
              modified: "2023-03-16T23:09:13Z",
              collection: "curriculum",
              id: "CUR-MS220603121346",
              published: true,
              version: 20,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 664,
              SharedToClassroom: 2,
              GoToResource: 390,
              SharedToTeams: 2,
              Likes: 2,
              LikedBy: ["mona.sidhu", "lisa.dunn16"],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "32f5e933-8783-4545-b700-f5afa871b359",
            collectionId: {
              application: "urh",
              id: "ee3da1ad-d427-42f7-b48e-23f0c04772dd",
              source: "collections",
            },
          },
          {
            rcsId: "12f2fc89-3bf8-44e3-9d67-2aeb9161782d",
            collectionId: {
              application: "urh",
              id: "11a973a7-9bfb-4c85-8133-1830de0d70a1",
              source: "collections",
            },
          },
          {
            rcsId: "c1e6c7ed-a10e-4d5d-8af3-a3a677efc262",
            collectionId: {
              application: "urh",
              id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
              source: "collections",
            },
          },
          {
            rcsId: "7cc94c0b-fb98-4a44-b2c9-b1f82ad28fbc",
            collectionId: {
              application: "urh",
              id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
              source: "collections",
            },
          },
          {
            rcsId: "35cf3ff6-9407-419e-955e-85540dcef147",
            collectionId: {
              application: "urh",
              id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
              source: "collections",
            },
          },
          {
            rcsId: "6546ff41-8ffb-4206-a615-4222f998d53c",
            collectionId: {
              application: "urh",
              id: "876e8df2-28bf-4680-805a-be8667575add",
              source: "collections",
            },
          },
          {
            rcsId: "942886b4-8b0c-47ed-803f-c0c7dcf9410d",
            collectionId: {
              application: "urh",
              id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
              source: "collections",
            },
          },
          {
            rcsId: "d658144a-a9b7-4b4b-9f0d-d27df63d4dea",
            collectionId: {
              application: "urh",
              id: "adfeb75b-1d84-4c22-90e4-0e5966232fae",
              source: "collections",
            },
          },
          {
            rcsId: "901180e3-3ff5-45e7-885d-5bdbb7b92e3a",
            collectionId: {
              application: "urh",
              id: "fb5001a7-e7be-47ae-9252-c700ad8a84d1",
              source: "collections",
            },
          },
          {
            rcsId: "54057da6-1604-480f-83ef-245066ffda27",
            collectionId: {
              application: "urh",
              id: "a68ff56b-88c4-414b-8f59-2932a6a41fe0",
              source: "collections",
            },
          },
          {
            rcsId: "ffab7ce8-feb9-4289-ba22-3983a3289e27",
            collectionId: {
              application: "urh",
              id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
              source: "collections",
            },
          },
          {
            rcsId: "3e848485-467d-4637-9ce0-217b8ef984f7",
            collectionId: {
              application: "urh",
              id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
              source: "collections",
            },
          },
          {
            rcsId: "2b5b0689-c4d3-4ba4-9d09-29bfd3b70a87",
            collectionId: {
              application: "urh",
              id: "9ee0482f-7266-4774-9b87-83e9b2591ee1",
              source: "collections",
            },
          },
          {
            rcsId: "ec5feec6-3451-4b5c-8df5-3daa9fa7d874",
            collectionId: {
              application: "urh",
              id: "6449fd1b-047c-4c6e-8c4f-e7be142ddd4b",
              source: "collections",
            },
          },
        ],
        audit: { sub: "michelle.chea", iss: "urh" },
        draft: {},
        record: {
          created: "2022-09-15T16:21:00",
          modified: "2023-03-26T08:13:20Z",
          collection: "curriculum",
          id: "CUR-MS220603121346",
          published: true,
          version: 21,
        },
        userAccess: {
          owner: ["michelle.chea"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 0,
        },
        files: [],
        links: [],
        misc: {
          Views: 669,
          SharedToClassroom: 2,
          GoToResource: 393,
          SharedToTeams: 2,
          Likes: 2,
          LikedBy: ["mona.sidhu", "lisa.dunn16"],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["English", "TAS", "PDHPE"],
          Description:
            "<p>This three-lesson learning sequence is designed to teach students about how content is created and used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.</p>",
          Keywords: [
            "cybersafety",
            "digital literacy",
            "fake news",
            "internet",
            "online safet",
            "digital citizenship",
            "english",
            "stage 3",
            "risk",
            "online",
            "security",
            "safety",
            "cyber",
          ],
          Phase: [
            {
              phase: "Planning and programming",
              id: "tlcp.planningandprogramming",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-12-05T03:44:05",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "English K-10",
                  name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                  id: "EN4-1A",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                },
                {
                  syllabus: "English K-10",
                  name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                  id: "EN4-2A",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                },
                {
                  syllabus: "English K-10",
                  name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                  id: "EN4-3B",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                },
              ],
              name: "English K-10",
            },
            {
              outcomes: [
                {
                  syllabus:
                    "Personal Development, Health and Physical Education K-10",
                  name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                  id: "PD4-10",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                },
              ],
              name: "Personal Development, Health and Physical Education K-10",
            },
            {
              outcomes: [
                {
                  syllabus: "Technology Mandatory 7-8",
                  name: "explains how people in technology related professions contribute to society now and into the future",
                  id: "TE4-10TS",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                },
              ],
              name: "Technology Mandatory 7-8",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "curriculum",
          Name: "Be a Cybermarvel: Be a digital leader Stage 4",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Document",
          RandNTopic: [],
          SIPTerms: ["Lesson planning", "Literacy", "Technology"],
          LitLearnProgLevelV3: [],
          FocusArea: [
            {
              channel: "Curriculum",
              id: "curriculum.cybersafety",
              ownerid: "curriculum",
              focusArea: "Cyber safety",
            },
          ],
          NumLearnProgLevel: [],
          Stage: ["4"],
          links: [],
          NumLearnProgLevelV3: [],
          QAAssured: true,
          Topic: [
            "Digital reputation",
            "Information literacy",
            "Internet safety",
          ],
          PLLinks: [],
          ResourceURL:
            "https://education.nsw.gov.au/content/dam/main-education/inside-the-department/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader.docx",
          professionalLearning: [],
          DateResCreated: "2022-12-05T03:44:05",
          ResourceType: "lessonplan",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [
            {
              domain: "Learning",
              id: "learn.curriculum",
              element: "Curriculum",
            },
            { domain: "Learning", id: "learn.wellbeing", element: "Wellbeing" },
          ],
          ItemID: "CUR-MS221122091543",
          AusCurrContDesc: [],
          RelatedResources: [
            {
              name: "Be a cybermarvel: Be a digital detective",
              id: "CUR-MS221005133739",
              url: "",
            },
            {
              name: "Be a cybermarvel: Be safe online",
              id: "CUR-MS220603123756",
              url: "",
            },
            {
              name: "Be a cybermarvel: Be kind online",
              id: "CUR-MS220603121346",
              url: "",
            },
          ],
          Year: ["7", "8"],
          LitLearnProgLevel: [],
          SchoolType: [
            "Secondary Schools",
            "Central Schools",
            "Schools for Specific Purposes",
          ],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-26T08:12:28",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and<br>used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is to be used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:44:05",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2022-12-05T03:44:05Z",
              collection: "curriculum",
              published: false,
              id: "CUR-MS221122091543",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and<br>used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is to be used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:44:05",
            },
            document: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and<br>used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is to be used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:44:05",
            },
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2022-12-05T03:49:41Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221122091543",
              version: 1,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            document: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and<br>used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is to be used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader Stage 4",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:51:13",
            },
            draft: {},
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2022-12-05T03:51:13Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221122091543",
              version: 2,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 5,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            document: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader Stage 4",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T04:42:32",
            },
            draft: {},
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2022-12-05T04:42:32Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221122091543",
              version: 3,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 6,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "0d236352-62f6-492e-97f1-d08094bde165",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "eb684bdd-528a-44ba-b393-ddd5198bfc00",
                collectionId: {
                  application: "urh",
                  id: "876e8df2-28bf-4680-805a-be8667575add",
                  source: "collections",
                },
              },
              {
                rcsId: "89d1dd32-39b8-4654-a384-bfb1a59918a2",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            document: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "This three-lesson learning sequence is designed to teach students about how content is created and used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital leader Stage 4",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T20:54:20",
            },
            draft: { LitLearnProgLevelV3: [], NumLearnProgLevelV3: [] },
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2022-12-12T01:48:58Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221122091543",
              version: 7,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 71,
              SharedToClassroom: 0,
              GoToResource: 30,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "0d236352-62f6-492e-97f1-d08094bde165",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "eb684bdd-528a-44ba-b393-ddd5198bfc00",
                collectionId: {
                  application: "urh",
                  id: "876e8df2-28bf-4680-805a-be8667575add",
                  source: "collections",
                },
              },
              {
                rcsId: "89d1dd32-39b8-4654-a384-bfb1a59918a2",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
              {
                rcsId: "34d658f6-e532-4d7d-950e-ea0e6f6421a8",
                collectionId: {
                  application: "urh",
                  id: "7cd49ca4-8170-4b88-ac86-1a13cafaff12",
                  source: "collections",
                },
              },
              {
                rcsId: "67b41613-fb7f-4015-836e-1f424032692f",
                collectionId: {
                  application: "urh",
                  id: "bdbfd88c-946d-4398-b35c-99fdda8b8111",
                  source: "collections",
                },
              },
              {
                rcsId: "01edf132-2c54-487d-921a-b28437b2ab19",
                collectionId: {
                  application: "urh",
                  id: "a1fdbac3-d096-4428-9125-88df50a82567",
                  source: "collections",
                },
              },
              {
                rcsId: "0c244524-7488-4588-9f01-bf162e0a5878",
                collectionId: {
                  application: "urh",
                  id: "7a691727-55ab-41f4-b0e3-0c2eec4fa1a5",
                  source: "collections",
                },
              },
              {
                rcsId: "3cf22a2f-8be6-4fd1-a469-fc6f3266905d",
                collectionId: {
                  application: "urh",
                  id: "5ad4a7b0-2269-4a78-80b6-78a8f597cc79",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "michelle.chea", iss: "urh" },
            document: {
              KLA: ["English", "TAS", "PDHPE"],
              Description:
                "<p>This three-lesson learning sequence is designed to teach students about how content is created and used online. It allows them to use sources and check to see if they are authentic and trustworthy, or if bias exists. It is recommended that this resource is used digitally to gain the benefit of the hyperlinks within. A list of all the online resources is at the end of each lesson.</p>",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safet",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:44:05",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "responds to and composes texts for understanding, interpretation, critical analysis, imaginative expression and pleasure",
                      id: "EN4-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "effectively uses a widening range of processes, skills, strategies and knowledge for responding to and composing texts in different media and technologies",
                      id: "EN4-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses and describes language forms, features and structures of texts appropriate to a range of purposes, audiences and contexts",
                      id: "EN4-3B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN4-3B",
                    },
                  ],
                  name: "English K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus:
                        "Personal Development, Health and Physical Education K-10",
                      name: "applies and refines interpersonal skills to assist themselves and others to interact respectfully and promote inclusion in a variety of groups or contexts",
                      id: "PD4-10",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=PD4-10",
                    },
                  ],
                  name: "Personal Development, Health and Physical Education K-10",
                },
                {
                  outcomes: [
                    {
                      syllabus: "Technology Mandatory 7-8",
                      name: "explains how people in technology related professions contribute to society now and into the future",
                      id: "TE4-10TS",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=TE4-10TS",
                    },
                  ],
                  name: "Technology Mandatory 7-8",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be a digital leader Stage 4",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["4"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_leader_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:44:05",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221122091543",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be a digital detective",
                  id: "CUR-MS221005133739",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
              ],
              Year: ["7", "8"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Secondary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-18T22:43:22",
            },
            draft: {},
            record: {
              created: "2022-12-05T03:44:05Z",
              modified: "2023-02-06T05:24:41Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221122091543",
              version: 14,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 181,
              SharedToClassroom: 0,
              GoToResource: 73,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "0d236352-62f6-492e-97f1-d08094bde165",
            collectionId: {
              application: "urh",
              id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
              source: "collections",
            },
          },
          {
            rcsId: "eb684bdd-528a-44ba-b393-ddd5198bfc00",
            collectionId: {
              application: "urh",
              id: "876e8df2-28bf-4680-805a-be8667575add",
              source: "collections",
            },
          },
          {
            rcsId: "89d1dd32-39b8-4654-a384-bfb1a59918a2",
            collectionId: {
              application: "urh",
              id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
              source: "collections",
            },
          },
          {
            rcsId: "34d658f6-e532-4d7d-950e-ea0e6f6421a8",
            collectionId: {
              application: "urh",
              id: "7cd49ca4-8170-4b88-ac86-1a13cafaff12",
              source: "collections",
            },
          },
          {
            rcsId: "67b41613-fb7f-4015-836e-1f424032692f",
            collectionId: {
              application: "urh",
              id: "bdbfd88c-946d-4398-b35c-99fdda8b8111",
              source: "collections",
            },
          },
          {
            rcsId: "01edf132-2c54-487d-921a-b28437b2ab19",
            collectionId: {
              application: "urh",
              id: "a1fdbac3-d096-4428-9125-88df50a82567",
              source: "collections",
            },
          },
          {
            rcsId: "0c244524-7488-4588-9f01-bf162e0a5878",
            collectionId: {
              application: "urh",
              id: "7a691727-55ab-41f4-b0e3-0c2eec4fa1a5",
              source: "collections",
            },
          },
          {
            rcsId: "3cf22a2f-8be6-4fd1-a469-fc6f3266905d",
            collectionId: {
              application: "urh",
              id: "5ad4a7b0-2269-4a78-80b6-78a8f597cc79",
              source: "collections",
            },
          },
        ],
        audit: { sub: "michelle.chea", iss: "urh" },
        draft: {},
        record: {
          created: "2022-12-05T03:44:05Z",
          modified: "2023-03-26T08:12:27Z",
          collection: "curriculum",
          published: true,
          id: "CUR-MS221122091543",
          version: 15,
        },
        userAccess: {
          owner: ["michelle.chea"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 0,
        },
        files: [],
        links: [],
        misc: {
          Views: 185,
          SharedToClassroom: 0,
          GoToResource: 74,
          SharedToTeams: 0,
          Likes: 0,
          LikedBy: [],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["English"],
          Description:
            "<p>Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.</p>",
          Keywords: [
            "cybersafety",
            "digital literacy",
            "fake news",
            "internet",
            "online safety",
            "digital citizenship",
            "english",
            "stage 3",
            "risk",
            "online",
            "security",
            "safety",
            "cyber",
          ],
          Phase: [
            {
              phase: "Planning and programming",
              id: "tlcp.planningandprogramming",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-12-05T03:29:58",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "English K-10",
                  name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                  id: "EN3-1A",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                },
                {
                  syllabus: "English K-10",
                  name: "composes, edits and presents well-structured and coherent texts",
                  id: "EN3-2A",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                },
                {
                  syllabus: "English K-10",
                  name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                  id: "EN3-3A",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                },
                {
                  syllabus: "English K-10",
                  name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                  id: "EN3-5B",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                },
                {
                  syllabus: "English K-10",
                  name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                  id: "EN3-7C",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                },
                {
                  syllabus: "English K-10",
                  name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                  id: "EN3-8D",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                },
              ],
              name: "English K-10",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "curriculum",
          Name: "Be a Cybermarvel: Be a digital detective Stage 3",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Document",
          RandNTopic: [],
          SIPTerms: ["Lesson planning", "Literacy", "Technology"],
          LitLearnProgLevelV3: [],
          FocusArea: [
            {
              channel: "Curriculum",
              id: "curriculum.cybersafety",
              ownerid: "curriculum",
              focusArea: "Cyber safety",
            },
          ],
          NumLearnProgLevel: [],
          Stage: ["3"],
          links: [],
          NumLearnProgLevelV3: [],
          QAAssured: true,
          Topic: [
            "Digital reputation",
            "Information literacy",
            "Internet safety",
          ],
          PLLinks: [],
          ResourceURL:
            "https://education.nsw.gov.au/content/dam/main-education/inside-the-department/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective.docx",
          professionalLearning: [],
          DateResCreated: "2022-12-05T03:29:58",
          ResourceType: "lessonplan",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [
            {
              domain: "Learning",
              id: "learn.curriculum",
              element: "Curriculum",
            },
            { domain: "Learning", id: "learn.wellbeing", element: "Wellbeing" },
          ],
          ItemID: "CUR-MS221005133739",
          AusCurrContDesc: [],
          RelatedResources: [
            {
              name: "Be a cybermarvel: Be kind online",
              id: "CUR-MS220603121346",
              url: "",
            },
            {
              name: "Be a cybermarvel: Be safe online",
              id: "CUR-MS220603123756",
              url: "",
            },
            {
              name: "Be a cybermarvel: Be a digital leader",
              id: "CUR-MS221122091543",
              url: "",
            },
          ],
          Year: ["5", "6"],
          LitLearnProgLevel: [],
          SchoolType: [
            "Primary Schools",
            "Central Schools",
            "Schools for Specific Purposes",
          ],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-26T08:11:42",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {
              KLA: ["English"],
              Description:
                "Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safety",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:29:58",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                      id: "EN3-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "composes, edits and presents well-structured and coherent texts",
                      id: "EN3-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                      id: "EN3-3A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                      id: "EN3-5B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                    },
                    {
                      syllabus: "English K-10",
                      name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                      id: "EN3-7C",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                    },
                    {
                      syllabus: "English K-10",
                      name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                      id: "EN3-8D",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                    },
                  ],
                  name: "English K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital detective Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective_FINAL.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:29:58",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221005133739",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be a digital leader",
                  id: "CUR-MS221122091543",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Primary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:29:58",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-12-05T03:29:58Z",
              modified: "2022-12-05T03:29:58Z",
              collection: "curriculum",
              published: false,
              id: "CUR-MS221005133739",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            collections: [],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            draft: {
              KLA: ["English"],
              Description:
                "Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safety",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:29:58",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                      id: "EN3-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "composes, edits and presents well-structured and coherent texts",
                      id: "EN3-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                      id: "EN3-3A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                      id: "EN3-5B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                    },
                    {
                      syllabus: "English K-10",
                      name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                      id: "EN3-7C",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                    },
                    {
                      syllabus: "English K-10",
                      name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                      id: "EN3-8D",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                    },
                  ],
                  name: "English K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital detective Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:29:58",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221005133739",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be a digital leader",
                  id: "CUR-MS221122091543",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Primary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:29:58",
            },
            document: {
              KLA: ["English"],
              Description:
                "Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safety",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:29:58",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                      id: "EN3-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "composes, edits and presents well-structured and coherent texts",
                      id: "EN3-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                      id: "EN3-3A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                      id: "EN3-5B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                    },
                    {
                      syllabus: "English K-10",
                      name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                      id: "EN3-7C",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                    },
                    {
                      syllabus: "English K-10",
                      name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                      id: "EN3-8D",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                    },
                  ],
                  name: "English K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital detective Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              links: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:29:58",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221005133739",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be a digital leader",
                  id: "CUR-MS221122091543",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Primary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/curriculum.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T03:29:58",
            },
            record: {
              created: "2022-12-05T03:29:58Z",
              modified: "2022-12-05T03:50:21Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221005133739",
              version: 1,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 5,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6f55900d-c997-420f-9fae-f7a25a982999",
                collectionId: {
                  application: "urh",
                  id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
                  source: "collections",
                },
              },
              {
                rcsId: "06875b59-12ed-41f0-8d8a-59f30eb4cda0",
                collectionId: {
                  application: "urh",
                  id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
                  source: "collections",
                },
              },
              {
                rcsId: "864b636c-0c35-4f3a-bb36-d636620b7f10",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "6116feeb-8d6c-4bc0-a241-504d813781eb",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
              {
                rcsId: "c0d50a5a-f81f-40cc-a3cb-9e833a4e17a0",
                collectionId: {
                  application: "urh",
                  id: "5aa5ec8c-b123-4b66-8952-571f12f0cd57",
                  source: "collections",
                },
              },
              {
                rcsId: "572ac6a8-6cb4-488f-98b4-26dcb1005321",
                collectionId: {
                  application: "urh",
                  id: "fad6bf08-3516-43c6-bd96-9165442790f2",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "lisa.g.edwards", iss: "urh" },
            document: {
              KLA: ["English"],
              Description:
                "Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safety",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:29:58",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                      id: "EN3-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "composes, edits and presents well-structured and coherent texts",
                      id: "EN3-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                      id: "EN3-3A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                      id: "EN3-5B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                    },
                    {
                      syllabus: "English K-10",
                      name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                      id: "EN3-7C",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                    },
                    {
                      syllabus: "English K-10",
                      name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                      id: "EN3-8D",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                    },
                  ],
                  name: "English K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a cybermarvel: Be a digital detective Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:29:58",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221005133739",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be a digital leader",
                  id: "CUR-MS221122091543",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Primary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-05T20:54:07",
            },
            draft: { LitLearnProgLevelV3: [], NumLearnProgLevelV3: [] },
            record: {
              created: "2022-12-05T03:29:58Z",
              modified: "2023-01-01T00:44:34Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221005133739",
              version: 8,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 65,
              SharedToClassroom: 0,
              GoToResource: 41,
              SharedToTeams: 0,
              Likes: 1,
              LikedBy: ["david.piec3"],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6f55900d-c997-420f-9fae-f7a25a982999",
                collectionId: {
                  application: "urh",
                  id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
                  source: "collections",
                },
              },
              {
                rcsId: "06875b59-12ed-41f0-8d8a-59f30eb4cda0",
                collectionId: {
                  application: "urh",
                  id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
                  source: "collections",
                },
              },
              {
                rcsId: "864b636c-0c35-4f3a-bb36-d636620b7f10",
                collectionId: {
                  application: "urh",
                  id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
                  source: "collections",
                },
              },
              {
                rcsId: "6116feeb-8d6c-4bc0-a241-504d813781eb",
                collectionId: {
                  application: "urh",
                  id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
                  source: "collections",
                },
              },
              {
                rcsId: "c0d50a5a-f81f-40cc-a3cb-9e833a4e17a0",
                collectionId: {
                  application: "urh",
                  id: "5aa5ec8c-b123-4b66-8952-571f12f0cd57",
                  source: "collections",
                },
              },
              {
                rcsId: "572ac6a8-6cb4-488f-98b4-26dcb1005321",
                collectionId: {
                  application: "urh",
                  id: "fad6bf08-3516-43c6-bd96-9165442790f2",
                  source: "collections",
                },
              },
              {
                rcsId: "b5a3ac3f-7961-44e8-9bfc-15ed839223d0",
                collectionId: {
                  application: "urh",
                  id: "adfeb75b-1d84-4c22-90e4-0e5966232fae",
                  source: "collections",
                },
              },
              {
                rcsId: "d9777f38-1357-4e1f-928e-17aa20cfe574",
                collectionId: {
                  application: "urh",
                  id: "a1fdbac3-d096-4428-9125-88df50a82567",
                  source: "collections",
                },
              },
              {
                rcsId: "13a74452-cd68-4b21-bc13-0a122af25061",
                collectionId: {
                  application: "urh",
                  id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
                  source: "collections",
                },
              },
              {
                rcsId: "6ae3beb5-f442-427d-980a-34340458c43a",
                collectionId: {
                  application: "urh",
                  id: "ca732177-b081-4b9d-b909-deaf57c87e83",
                  source: "collections",
                },
              },
              {
                rcsId: "2aea8c42-1ba9-41be-8e3e-9433e3080c42",
                collectionId: {
                  application: "urh",
                  id: "89020a87-3baa-4729-83e8-2a0f4475af35",
                  source: "collections",
                },
              },
              {
                rcsId: "96ac421b-a8c5-4deb-ba76-2e93868dced9",
                collectionId: {
                  application: "urh",
                  id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
                  source: "collections",
                },
              },
              {
                rcsId: "8b71c449-b364-4749-ac30-bda1b1ec70f8",
                collectionId: {
                  application: "urh",
                  id: "6d5089fd-90e4-46bc-8c90-7794ef223dc2",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "michelle.chea", iss: "urh" },
            document: {
              KLA: ["English"],
              Description:
                "<p>Digital literacy skills are essential for students to feel empowered to question things they find online. The ability to critically evaluate information and images online allows students to make informed decisions, share information responsibly and help them to maintain their online reputation. Be a digital detective is a two-lesson sequence with an extension activity that has been developed to support students to critically evaluate news and to feel empowered to question things they find online.</p>",
              Keywords: [
                "cybersafety",
                "digital literacy",
                "fake news",
                "internet",
                "online safety",
                "digital citizenship",
                "english",
                "stage 3",
                "risk",
                "online",
                "security",
                "safety",
                "cyber",
              ],
              Phase: [
                {
                  phase: "Planning and programming",
                  id: "tlcp.planningandprogramming",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-05T03:29:58",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "English K-10",
                      name: "communicates effectively for a variety of audiences and purposes using increasingly challenging topics, ideas, issues and language forms and features",
                      id: "EN3-1A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-1A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "composes, edits and presents well-structured and coherent texts",
                      id: "EN3-2A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-2A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "uses an integrated range of skills, strategies and knowledge to read, view and comprehend a wide range of texts in different media and technologies",
                      id: "EN3-3A",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-3A",
                    },
                    {
                      syllabus: "English K-10",
                      name: "discusses how language is used to achieve a widening range of purposes for a widening range of audiences and contexts",
                      id: "EN3-5B",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-5B",
                    },
                    {
                      syllabus: "English K-10",
                      name: "thinks imaginatively, creatively, interpretively and critically about information and ideas and identifies connections between texts when responding to and composing texts",
                      id: "EN3-7C",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-7C",
                    },
                    {
                      syllabus: "English K-10",
                      name: "identifies and considers how different viewpoints of their world, including aspects of culture, are represented in texts",
                      id: "EN3-8D",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=EN3-8D",
                    },
                  ],
                  name: "English K-10",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "curriculum",
              Name: "Be a Cybermarvel: Be a digital detective Stage 3",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Document",
              RandNTopic: [],
              SIPTerms: ["Lesson planning", "Literacy", "Technology"],
              LitLearnProgLevelV3: [],
              FocusArea: [
                {
                  channel: "Curriculum",
                  id: "curriculum.cybersafety",
                  ownerid: "curriculum",
                  focusArea: "Cyber safety",
                },
              ],
              NumLearnProgLevel: [],
              Stage: ["3"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Digital reputation",
                "Information literacy",
                "Internet safety",
              ],
              PLLinks: [],
              ResourceURL:
                "https://education.nsw.gov.au/content/dam/main-education/technology/media/documents/Be_a_Cybermarvel_-_Be_a_digital_detective_FINAL1.pdf",
              professionalLearning: [],
              DateResCreated: "2022-12-05T03:29:58",
              ResourceType: "lessonplan",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [
                {
                  domain: "Learning",
                  id: "learn.curriculum",
                  element: "Curriculum",
                },
                {
                  domain: "Learning",
                  id: "learn.wellbeing",
                  element: "Wellbeing",
                },
              ],
              ItemID: "CUR-MS221005133739",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Be a cybermarvel: Be kind online",
                  id: "CUR-MS220603121346",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be safe online",
                  id: "CUR-MS220603123756",
                  url: "",
                },
                {
                  name: "Be a cybermarvel: Be a digital leader",
                  id: "CUR-MS221122091543",
                  url: "",
                },
              ],
              Year: ["5", "6"],
              LitLearnProgLevel: [],
              SchoolType: [
                "Primary Schools",
                "Central Schools",
                "Schools for Specific Purposes",
              ],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/urh/cyber-safety.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-18T22:43:38",
            },
            draft: {},
            record: {
              created: "2022-12-05T03:29:58Z",
              modified: "2023-03-02T08:55:58Z",
              collection: "curriculum",
              published: true,
              id: "CUR-MS221005133739",
              version: 17,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 232,
              SharedToClassroom: 0,
              GoToResource: 129,
              SharedToTeams: 0,
              Likes: 1,
              LikedBy: ["david.piec3"],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "6f55900d-c997-420f-9fae-f7a25a982999",
            collectionId: {
              application: "urh",
              id: "eb171e10-1a75-4394-9e3a-26a8a515fcce",
              source: "collections",
            },
          },
          {
            rcsId: "06875b59-12ed-41f0-8d8a-59f30eb4cda0",
            collectionId: {
              application: "urh",
              id: "f1aff5ea-ce46-4bfc-a909-5a577aaaece4",
              source: "collections",
            },
          },
          {
            rcsId: "864b636c-0c35-4f3a-bb36-d636620b7f10",
            collectionId: {
              application: "urh",
              id: "1bfe69b2-cac0-4d07-89cd-a0e07068e382",
              source: "collections",
            },
          },
          {
            rcsId: "6116feeb-8d6c-4bc0-a241-504d813781eb",
            collectionId: {
              application: "urh",
              id: "f1b6cbc8-ced0-469c-bc85-3ccb2dbc5f42",
              source: "collections",
            },
          },
          {
            rcsId: "c0d50a5a-f81f-40cc-a3cb-9e833a4e17a0",
            collectionId: {
              application: "urh",
              id: "5aa5ec8c-b123-4b66-8952-571f12f0cd57",
              source: "collections",
            },
          },
          {
            rcsId: "572ac6a8-6cb4-488f-98b4-26dcb1005321",
            collectionId: {
              application: "urh",
              id: "fad6bf08-3516-43c6-bd96-9165442790f2",
              source: "collections",
            },
          },
          {
            rcsId: "b5a3ac3f-7961-44e8-9bfc-15ed839223d0",
            collectionId: {
              application: "urh",
              id: "adfeb75b-1d84-4c22-90e4-0e5966232fae",
              source: "collections",
            },
          },
          {
            rcsId: "d9777f38-1357-4e1f-928e-17aa20cfe574",
            collectionId: {
              application: "urh",
              id: "a1fdbac3-d096-4428-9125-88df50a82567",
              source: "collections",
            },
          },
          {
            rcsId: "13a74452-cd68-4b21-bc13-0a122af25061",
            collectionId: {
              application: "urh",
              id: "a1f9fcb5-7d34-4c03-a436-cf617508bd77",
              source: "collections",
            },
          },
          {
            rcsId: "6ae3beb5-f442-427d-980a-34340458c43a",
            collectionId: {
              application: "urh",
              id: "ca732177-b081-4b9d-b909-deaf57c87e83",
              source: "collections",
            },
          },
          {
            rcsId: "2aea8c42-1ba9-41be-8e3e-9433e3080c42",
            collectionId: {
              application: "urh",
              id: "89020a87-3baa-4729-83e8-2a0f4475af35",
              source: "collections",
            },
          },
          {
            rcsId: "96ac421b-a8c5-4deb-ba76-2e93868dced9",
            collectionId: {
              application: "urh",
              id: "c0218bc8-e9be-4d18-bc31-11130fd78bfe",
              source: "collections",
            },
          },
          {
            rcsId: "8b71c449-b364-4749-ac30-bda1b1ec70f8",
            collectionId: {
              application: "urh",
              id: "6d5089fd-90e4-46bc-8c90-7794ef223dc2",
              source: "collections",
            },
          },
        ],
        audit: { sub: "michelle.chea", iss: "urh" },
        draft: {},
        record: {
          created: "2022-12-05T03:29:58Z",
          modified: "2023-03-26T08:11:41Z",
          collection: "curriculum",
          published: true,
          id: "CUR-MS221005133739",
          version: 18,
        },
        userAccess: {
          owner: ["michelle.chea"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 0,
        },
        files: [],
        links: [],
        misc: {
          Views: 232,
          SharedToClassroom: 0,
          GoToResource: 130,
          SharedToTeams: 0,
          Likes: 1,
          LikedBy: ["david.piec3"],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["Mathematics"],
          Description:
            "<p>Count objects to find how many there are altogether.</p>",
          Keywords: ["attributes", "2201-04"],
          Phase: [
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-11-28T22:37:37",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "Mathematics K-2",
                  name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                  id: "MAE-2DS-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "demonstrates an understanding of how whole numbers indicate quantity",
                  id: "MAE-RWN-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "reads numerals and represents whole numbers to at least 20",
                  id: "MAE-RWN-02",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                  id: "MAO-WM-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                },
              ],
              name: "Mathematics K-2",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "public",
          Name: "Counting objects",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Web page",
          RandNTopic: [],
          LitLearnProgLevelV3: [],
          SIPTerms: [],
          FocusArea: [],
          NumLearnProgLevel: [],
          Stage: ["ES1"],
          links: [],
          NumLearnProgLevelV3: [
            {
              level: "CPr1",
              text: "CPr1",
              id: "CPr1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr2",
              text: "CPr2",
              id: "CPr2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr3",
              text: "CPr3",
              id: "CPr3",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr5",
              text: "CPr5",
              id: "CPr5",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "NPV1",
              text: "NPV1",
              id: "NPV1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "NPV2",
              text: "NPV2",
              id: "NPV2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "UGP1",
              text: "UGP1",
              id: "UGP1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
            {
              level: "UGP2",
              text: "UGP2",
              id: "UGP2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
          ],
          QAAssured: true,
          Topic: [
            "Shapes (Geometry)",
            "Counting",
            "Subitising",
            "Number (Mathematics)",
          ],
          PLLinks: [
            {
              name: "Mathematics Early Stage 1 Unit 1 - Attributes can be used to sort objects",
              id: "CUR-MP-U-001",
              url: "",
            },
          ],
          ResourceURL:
            "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
          professionalLearning: [],
          DateResCreated: "2022-11-28T22:37:37",
          ResourceType: "classroomresource",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [],
          ItemID: "2201-04-05",
          AusCurrContDesc: [],
          RelatedResources: [
            { name: "Maths is all around us", id: "2201-04-03", url: "" },
            {
              name: "Finding maths in our environment",
              id: "2201-04-04",
              url: "",
            },
          ],
          Year: ["K"],
          LitLearnProgLevel: [],
          SchoolType: [],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-23T05:56:33",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various various objects in different arrangements.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Grouping (Counting)",
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/doe-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T22:37:37",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-28T22:37:37Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various various objects in different arrangements.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Grouping (Counting)",
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/doe-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T22:38:07",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-28T22:38:07Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 1,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various various objects in different arrangements.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Grouping (Counting)",
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/doe-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T22:38:11",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-28T22:38:11Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 2,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Grouping (Counting)",
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/doe-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T00:36:46",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T00:36:48Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 3,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Grouping (Counting)",
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T01:22:33",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T01:22:36Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting Objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T01:51:48",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T01:51:48Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 5,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T01:52:16",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T01:52:16Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 6,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [{ name: "", id: "", url: "" }],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T01:54:32",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T01:54:32Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 7,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [{ name: "", id: "", url: "" }],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T01:55:47",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T01:55:47Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 8,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "",
                  url: "2201-04-05",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [{ name: "", id: "", url: "" }],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-29T02:51:01",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-11-29T02:51:02Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 9,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for use of resources that support the Mathematics-Early Stage 1-Unit 1",
                  id: "",
                  url: "2201-04-01",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "", url: "2201-04-02" },
                { name: "Maths is all around us", id: "", url: "2201-04-03" },
                {
                  name: "Find Maths in our environment",
                  id: "",
                  url: "2201-04-04",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T00:37:09",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-01T00:37:09Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 10,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [{ name: "Teacher Guide", id: "", url: "2201-04-38" }],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "", url: "2201-04-02" },
                { name: "Maths is all around us", id: "", url: "2201-04-03" },
                {
                  name: "Find Maths in our environment",
                  id: "",
                  url: "2201-04-04",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T00:46:36",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-01T00:46:36Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 11,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "bernadette.plush1", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [{ name: "Teacher Guide", id: "2201-04-38", url: "" }],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Find Maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T00:49:18",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-01T00:49:18Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 12,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                { name: "Teacher Guide", id: "2201-04-34", url: "" },
                { name: "", id: "", url: "" },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Find Maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T01:23:54",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-01T01:23:54Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 13,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:59:15",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-01T05:59:15Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 14,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "zarn.bou", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:59:15",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:59:15",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-05T00:46:14Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 15,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:59:15",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-07T06:55:07Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 16,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Substitution",
                "Data collection",
                "Data representation",
                "Counting",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-11T22:23:38",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-11T22:23:38Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 17,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "attribute",
                "subitising",
                "sorting",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T00:22:11",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-12T00:22:11Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 18,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 3,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T03:50:46",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-12T03:50:46Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 19,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 3,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "marc.burgess2", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:55",
            },
            document: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2022-12-20T05:06:55Z",
              collection: "public",
              published: false,
              id: "2201-04-05",
              version: 20,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 3,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:55",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:55",
            },
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-01-30T06:17:42Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 21,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 5,
              SharedToClassroom: 0,
              GoToResource: 6,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-31T23:09:58",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-01-31T23:09:58Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 22,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 21,
              SharedToClassroom: 0,
              GoToResource: 19,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "emilymay.norman", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-07T02:38:31",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-02-16T09:28:28Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 26,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 57,
              SharedToClassroom: 0,
              GoToResource: 47,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "0e12f123-9747-4b43-9888-cf0038b44e2a",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "marc.burgess2", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T02:00:25",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-02-18T04:55:34Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 28,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 62,
              SharedToClassroom: 0,
              GoToResource: 51,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "0e12f123-9747-4b43-9888-cf0038b44e2a",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "4f9d1aff-f758-4d93-8f06-c6f16f8ba848",
                collectionId: {
                  application: "urh",
                  id: "a89e2d05-450a-47c4-9ccc-95825dcd2954",
                  source: "collections",
                },
              },
              {
                rcsId: "b24ba69a-8710-4c55-a0a4-e62861c2cd18",
                collectionId: {
                  application: "urh",
                  id: "2096ba70-e27f-445d-89fd-b89293577bbb",
                  source: "collections",
                },
              },
              {
                rcsId: "714eb59c-a72e-4c12-a812-6a3353e7f26b",
                collectionId: {
                  application: "urh",
                  id: "9c17f681-0a1e-449a-b91e-29a3984f6425",
                  source: "collections",
                },
              },
              {
                rcsId: "f883b623-1d70-4008-999f-52d62746ac1a",
                collectionId: {
                  application: "urh",
                  id: "365d9401-7566-4a2e-b571-630950393eca",
                  source: "collections",
                },
              },
              {
                rcsId: "0f2ce9c2-e201-4426-bace-349c56211df5",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "6296c98e-ef72-4f46-81e9-9866e5bc5880",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "34661f41-de51-4cdd-af5d-ff84ea8eb322",
                collectionId: {
                  application: "urh",
                  id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
                  source: "collections",
                },
              },
              {
                rcsId: "21b6a2a0-7123-4ddd-8033-4db77c88ea44",
                collectionId: {
                  application: "urh",
                  id: "b75974a4-1254-45f1-882b-4ecb26e3af13",
                  source: "collections",
                },
              },
              {
                rcsId: "819d14e1-92bf-4a64-b84d-226d31cfe77f",
                collectionId: {
                  application: "urh",
                  id: "ce0a783f-2fb9-4e21-bee0-d67d6deb2864",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-18T23:07:57",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-03-05T02:33:05Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 38,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 136,
              SharedToClassroom: 0,
              GoToResource: 115,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "0e12f123-9747-4b43-9888-cf0038b44e2a",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "4f9d1aff-f758-4d93-8f06-c6f16f8ba848",
                collectionId: {
                  application: "urh",
                  id: "a89e2d05-450a-47c4-9ccc-95825dcd2954",
                  source: "collections",
                },
              },
              {
                rcsId: "b24ba69a-8710-4c55-a0a4-e62861c2cd18",
                collectionId: {
                  application: "urh",
                  id: "2096ba70-e27f-445d-89fd-b89293577bbb",
                  source: "collections",
                },
              },
              {
                rcsId: "714eb59c-a72e-4c12-a812-6a3353e7f26b",
                collectionId: {
                  application: "urh",
                  id: "9c17f681-0a1e-449a-b91e-29a3984f6425",
                  source: "collections",
                },
              },
              {
                rcsId: "f883b623-1d70-4008-999f-52d62746ac1a",
                collectionId: {
                  application: "urh",
                  id: "365d9401-7566-4a2e-b571-630950393eca",
                  source: "collections",
                },
              },
              {
                rcsId: "0f2ce9c2-e201-4426-bace-349c56211df5",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "6296c98e-ef72-4f46-81e9-9866e5bc5880",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "34661f41-de51-4cdd-af5d-ff84ea8eb322",
                collectionId: {
                  application: "urh",
                  id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
                  source: "collections",
                },
              },
              {
                rcsId: "21b6a2a0-7123-4ddd-8033-4db77c88ea44",
                collectionId: {
                  application: "urh",
                  id: "b75974a4-1254-45f1-882b-4ecb26e3af13",
                  source: "collections",
                },
              },
              {
                rcsId: "819d14e1-92bf-4a64-b84d-226d31cfe77f",
                collectionId: {
                  application: "urh",
                  id: "ce0a783f-2fb9-4e21-bee0-d67d6deb2864",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through counting of various objects in different arrangements.</span></p>',
              Keywords: [
                "shapes ",
                "counting",
                "whole number",
                "patterns",
                "subitising",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-07T23:00:21",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-03-07T23:00:21Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 39,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 143,
              SharedToClassroom: 0,
              GoToResource: 122,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "0e12f123-9747-4b43-9888-cf0038b44e2a",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "4f9d1aff-f758-4d93-8f06-c6f16f8ba848",
                collectionId: {
                  application: "urh",
                  id: "a89e2d05-450a-47c4-9ccc-95825dcd2954",
                  source: "collections",
                },
              },
              {
                rcsId: "b24ba69a-8710-4c55-a0a4-e62861c2cd18",
                collectionId: {
                  application: "urh",
                  id: "2096ba70-e27f-445d-89fd-b89293577bbb",
                  source: "collections",
                },
              },
              {
                rcsId: "714eb59c-a72e-4c12-a812-6a3353e7f26b",
                collectionId: {
                  application: "urh",
                  id: "9c17f681-0a1e-449a-b91e-29a3984f6425",
                  source: "collections",
                },
              },
              {
                rcsId: "f883b623-1d70-4008-999f-52d62746ac1a",
                collectionId: {
                  application: "urh",
                  id: "365d9401-7566-4a2e-b571-630950393eca",
                  source: "collections",
                },
              },
              {
                rcsId: "0f2ce9c2-e201-4426-bace-349c56211df5",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "6296c98e-ef72-4f46-81e9-9866e5bc5880",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "34661f41-de51-4cdd-af5d-ff84ea8eb322",
                collectionId: {
                  application: "urh",
                  id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
                  source: "collections",
                },
              },
              {
                rcsId: "21b6a2a0-7123-4ddd-8033-4db77c88ea44",
                collectionId: {
                  application: "urh",
                  id: "b75974a4-1254-45f1-882b-4ecb26e3af13",
                  source: "collections",
                },
              },
              {
                rcsId: "819d14e1-92bf-4a64-b84d-226d31cfe77f",
                collectionId: {
                  application: "urh",
                  id: "ce0a783f-2fb9-4e21-bee0-d67d6deb2864",
                  source: "collections",
                },
              },
              {
                rcsId: "676f2842-5fa1-41dd-8e6e-2846a38d3117",
                collectionId: {
                  application: "urh",
                  id: "90ce4d73-bac3-4020-93dd-3833f2f2caf5",
                  source: "collections",
                },
              },
              {
                rcsId: "98665c9a-8cd6-45ce-9f2f-b0570514bdf9",
                collectionId: {
                  application: "urh",
                  id: "a480639f-fb3f-40a9-b8ba-3dd33f2bce85",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                "<p>Count objects to find how many there are altogether.</p>",
              Keywords: [
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T22:37:37",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Counting objects",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-05",
              professionalLearning: [],
              DateResCreated: "2022-11-28T22:37:37",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-05",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-05.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-13T04:53:58",
            },
            draft: {},
            record: {
              created: "2022-11-28T22:37:37Z",
              modified: "2023-03-21T11:43:40Z",
              collection: "public",
              published: true,
              id: "2201-04-05",
              version: 42,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 158,
              SharedToClassroom: 0,
              GoToResource: 141,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "34a18e4f-835e-4d43-b683-2b8dc43b3ea5",
            collectionId: {
              application: "urh",
              id: "53417c8b-da4a-4479-a186-5362c1c23979",
              source: "collections",
            },
          },
          {
            rcsId: "f0fbe7dc-9de5-41a1-9a8a-f3a2e7b8a4c3",
            collectionId: {
              application: "urh",
              id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
              source: "collections",
            },
          },
          {
            rcsId: "c69787b7-6b43-4153-ac02-f7b2f694b404",
            collectionId: {
              application: "urh",
              id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
              source: "collections",
            },
          },
          {
            rcsId: "0e12f123-9747-4b43-9888-cf0038b44e2a",
            collectionId: {
              application: "urh",
              id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
              source: "collections",
            },
          },
          {
            rcsId: "4f9d1aff-f758-4d93-8f06-c6f16f8ba848",
            collectionId: {
              application: "urh",
              id: "a89e2d05-450a-47c4-9ccc-95825dcd2954",
              source: "collections",
            },
          },
          {
            rcsId: "b24ba69a-8710-4c55-a0a4-e62861c2cd18",
            collectionId: {
              application: "urh",
              id: "2096ba70-e27f-445d-89fd-b89293577bbb",
              source: "collections",
            },
          },
          {
            rcsId: "714eb59c-a72e-4c12-a812-6a3353e7f26b",
            collectionId: {
              application: "urh",
              id: "9c17f681-0a1e-449a-b91e-29a3984f6425",
              source: "collections",
            },
          },
          {
            rcsId: "f883b623-1d70-4008-999f-52d62746ac1a",
            collectionId: {
              application: "urh",
              id: "365d9401-7566-4a2e-b571-630950393eca",
              source: "collections",
            },
          },
          {
            rcsId: "0f2ce9c2-e201-4426-bace-349c56211df5",
            collectionId: {
              application: "urh",
              id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
              source: "collections",
            },
          },
          {
            rcsId: "6296c98e-ef72-4f46-81e9-9866e5bc5880",
            collectionId: {
              application: "urh",
              id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
              source: "collections",
            },
          },
          {
            rcsId: "34661f41-de51-4cdd-af5d-ff84ea8eb322",
            collectionId: {
              application: "urh",
              id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
              source: "collections",
            },
          },
          {
            rcsId: "21b6a2a0-7123-4ddd-8033-4db77c88ea44",
            collectionId: {
              application: "urh",
              id: "b75974a4-1254-45f1-882b-4ecb26e3af13",
              source: "collections",
            },
          },
          {
            rcsId: "819d14e1-92bf-4a64-b84d-226d31cfe77f",
            collectionId: {
              application: "urh",
              id: "ce0a783f-2fb9-4e21-bee0-d67d6deb2864",
              source: "collections",
            },
          },
          {
            rcsId: "676f2842-5fa1-41dd-8e6e-2846a38d3117",
            collectionId: {
              application: "urh",
              id: "90ce4d73-bac3-4020-93dd-3833f2f2caf5",
              source: "collections",
            },
          },
          {
            rcsId: "98665c9a-8cd6-45ce-9f2f-b0570514bdf9",
            collectionId: {
              application: "urh",
              id: "a480639f-fb3f-40a9-b8ba-3dd33f2bce85",
              source: "collections",
            },
          },
        ],
        audit: { sub: "irvin.flack", iss: "olp" },
        draft: {},
        record: {
          created: "2022-11-28T22:37:37Z",
          modified: "2023-03-23T05:56:33Z",
          collection: "public",
          published: true,
          id: "2201-04-05",
          version: 43,
        },
        userAccess: {
          owner: ["irvin.flack"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 2,
        },
        files: [],
        links: [],
        misc: {
          Views: 222,
          SharedToClassroom: 0,
          GoToResource: 183,
          SharedToTeams: 0,
          Likes: 0,
          LikedBy: [],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["Mathematics"],
          Description:
            "<p>Discover numbers, shapes and patterns you might find in your environment.</p>",
          Keywords: ["attributes", "2201-04", "mathematics is everywhere"],
          Phase: [
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-12-01T06:02:19",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "Mathematics K-2",
                  name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                  id: "MAE-2DS-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "demonstrates an understanding of how whole numbers indicate quantity",
                  id: "MAE-RWN-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "reads numerals and represents whole numbers to at least 20",
                  id: "MAE-RWN-02",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                  id: "MAO-WM-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                },
              ],
              name: "Mathematics K-2",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "public",
          Name: "Finding maths in our environment",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Web page",
          RandNTopic: [],
          LitLearnProgLevelV3: [],
          SIPTerms: [],
          FocusArea: [],
          NumLearnProgLevel: [],
          Stage: ["ES1"],
          links: [],
          NumLearnProgLevelV3: [
            {
              level: "CPr1",
              text: "CPr1",
              id: "CPr1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr2",
              text: "CPr2",
              id: "CPr2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr3",
              text: "CPr3",
              id: "CPr3",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr5",
              text: "CPr5",
              id: "CPr5",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "NPV1",
              text: "NPV1",
              id: "NPV1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "NPV2",
              text: "NPV2",
              id: "NPV2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "UGP1",
              text: "UGP1",
              id: "UGP1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
            {
              level: "UGP2",
              text: "UGP2",
              id: "UGP2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
          ],
          QAAssured: true,
          Topic: [
            "Shapes (Geometry)",
            "Counting",
            "Subitising",
            "Number (Mathematics)",
            "Properties",
            "Numeral systems",
          ],
          PLLinks: [
            {
              name: "Mathematics Early Stage 1 Unit 1 - Attributes can be used to sort objects",
              id: "CUR-MP-U-001",
              url: "",
            },
          ],
          ResourceURL:
            "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
          professionalLearning: [],
          DateResCreated: "2022-12-01T06:02:19",
          ResourceType: "classroomresource",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [],
          ItemID: "2201-04-04",
          AusCurrContDesc: [],
          RelatedResources: [
            { name: "Maths is all around us", id: "2201-04-03", url: "" },
            { name: "Counting objects", id: "2201-04-05", url: "" },
          ],
          Year: ["K"],
          LitLearnProgLevel: [],
          SchoolType: [],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-23T05:55:31",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:02:19",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-01T06:02:19Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:10:19",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-01T06:10:19Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 1,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:10:37",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-01T06:10:37Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 2,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "zarn.bou", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:10:37",
            },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:10:37",
            },
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-04T23:44:52Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 3,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T06:10:37",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-07T06:55:10Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-11T22:21:43",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-11T22:21:43Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 5,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-11T22:22:09",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-11T22:22:09Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 6,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T00:17:27",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-12T00:17:27Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 7,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T03:49:55",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-12T03:49:55Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 8,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "marc.burgess2", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:24",
            },
            document: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2022-12-20T05:06:24Z",
              collection: "public",
              published: false,
              id: "2201-04-04",
              version: 9,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:24",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:06:24",
            },
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-01-30T06:17:28Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 10,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 1,
              SharedToClassroom: 0,
              GoToResource: 4,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-31T23:09:34",
            },
            draft: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-01-31T23:09:34Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 11,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 18,
              SharedToClassroom: 0,
              GoToResource: 15,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "emilymay.norman", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-07T02:30:31",
            },
            draft: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-02-16T09:24:06Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 15,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 38,
              SharedToClassroom: 0,
              GoToResource: 31,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "24a3acfa-574f-49e4-8f58-997a5ca29588",
                collectionId: {
                  application: "urh",
                  id: "a4a32d1d-95c6-4e99-a6bc-f5d67f74e8b9",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
                "Properties",
                "Classification",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T02:00:02",
            },
            draft: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-02-18T23:34:16Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 17,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 45,
              SharedToClassroom: 0,
              GoToResource: 36,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "24a3acfa-574f-49e4-8f58-997a5ca29588",
                collectionId: {
                  application: "urh",
                  id: "a4a32d1d-95c6-4e99-a6bc-f5d67f74e8b9",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {},
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: [
                "attributes",
                "2201-04",
                "matching",
                "mathematics is everywhere",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Properties",
                "Numeral systems",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-21T05:38:03",
            },
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-02-21T05:38:03Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 18,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 45,
              SharedToClassroom: 0,
              GoToResource: 36,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "24a3acfa-574f-49e4-8f58-997a5ca29588",
                collectionId: {
                  application: "urh",
                  id: "a4a32d1d-95c6-4e99-a6bc-f5d67f74e8b9",
                  source: "collections",
                },
              },
              {
                rcsId: "55b0b099-0350-4eb1-8704-22a21248f017",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "f34c7290-3a49-4cf2-8093-6ec9a49777c9",
                collectionId: {
                  application: "urh",
                  id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
                  source: "collections",
                },
              },
              {
                rcsId: "ba03b8e4-8097-45a0-9b33-010d4120076f",
                collectionId: {
                  application: "urh",
                  id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
                  source: "collections",
                },
              },
              {
                rcsId: "5a937455-0f70-4d67-9363-ac776e958765",
                collectionId: {
                  application: "urh",
                  id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
                  source: "collections",
                },
              },
              {
                rcsId: "e567f4ee-3238-49dd-bf2e-ed2f42ae371f",
                collectionId: {
                  application: "urh",
                  id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
                  source: "collections",
                },
              },
              {
                rcsId: "c8f62e95-42fd-49f5-97f6-43242d7fd6ac",
                collectionId: {
                  application: "urh",
                  id: "7455e9b2-610b-4580-b73c-689e79d0f1dd",
                  source: "collections",
                },
              },
              {
                rcsId: "d5667ed5-c9d9-4fef-b555-6fff65d8cfb0",
                collectionId: {
                  application: "urh",
                  id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
                  source: "collections",
                },
              },
              {
                rcsId: "deabaa3b-c4ad-4219-a5d3-2d8ce7b2f1b1",
                collectionId: {
                  application: "urh",
                  id: "027a340b-b7d7-4c8f-bcdd-2674157356bc",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through matching activities, where they aim to match colour, shape or number.</span></p>',
              Keywords: ["attributes", "2201-04", "mathematics is everywhere"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Properties",
                "Numeral systems",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-21T05:38:54",
            },
            draft: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-03-06T05:13:35Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 27,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 106,
              SharedToClassroom: 0,
              GoToResource: 75,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
                collectionId: {
                  application: "urh",
                  id: "53417c8b-da4a-4479-a186-5362c1c23979",
                  source: "collections",
                },
              },
              {
                rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
                collectionId: {
                  application: "urh",
                  id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
                  source: "collections",
                },
              },
              {
                rcsId: "24a3acfa-574f-49e4-8f58-997a5ca29588",
                collectionId: {
                  application: "urh",
                  id: "a4a32d1d-95c6-4e99-a6bc-f5d67f74e8b9",
                  source: "collections",
                },
              },
              {
                rcsId: "55b0b099-0350-4eb1-8704-22a21248f017",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "f34c7290-3a49-4cf2-8093-6ec9a49777c9",
                collectionId: {
                  application: "urh",
                  id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
                  source: "collections",
                },
              },
              {
                rcsId: "ba03b8e4-8097-45a0-9b33-010d4120076f",
                collectionId: {
                  application: "urh",
                  id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
                  source: "collections",
                },
              },
              {
                rcsId: "5a937455-0f70-4d67-9363-ac776e958765",
                collectionId: {
                  application: "urh",
                  id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
                  source: "collections",
                },
              },
              {
                rcsId: "e567f4ee-3238-49dd-bf2e-ed2f42ae371f",
                collectionId: {
                  application: "urh",
                  id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
                  source: "collections",
                },
              },
              {
                rcsId: "c8f62e95-42fd-49f5-97f6-43242d7fd6ac",
                collectionId: {
                  application: "urh",
                  id: "7455e9b2-610b-4580-b73c-689e79d0f1dd",
                  source: "collections",
                },
              },
              {
                rcsId: "d5667ed5-c9d9-4fef-b555-6fff65d8cfb0",
                collectionId: {
                  application: "urh",
                  id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
                  source: "collections",
                },
              },
              {
                rcsId: "deabaa3b-c4ad-4219-a5d3-2d8ce7b2f1b1",
                collectionId: {
                  application: "urh",
                  id: "027a340b-b7d7-4c8f-bcdd-2674157356bc",
                  source: "collections",
                },
              },
              {
                rcsId: "153c7eb0-e6c1-4bb9-bfc3-a55c42446823",
                collectionId: {
                  application: "urh",
                  id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                "<p>Discover numbers, shapes and patterns you might find in your environment.</p>",
              Keywords: ["attributes", "2201-04", "mathematics is everywhere"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-12-01T06:02:19",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Finding maths in our environment",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Properties",
                "Numeral systems",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-04",
              professionalLearning: [],
              DateResCreated: "2022-12-01T06:02:19",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-04",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-04.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-10T05:32:13",
            },
            draft: {},
            record: {
              created: "2022-12-01T06:02:19Z",
              modified: "2023-03-11T06:33:10Z",
              collection: "public",
              published: true,
              id: "2201-04-04",
              version: 29,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 149,
              SharedToClassroom: 0,
              GoToResource: 103,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "4b4006ae-0be6-48f0-a120-fb3f4d17e261",
            collectionId: {
              application: "urh",
              id: "53417c8b-da4a-4479-a186-5362c1c23979",
              source: "collections",
            },
          },
          {
            rcsId: "3d583dde-4ba2-4144-af23-461819f50067",
            collectionId: {
              application: "urh",
              id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
              source: "collections",
            },
          },
          {
            rcsId: "1d1aed6a-9dc5-46a8-96a7-ded02138333c",
            collectionId: {
              application: "urh",
              id: "8fbb7cec-fa0b-44f8-99f3-c2b884454e8b",
              source: "collections",
            },
          },
          {
            rcsId: "24a3acfa-574f-49e4-8f58-997a5ca29588",
            collectionId: {
              application: "urh",
              id: "a4a32d1d-95c6-4e99-a6bc-f5d67f74e8b9",
              source: "collections",
            },
          },
          {
            rcsId: "55b0b099-0350-4eb1-8704-22a21248f017",
            collectionId: {
              application: "urh",
              id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
              source: "collections",
            },
          },
          {
            rcsId: "f34c7290-3a49-4cf2-8093-6ec9a49777c9",
            collectionId: {
              application: "urh",
              id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
              source: "collections",
            },
          },
          {
            rcsId: "ba03b8e4-8097-45a0-9b33-010d4120076f",
            collectionId: {
              application: "urh",
              id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
              source: "collections",
            },
          },
          {
            rcsId: "5a937455-0f70-4d67-9363-ac776e958765",
            collectionId: {
              application: "urh",
              id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
              source: "collections",
            },
          },
          {
            rcsId: "e567f4ee-3238-49dd-bf2e-ed2f42ae371f",
            collectionId: {
              application: "urh",
              id: "bcba3d01-08ae-4352-b784-b68a9325f35b",
              source: "collections",
            },
          },
          {
            rcsId: "c8f62e95-42fd-49f5-97f6-43242d7fd6ac",
            collectionId: {
              application: "urh",
              id: "7455e9b2-610b-4580-b73c-689e79d0f1dd",
              source: "collections",
            },
          },
          {
            rcsId: "d5667ed5-c9d9-4fef-b555-6fff65d8cfb0",
            collectionId: {
              application: "urh",
              id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
              source: "collections",
            },
          },
          {
            rcsId: "deabaa3b-c4ad-4219-a5d3-2d8ce7b2f1b1",
            collectionId: {
              application: "urh",
              id: "027a340b-b7d7-4c8f-bcdd-2674157356bc",
              source: "collections",
            },
          },
          {
            rcsId: "153c7eb0-e6c1-4bb9-bfc3-a55c42446823",
            collectionId: {
              application: "urh",
              id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
              source: "collections",
            },
          },
          {
            rcsId: "c36eac16-478b-4e68-b921-f86aecfc8dad",
            collectionId: {
              application: "urh",
              id: "8c8dad06-734e-4c31-9406-31bed9d4c011",
              source: "collections",
            },
          },
        ],
        audit: { sub: "donna.germany", iss: "olp" },
        draft: {},
        record: {
          created: "2022-12-01T06:02:19Z",
          modified: "2023-03-24T02:50:22Z",
          collection: "public",
          published: true,
          id: "2201-04-04",
          version: 31,
        },
        userAccess: {
          owner: ["irvin.flack"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 2,
        },
        files: [],
        links: [],
        misc: {
          Views: 163,
          SharedToClassroom: 0,
          GoToResource: 112,
          SharedToTeams: 0,
          Likes: 0,
          LikedBy: [],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["Mathematics"],
          Description:
            '<p><span style="color: rgb(68, 68, 68);">Practise counting, recognising numbers, colours and more.</span></p>',
          Keywords: ["attributes", "2201-04", "number recognition", "colours"],
          Phase: [
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-11-28T03:21:28",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "Mathematics K-2",
                  name: "demonstrates an understanding of how whole numbers indicate quantity",
                  id: "MAE-RWN-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "reads numerals and represents whole numbers to at least 20",
                  id: "MAE-RWN-02",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                  id: "MAE-2DS-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                  id: "MAO-WM-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                },
              ],
              name: "Mathematics K-2",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "public",
          Name: "Warm-up and review 1",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Web page",
          RandNTopic: [],
          LitLearnProgLevelV3: [],
          SIPTerms: [],
          FocusArea: [],
          NumLearnProgLevel: [],
          Stage: ["ES1"],
          links: [],
          NumLearnProgLevelV3: [
            {
              level: "CPr1",
              text: "CPr1",
              id: "CPr1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr2",
              text: "CPr2",
              id: "CPr2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "NPV1",
              text: "NPV1",
              id: "NPV1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "UGP1",
              text: "UGP1",
              id: "UGP1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
          ],
          QAAssured: true,
          Topic: [
            "Shapes (Geometry)",
            "Counting",
            "Subitising",
            "Number (Mathematics)",
            "Numeral systems",
            "Patterns",
            "Properties",
          ],
          PLLinks: [
            {
              name: "Mathematics Early Stage 1 Unit 1 - Attributes can be used to sort objects",
              id: "CUR-MP-U-001",
              url: "",
            },
          ],
          ResourceURL:
            "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
          professionalLearning: [],
          DateResCreated: "2022-11-28T03:21:28",
          ResourceType: "classroomresource",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [],
          ItemID: "2201-04-02",
          AusCurrContDesc: [],
          RelatedResources: [],
          Year: ["K"],
          LitLearnProgLevel: [],
          SchoolType: [],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-23T05:54:22",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: [],
              Description: "",
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: [],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: [],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:21:28",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:21:29Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: [],
              Description: "",
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: [],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: [],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:25:11",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:25:11Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 1,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:28:53",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:28:54Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 2,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:37:24",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:37:25Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 3,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:41:47",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:41:47Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:45:25",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:45:25Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 5,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:45:25",
            },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:45:25",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:45:31Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 6,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:45:25",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:46:21Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 7,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:46:38",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:46:38Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 8,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:46:38",
            },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:46:38",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T03:56:59Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 9,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:46:38",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T04:05:04Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 10,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: ["shapes", "counting"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T04:08:08",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T04:08:08Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 11,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T04:22:10",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T04:22:10Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 12,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
                { name: "tf", id: "2201-04-04", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
                { name: "tf", id: "2201-04-06", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T04:30:03",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T04:30:03Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 13,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
                { name: "tf", id: "2201-04-04", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
                { name: "tf", id: "2201-04-06", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T04:32:26",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T04:32:26Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 14,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Daily Review of Shapes, Counting, Subitising and Data 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data analysis",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                { name: "Maths is All Around Us", id: "2201-04-03", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
                { name: "tf", id: "2201-04-06", url: "" },
                { name: "tf", id: "2201-04-05", url: "" },
                { name: "", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T05:31:39",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T05:31:39Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 15,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T23:18:50",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-28T23:18:50Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 16,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-30T23:19:13",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-30T23:19:13Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 17,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-30T23:21:45",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-11-30T23:21:46Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 18,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:47:07",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-01T05:47:07Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 19,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 1,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "zarn.bou", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:47:07",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:47:07",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-05T00:44:38Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 20,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.jpg",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:47:07",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-07T06:51:18Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 21,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-11T22:13:24",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-11T22:13:24Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 22,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T00:13:01",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-12T00:13:01Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 23,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T03:48:15",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-12T03:48:15Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 24,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-15T22:47:00",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-15T22:46:59Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 25,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-15T22:47:24",
            },
            document: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-15T22:47:23Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 26,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 2,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "marc.burgess2", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:04:52",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2022-12-20T05:04:52Z",
              collection: "public",
              published: false,
              id: "2201-04-02",
              version: 27,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 3,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:04:52",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:04:52",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-01-30T06:20:04Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 28,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 4,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-31T23:08:52",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-01-31T23:08:52Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 29,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 23,
              SharedToClassroom: 0,
              GoToResource: 17,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-06T00:13:58",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-06T00:13:58Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 30,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 24,
              SharedToClassroom: 0,
              GoToResource: 18,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-07T01:23:13",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-07T01:23:13Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 31,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 39,
              SharedToClassroom: 0,
              GoToResource: 26,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "emilymay.norman", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics – Early Stage 1 – Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-09T00:50:59",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-15T10:52:12Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 34,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 73,
              SharedToClassroom: 0,
              GoToResource: 54,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "marc.burgess2", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through practice of previously-taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Foundation skills 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T01:54:33",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-17T01:54:33Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 35,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 75,
              SharedToClassroom: 0,
              GoToResource: 55,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "marc.burgess2", iss: "olp" },
            draft: {},
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: black;">A warm-up resource guiding students through practice of previously taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
                thumbnailUrl:
                  "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T07:06:50",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-17T07:06:50Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 36,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 75,
              SharedToClassroom: 0,
              GoToResource: 55,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: black;">A warm-up resource guiding Kindergarten students through practice of previously taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
                thumbnailUrl:
                  "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Maths is all around us", id: "2201-04-03", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://images.unsplash.com/photo-1611957082126-061f655ef1fb?crop=entropy&cs=tinysrgb&fm=jpg&ixid=MnwyNzAzODF8MHwxfHNlYXJjaHwxfHxjb3VudGluZ3xlbnwwfDF8fHwxNjc2NjE1MTcy&ixlib=rb-4.0.3&q=80",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T07:17:49",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-20T00:03:17Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 38,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 89,
              SharedToClassroom: 0,
              GoToResource: 67,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "fc69c7a3-c2d8-4157-a23c-e62c7c6fc241",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: black;">A warm-up resource guiding Kindergarten students through practice of previously taught skills.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "whole number",
                "patterns",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-20T05:23:06",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-02-21T02:27:59Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 40,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 95,
              SharedToClassroom: 0,
              GoToResource: 73,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "fc69c7a3-c2d8-4157-a23c-e62c7c6fc241",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "d736bafe-edb2-42c3-b0ce-9eba1365fc76",
                collectionId: {
                  application: "urh",
                  id: "fef80178-c54f-4481-9047-169a45ce95a0",
                  source: "collections",
                },
              },
              {
                rcsId: "182ba18e-2919-428f-a50b-3e8eaaa6f6eb",
                collectionId: {
                  application: "urh",
                  id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
                  source: "collections",
                },
              },
              {
                rcsId: "9a5b4788-50ad-47ab-8886-b9f98b66f5f4",
                collectionId: {
                  application: "urh",
                  id: "4856a7c2-c807-4c36-95c7-c68029497b68",
                  source: "collections",
                },
              },
              {
                rcsId: "4e6cca45-f23c-4c13-880a-eb3ea525626e",
                collectionId: {
                  application: "urh",
                  id: "400c52ce-13b0-4bd8-9093-30ae75fd7cdc",
                  source: "collections",
                },
              },
              {
                rcsId: "41d17b26-588b-49f2-af48-a3cd8a540158",
                collectionId: {
                  application: "urh",
                  id: "bf06bff7-5f05-4995-95dd-b9f26c15916b",
                  source: "collections",
                },
              },
              {
                rcsId: "0048aed4-7724-402c-8a86-fd50509681b3",
                collectionId: {
                  application: "urh",
                  id: "997ce178-b6ba-4451-9599-68284ff30eda",
                  source: "collections",
                },
              },
              {
                rcsId: "45c79e70-ce8d-480d-a58b-b1222324ee78",
                collectionId: {
                  application: "urh",
                  id: "260476b1-78c3-42d3-8bc9-6f4a78b0bb0d",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: black;">A warm-up resource guiding Kindergarten students through practice of previously taught skills.</span></p>',
              Keywords: [
                "attributes",
                "2201-04",
                "number recognition",
                "colours",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Numeral systems",
                "Patterns",
                "Properties",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-21T03:45:04",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-03-08T00:03:08Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 49,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 163,
              SharedToClassroom: 0,
              GoToResource: 428,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "fc69c7a3-c2d8-4157-a23c-e62c7c6fc241",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "d736bafe-edb2-42c3-b0ce-9eba1365fc76",
                collectionId: {
                  application: "urh",
                  id: "fef80178-c54f-4481-9047-169a45ce95a0",
                  source: "collections",
                },
              },
              {
                rcsId: "182ba18e-2919-428f-a50b-3e8eaaa6f6eb",
                collectionId: {
                  application: "urh",
                  id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
                  source: "collections",
                },
              },
              {
                rcsId: "9a5b4788-50ad-47ab-8886-b9f98b66f5f4",
                collectionId: {
                  application: "urh",
                  id: "4856a7c2-c807-4c36-95c7-c68029497b68",
                  source: "collections",
                },
              },
              {
                rcsId: "4e6cca45-f23c-4c13-880a-eb3ea525626e",
                collectionId: {
                  application: "urh",
                  id: "400c52ce-13b0-4bd8-9093-30ae75fd7cdc",
                  source: "collections",
                },
              },
              {
                rcsId: "41d17b26-588b-49f2-af48-a3cd8a540158",
                collectionId: {
                  application: "urh",
                  id: "bf06bff7-5f05-4995-95dd-b9f26c15916b",
                  source: "collections",
                },
              },
              {
                rcsId: "0048aed4-7724-402c-8a86-fd50509681b3",
                collectionId: {
                  application: "urh",
                  id: "997ce178-b6ba-4451-9599-68284ff30eda",
                  source: "collections",
                },
              },
              {
                rcsId: "45c79e70-ce8d-480d-a58b-b1222324ee78",
                collectionId: {
                  application: "urh",
                  id: "260476b1-78c3-42d3-8bc9-6f4a78b0bb0d",
                  source: "collections",
                },
              },
              {
                rcsId: "71ac8592-b83d-46f5-896a-dcab3a1dfb93",
                collectionId: {
                  application: "urh",
                  id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
                  source: "collections",
                },
              },
              {
                rcsId: "54e3b784-5d4b-4dd3-962a-b60e703da435",
                collectionId: {
                  application: "urh",
                  id: "759ddd91-2a9a-421e-aa95-7f6e130ffdec",
                  source: "collections",
                },
              },
              {
                rcsId: "cdbf2164-836c-4420-8d0e-fa5803d70ecd",
                collectionId: {
                  application: "urh",
                  id: "363accb3-c414-4152-b317-5042636c8eb4",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(68, 68, 68);">Practise counting, recognising numbers, colours and more.</span></p>',
              Keywords: [
                "attributes",
                "2201-04",
                "number recognition",
                "colours",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Numeral systems",
                "Patterns",
                "Properties",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-10T05:29:45",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-03-19T21:05:49Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 53,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 229,
              SharedToClassroom: 0,
              GoToResource: 523,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "fc69c7a3-c2d8-4157-a23c-e62c7c6fc241",
                collectionId: {
                  application: "urh",
                  id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
                  source: "collections",
                },
              },
              {
                rcsId: "d736bafe-edb2-42c3-b0ce-9eba1365fc76",
                collectionId: {
                  application: "urh",
                  id: "fef80178-c54f-4481-9047-169a45ce95a0",
                  source: "collections",
                },
              },
              {
                rcsId: "182ba18e-2919-428f-a50b-3e8eaaa6f6eb",
                collectionId: {
                  application: "urh",
                  id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
                  source: "collections",
                },
              },
              {
                rcsId: "9a5b4788-50ad-47ab-8886-b9f98b66f5f4",
                collectionId: {
                  application: "urh",
                  id: "4856a7c2-c807-4c36-95c7-c68029497b68",
                  source: "collections",
                },
              },
              {
                rcsId: "4e6cca45-f23c-4c13-880a-eb3ea525626e",
                collectionId: {
                  application: "urh",
                  id: "400c52ce-13b0-4bd8-9093-30ae75fd7cdc",
                  source: "collections",
                },
              },
              {
                rcsId: "41d17b26-588b-49f2-af48-a3cd8a540158",
                collectionId: {
                  application: "urh",
                  id: "bf06bff7-5f05-4995-95dd-b9f26c15916b",
                  source: "collections",
                },
              },
              {
                rcsId: "0048aed4-7724-402c-8a86-fd50509681b3",
                collectionId: {
                  application: "urh",
                  id: "997ce178-b6ba-4451-9599-68284ff30eda",
                  source: "collections",
                },
              },
              {
                rcsId: "45c79e70-ce8d-480d-a58b-b1222324ee78",
                collectionId: {
                  application: "urh",
                  id: "260476b1-78c3-42d3-8bc9-6f4a78b0bb0d",
                  source: "collections",
                },
              },
              {
                rcsId: "71ac8592-b83d-46f5-896a-dcab3a1dfb93",
                collectionId: {
                  application: "urh",
                  id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
                  source: "collections",
                },
              },
              {
                rcsId: "54e3b784-5d4b-4dd3-962a-b60e703da435",
                collectionId: {
                  application: "urh",
                  id: "759ddd91-2a9a-421e-aa95-7f6e130ffdec",
                  source: "collections",
                },
              },
              {
                rcsId: "cdbf2164-836c-4420-8d0e-fa5803d70ecd",
                collectionId: {
                  application: "urh",
                  id: "363accb3-c414-4152-b317-5042636c8eb4",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {},
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(68, 68, 68);">Practise counting, recognising numbers, colours and more.</span></p>',
              Keywords: [
                "attributes",
                "2201-04",
                "number recognition",
                "colours",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:21:28",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Warm-up and review 1",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Number (Mathematics)",
                "Numeral systems",
                "Patterns",
                "Properties",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1 - Mathematics Early Stage 1 Unit 1 - Attributes can be used to sort objects",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-02",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:21:28",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-02",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-02.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-23T05:52:49",
            },
            record: {
              created: "2022-11-28T03:21:29Z",
              modified: "2023-03-23T05:52:49Z",
              collection: "public",
              published: true,
              id: "2201-04-02",
              version: 54,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 229,
              SharedToClassroom: 0,
              GoToResource: 523,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "fe4c3c31-b15f-42e0-a780-272de5abf528",
            collectionId: {
              application: "urh",
              id: "6dae149d-3672-4902-84d5-cd84ada16054",
              source: "collections",
            },
          },
          {
            rcsId: "49b61edf-1c0e-4c94-b4d5-b336ce038263",
            collectionId: {
              application: "urh",
              id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
              source: "collections",
            },
          },
          {
            rcsId: "f1390dc1-e25f-47ac-bd16-52ee9a3580c5",
            collectionId: {
              application: "urh",
              id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
              source: "collections",
            },
          },
          {
            rcsId: "fc69c7a3-c2d8-4157-a23c-e62c7c6fc241",
            collectionId: {
              application: "urh",
              id: "c0489c9e-a238-40b4-a18d-b92df48fa49b",
              source: "collections",
            },
          },
          {
            rcsId: "d736bafe-edb2-42c3-b0ce-9eba1365fc76",
            collectionId: {
              application: "urh",
              id: "fef80178-c54f-4481-9047-169a45ce95a0",
              source: "collections",
            },
          },
          {
            rcsId: "182ba18e-2919-428f-a50b-3e8eaaa6f6eb",
            collectionId: {
              application: "urh",
              id: "4885ebb0-4ec4-4ea9-b423-cee3d238d05a",
              source: "collections",
            },
          },
          {
            rcsId: "9a5b4788-50ad-47ab-8886-b9f98b66f5f4",
            collectionId: {
              application: "urh",
              id: "4856a7c2-c807-4c36-95c7-c68029497b68",
              source: "collections",
            },
          },
          {
            rcsId: "4e6cca45-f23c-4c13-880a-eb3ea525626e",
            collectionId: {
              application: "urh",
              id: "400c52ce-13b0-4bd8-9093-30ae75fd7cdc",
              source: "collections",
            },
          },
          {
            rcsId: "41d17b26-588b-49f2-af48-a3cd8a540158",
            collectionId: {
              application: "urh",
              id: "bf06bff7-5f05-4995-95dd-b9f26c15916b",
              source: "collections",
            },
          },
          {
            rcsId: "0048aed4-7724-402c-8a86-fd50509681b3",
            collectionId: {
              application: "urh",
              id: "997ce178-b6ba-4451-9599-68284ff30eda",
              source: "collections",
            },
          },
          {
            rcsId: "45c79e70-ce8d-480d-a58b-b1222324ee78",
            collectionId: {
              application: "urh",
              id: "260476b1-78c3-42d3-8bc9-6f4a78b0bb0d",
              source: "collections",
            },
          },
          {
            rcsId: "71ac8592-b83d-46f5-896a-dcab3a1dfb93",
            collectionId: {
              application: "urh",
              id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
              source: "collections",
            },
          },
          {
            rcsId: "54e3b784-5d4b-4dd3-962a-b60e703da435",
            collectionId: {
              application: "urh",
              id: "759ddd91-2a9a-421e-aa95-7f6e130ffdec",
              source: "collections",
            },
          },
          {
            rcsId: "cdbf2164-836c-4420-8d0e-fa5803d70ecd",
            collectionId: {
              application: "urh",
              id: "363accb3-c414-4152-b317-5042636c8eb4",
              source: "collections",
            },
          },
          {
            rcsId: "fc6cf704-1ac6-4f8d-8f77-01d1ef9ca608",
            collectionId: {
              application: "urh",
              id: "8c8dad06-734e-4c31-9406-31bed9d4c011",
              source: "collections",
            },
          },
        ],
        audit: { sub: "donna.germany", iss: "olp" },
        draft: {},
        record: {
          created: "2022-11-28T03:21:29Z",
          modified: "2023-03-24T02:50:43Z",
          collection: "public",
          published: true,
          id: "2201-04-02",
          version: 56,
        },
        userAccess: {
          owner: ["irvin.flack"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 2,
        },
        files: [],
        links: [],
        misc: {
          Views: 255,
          SharedToClassroom: 0,
          GoToResource: 549,
          SharedToTeams: 0,
          Likes: 0,
          LikedBy: [],
        },
      },
      {
        professionalLearning: [],
        document: {
          KLA: ["Mathematics"],
          Description:
            "<p>Identify shapes and numbers you might find around you.</p>",
          Keywords: ["2201-04", "mathematics is everywhere"],
          Phase: [
            {
              phase: "Classroom practice",
              id: "tlcp.classroompractice",
              category: ["tlr"],
              cycle: "Teaching and learning cycle phase",
            },
          ],
          ResourceImage: {
            author: "",
            resourceImage:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
            thumbnailUrl:
              "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
          },
          IsAustCurr: null,
          IsNSWSyllabus: true,
          DateCreated: "2022-11-28T03:56:10",
          NSWSyllabus: [
            {
              outcomes: [
                {
                  syllabus: "Mathematics K-2",
                  name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                  id: "MAE-2DS-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "demonstrates an understanding of how whole numbers indicate quantity",
                  id: "MAE-RWN-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "reads numerals and represents whole numbers to at least 20",
                  id: "MAE-RWN-02",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                },
                {
                  syllabus: "Mathematics K-2",
                  name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                  id: "MAO-WM-01",
                  url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                },
              ],
              name: "Mathematics K-2",
            },
          ],
          ResourceCategory: ["tlr"],
          Source: "public",
          Name: "Maths is all around us",
          Rights: "© State of New South Wales (Department of Education).",
          Format: "Web page",
          RandNTopic: [],
          LitLearnProgLevelV3: [],
          SIPTerms: [],
          FocusArea: [],
          NumLearnProgLevel: [],
          Stage: ["ES1"],
          links: [],
          NumLearnProgLevelV3: [
            {
              level: "CPr1",
              text: "CPr1",
              id: "CPr1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr2",
              text: "CPr2",
              id: "CPr2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr3",
              text: "CPr3",
              id: "CPr3",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "CPr5",
              text: "CPr5",
              id: "CPr5",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
              subElement: "Counting processes",
              element: "Number sense and algebra",
            },
            {
              level: "NPV1",
              text: "NPV1",
              id: "NPV1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "NPV2",
              text: "NPV2",
              id: "NPV2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
              subElement: "Number and place value",
              element: "Number sense and algebra",
            },
            {
              level: "UGP1",
              text: "UGP1",
              id: "UGP1",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
            {
              level: "UGP2",
              text: "UGP2",
              id: "UGP2",
              type: "numeracy",
              url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
              subElement: "Understanding geometric properties",
              element: "Measurement and geometry",
            },
          ],
          QAAssured: true,
          Topic: [
            "Shapes (Geometry)",
            "Counting",
            "Subitising",
            "Numeral systems",
            "Number (Mathematics)",
            "Patterns",
          ],
          PLLinks: [
            {
              name: "Mathematics Early Stage 1 Unit 1 - Attributes can be used to sort objects",
              id: "CUR-MP-U-001",
              url: "",
            },
          ],
          ResourceURL:
            "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
          professionalLearning: [],
          DateResCreated: "2022-11-28T03:56:10",
          ResourceType: "classroomresource",
          SubSource: "",
          Publisher: "NSW Department of Education",
          curriculum: {},
          SEFAlignment: [],
          ItemID: "2201-04-03",
          AusCurrContDesc: [],
          RelatedResources: [
            {
              name: "Finding maths in our environment",
              id: "2201-04-04",
              url: "",
            },
            { name: "Counting objects", id: "2201-04-05", url: "" },
          ],
          Year: ["K"],
          LitLearnProgLevel: [],
          SchoolType: [],
          ThumbnailImage:
            "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
          Author: "NSW Department of Education",
          Theme: "",
          DateModified: "2023-03-23T05:53:55",
        },
        archive: [
          {
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: [],
              Description: "",
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "ff",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: [],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL: "fff",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: [],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T03:56:10",
            },
            document: { professionalLearning: [], links: [], curriculum: {} },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-11-28T03:56:10Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 0,
            },
            files: [],
            subResources: [],
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: [],
              Description: "",
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is All Around Us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: [],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: [],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-11-28T04:34:59",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-11-28T04:34:59Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 1,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing Maths in their environment.</span></p>',
              Keywords: [],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: false,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:49:32",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-01T05:49:32Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 2,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "doe-01-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:54:49",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-01T05:54:50Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 3,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:57:16",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-01T05:57:16Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 4,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "zarn.bou", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:57:16",
            },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:57:16",
            },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-04T23:36:09Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 5,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage: "",
                thumbnailUrl: "",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage: "",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-01T05:57:16",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-07T06:55:05Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 6,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Data collection",
                "Data representation",
                "Numerical order",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-11T22:14:17",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-11T22:14:17Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 7,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attribute",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T00:15:53",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-12T00:15:53Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 8,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAE-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-12T03:49:12",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-12T03:49:12Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 9,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:05:53",
            },
            document: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2022-12-20T05:05:53Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 10,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 0,
              SharedToClassroom: 0,
              GoToResource: 0,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:05:53",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics is everywhere! Teacher resource",
                  id: "2201-04-34",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2022-12-20T05:05:53",
            },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-01-30T06:08:54Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 11,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 2,
              SharedToClassroom: 0,
              GoToResource: 3,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-01-31T23:09:17",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-01-31T23:09:17Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 12,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 22,
              SharedToClassroom: 0,
              GoToResource: 16,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [],
            audit: { sub: "irvin.flack", iss: "olp" },
            draft: {},
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-07T01:20:37",
            },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-07T01:20:37Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 13,
            },
            files: [],
            links: [],
            subResources: [],
            curriculum: {},
            misc: {
              Views: 22,
              SharedToClassroom: 0,
              GoToResource: 16,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "emilymay.norman", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Teacher guidance for resources related to Mathematics - Early Stage 1 - Unit 1 unit of work",
                  id: "2201-04-01",
                  url: "",
                },
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-07T01:22:18",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-15T10:52:50Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 17,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 52,
              SharedToClassroom: 0,
              GoToResource: 36,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "marc.burgess2", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T01:55:35",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-18T04:53:59Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 19,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 54,
              SharedToClassroom: 0,
              GoToResource: 37,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "marc.burgess2", iss: "olp" },
            document: {},
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T01:55:35",
            },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-18T23:06:23Z",
              collection: "public",
              published: false,
              id: "2201-04-03",
              version: 20,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 54,
              SharedToClassroom: 0,
              GoToResource: 37,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "7af9f580-3fbb-4113-abc5-f128501e6065",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T01:55:35",
            },
            draft: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                { name: "Foundation skills 1", id: "2201-04-02", url: "" },
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-17T01:55:35",
            },
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-20T00:19:42Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 22,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 59,
              SharedToClassroom: 0,
              GoToResource: 39,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "7af9f580-3fbb-4113-abc5-f128501e6065",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "69b0d517-d0ad-4179-a1a4-bee5fca0ed0b",
                collectionId: {
                  application: "urh",
                  id: "be4a5d0e-248a-49d0-a6e8-1fd4f9663c49",
                  source: "collections",
                },
              },
              {
                rcsId: "ee1ce1d0-ac86-4b65-8d22-a6f08af056b8",
                collectionId: {
                  application: "urh",
                  id: "ca92ad5f-9a6c-4d31-824e-274486cb13b9",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: [
                "shapes",
                "counting",
                "subitising",
                "whole number",
                "patterns",
                "sorting",
                "attributes",
                "2201-04",
              ],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numerical order",
                "Number (Mathematics)",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-21T02:11:00",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-02-21T02:50:34Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 25,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 69,
              SharedToClassroom: 0,
              GoToResource: 49,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "7af9f580-3fbb-4113-abc5-f128501e6065",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "69b0d517-d0ad-4179-a1a4-bee5fca0ed0b",
                collectionId: {
                  application: "urh",
                  id: "be4a5d0e-248a-49d0-a6e8-1fd4f9663c49",
                  source: "collections",
                },
              },
              {
                rcsId: "ee1ce1d0-ac86-4b65-8d22-a6f08af056b8",
                collectionId: {
                  application: "urh",
                  id: "ca92ad5f-9a6c-4d31-824e-274486cb13b9",
                  source: "collections",
                },
              },
              {
                rcsId: "5ecba72c-af19-4011-9b2c-e20e095d47b7",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "265f5b05-e4e0-4895-83bd-38a47a0c6a1f",
                collectionId: {
                  application: "urh",
                  id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
                  source: "collections",
                },
              },
              {
                rcsId: "dd43e19d-6dda-49c6-8422-3655711c19c3",
                collectionId: {
                  application: "urh",
                  id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
                  source: "collections",
                },
              },
              {
                rcsId: "8c2abc0a-85fd-4cda-9003-f9be234a1620",
                collectionId: {
                  application: "urh",
                  id: "90c6d8cd-7d9a-4df7-b572-9864eb831ecb",
                  source: "collections",
                },
              },
              {
                rcsId: "a5df578f-f3ff-4bc1-ae75-0573f544dbe2",
                collectionId: {
                  application: "urh",
                  id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                '<p><span style="color: rgb(0, 0, 0);">A resource guiding students through observing maths in their environment.</span></p>',
              Keywords: ["2201-04", "mathematics is everywhere"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numeral systems",
                "Number (Mathematics)",
                "Patterns",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-02-21T05:17:30",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-03-05T02:37:43Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 31,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 137,
              SharedToClassroom: 0,
              GoToResource: 98,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
          {
            professionalLearning: [],
            collections: [
              {
                rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
                collectionId: {
                  application: "urh",
                  id: "6dae149d-3672-4902-84d5-cd84ada16054",
                  source: "collections",
                },
              },
              {
                rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
                collectionId: {
                  application: "urh",
                  id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
                  source: "collections",
                },
              },
              {
                rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
                collectionId: {
                  application: "urh",
                  id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
                  source: "collections",
                },
              },
              {
                rcsId: "7af9f580-3fbb-4113-abc5-f128501e6065",
                collectionId: {
                  application: "urh",
                  id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
                  source: "collections",
                },
              },
              {
                rcsId: "69b0d517-d0ad-4179-a1a4-bee5fca0ed0b",
                collectionId: {
                  application: "urh",
                  id: "be4a5d0e-248a-49d0-a6e8-1fd4f9663c49",
                  source: "collections",
                },
              },
              {
                rcsId: "ee1ce1d0-ac86-4b65-8d22-a6f08af056b8",
                collectionId: {
                  application: "urh",
                  id: "ca92ad5f-9a6c-4d31-824e-274486cb13b9",
                  source: "collections",
                },
              },
              {
                rcsId: "5ecba72c-af19-4011-9b2c-e20e095d47b7",
                collectionId: {
                  application: "urh",
                  id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
                  source: "collections",
                },
              },
              {
                rcsId: "265f5b05-e4e0-4895-83bd-38a47a0c6a1f",
                collectionId: {
                  application: "urh",
                  id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
                  source: "collections",
                },
              },
              {
                rcsId: "dd43e19d-6dda-49c6-8422-3655711c19c3",
                collectionId: {
                  application: "urh",
                  id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
                  source: "collections",
                },
              },
              {
                rcsId: "8c2abc0a-85fd-4cda-9003-f9be234a1620",
                collectionId: {
                  application: "urh",
                  id: "90c6d8cd-7d9a-4df7-b572-9864eb831ecb",
                  source: "collections",
                },
              },
              {
                rcsId: "a5df578f-f3ff-4bc1-ae75-0573f544dbe2",
                collectionId: {
                  application: "urh",
                  id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
                  source: "collections",
                },
              },
              {
                rcsId: "013b979b-7f57-47b6-85e9-d6a177fceb7e",
                collectionId: {
                  application: "urh",
                  id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
                  source: "collections",
                },
              },
            ],
            audit: { sub: "irvin.flack", iss: "olp" },
            document: {
              KLA: ["Mathematics"],
              Description:
                "<p>Identify shapes and numbers you might find around you.</p>",
              Keywords: ["2201-04", "mathematics is everywhere"],
              Phase: [
                {
                  phase: "Classroom practice",
                  id: "tlcp.classroompractice",
                  category: ["tlr"],
                  cycle: "Teaching and learning cycle phase",
                },
              ],
              ResourceImage: {
                author: "",
                resourceImage:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
                thumbnailUrl:
                  "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              },
              IsAustCurr: null,
              IsNSWSyllabus: true,
              DateCreated: "2022-11-28T03:56:10",
              NSWSyllabus: [
                {
                  outcomes: [
                    {
                      syllabus: "Mathematics K-2",
                      name: "sorts, describes, names and makes two-dimensional shapes, including triangles, circles, squares and rectangles",
                      id: "MAE-2DS-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-2DS-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "demonstrates an understanding of how whole numbers indicate quantity",
                      id: "MAE-RWN-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-01",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "reads numerals and represents whole numbers to at least 20",
                      id: "MAE-RWN-02",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAE-RWN-02",
                    },
                    {
                      syllabus: "Mathematics K-2",
                      name: "develops understanding and fluency in mathematics through exploring and connecting mathematical concepts, choosing and applying mathematical techniques to solve problems, and communicating their thinking and reasoning coherently and clearly",
                      id: "MAO-WM-01",
                      url: "http://educationstandards.nsw.edu.au/wps/portal/nesa/Advanced%20Search?search_query=MAO-WM-01",
                    },
                  ],
                  name: "Mathematics K-2",
                },
              ],
              ResourceCategory: ["tlr"],
              Source: "public",
              Name: "Maths is all around us",
              Rights: "© State of New South Wales (Department of Education).",
              Format: "Web page",
              RandNTopic: [],
              LitLearnProgLevelV3: [],
              SIPTerms: [],
              FocusArea: [],
              NumLearnProgLevel: [],
              Stage: ["ES1"],
              links: [],
              NumLearnProgLevelV3: [
                {
                  level: "CPr1",
                  text: "CPr1",
                  id: "CPr1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr1",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr2",
                  text: "CPr2",
                  id: "CPr2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr2",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr3",
                  text: "CPr3",
                  id: "CPr3",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr3",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "CPr5",
                  text: "CPr5",
                  id: "CPr5",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=CPr5",
                  subElement: "Counting processes",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV1",
                  text: "NPV1",
                  id: "NPV1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV1",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "NPV2",
                  text: "NPV2",
                  id: "NPV2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=NPV2",
                  subElement: "Number and place value",
                  element: "Number sense and algebra",
                },
                {
                  level: "UGP1",
                  text: "UGP1",
                  id: "UGP1",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP1",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
                {
                  level: "UGP2",
                  text: "UGP2",
                  id: "UGP2",
                  type: "numeracy",
                  url: "https://educationstandards.nsw.edu.au/wps/wcm/connect/7a7c08ac-8c7b-43db-934b-4a71f46a790e/national-numeracy-learning-progression.pdf?MOD=AJPERES&CVID=UGP2",
                  subElement: "Understanding geometric properties",
                  element: "Measurement and geometry",
                },
              ],
              QAAssured: true,
              Topic: [
                "Shapes (Geometry)",
                "Counting",
                "Subitising",
                "Numeral systems",
                "Number (Mathematics)",
                "Patterns",
              ],
              PLLinks: [
                {
                  name: "Mathematics Early Stage 1 Unit 1",
                  id: "CUR-MP-U-001",
                  url: "",
                },
              ],
              ResourceURL:
                "https://www.studentresources.nsw.edu.au/resources/2201-04-03",
              professionalLearning: [],
              DateResCreated: "2022-11-28T03:56:10",
              ResourceType: "classroomresource",
              SubSource: "",
              Publisher: "NSW Department of Education",
              curriculum: {},
              SEFAlignment: [],
              ItemID: "2201-04-03",
              AusCurrContDesc: [],
              RelatedResources: [
                {
                  name: "Finding maths in our environment",
                  id: "2201-04-04",
                  url: "",
                },
                { name: "Counting objects", id: "2201-04-05", url: "" },
              ],
              Year: ["K"],
              LitLearnProgLevel: [],
              SchoolType: [],
              ThumbnailImage:
                "https://resourceimages.azurewebsites.net/nswsll/2201-04-03.png",
              Author: "NSW Department of Education",
              Theme: "",
              DateModified: "2023-03-10T05:30:37",
            },
            draft: {},
            record: {
              created: "2022-11-28T03:56:10Z",
              modified: "2023-03-11T06:32:57Z",
              collection: "public",
              published: true,
              id: "2201-04-03",
              version: 33,
            },
            files: [],
            subResources: [],
            links: [],
            curriculum: {},
            misc: {
              Views: 173,
              SharedToClassroom: 0,
              GoToResource: 131,
              SharedToTeams: 0,
              Likes: 0,
              LikedBy: [],
            },
          },
        ],
        subResources: [],
        curriculum: {},
        collections: [
          {
            rcsId: "6ea204ba-dc1f-4b6a-a565-3ab3daff9523",
            collectionId: {
              application: "urh",
              id: "6dae149d-3672-4902-84d5-cd84ada16054",
              source: "collections",
            },
          },
          {
            rcsId: "bd2a8a90-8d14-485d-9329-5cac579a23e6",
            collectionId: {
              application: "urh",
              id: "9a15e430-74e7-4791-a1e7-7da5a0bb5a18",
              source: "collections",
            },
          },
          {
            rcsId: "529f0109-7a5c-4eef-bdaf-ad40029359f8",
            collectionId: {
              application: "urh",
              id: "3df0add6-0b7c-4cb2-9a93-c782786ecd8a",
              source: "collections",
            },
          },
          {
            rcsId: "7af9f580-3fbb-4113-abc5-f128501e6065",
            collectionId: {
              application: "urh",
              id: "49b2bd1a-c9a8-4d88-a9c5-10345aa9e0bf",
              source: "collections",
            },
          },
          {
            rcsId: "69b0d517-d0ad-4179-a1a4-bee5fca0ed0b",
            collectionId: {
              application: "urh",
              id: "be4a5d0e-248a-49d0-a6e8-1fd4f9663c49",
              source: "collections",
            },
          },
          {
            rcsId: "ee1ce1d0-ac86-4b65-8d22-a6f08af056b8",
            collectionId: {
              application: "urh",
              id: "ca92ad5f-9a6c-4d31-824e-274486cb13b9",
              source: "collections",
            },
          },
          {
            rcsId: "5ecba72c-af19-4011-9b2c-e20e095d47b7",
            collectionId: {
              application: "urh",
              id: "a0961ad2-dac6-4a35-bdd9-5587f60c5a2e",
              source: "collections",
            },
          },
          {
            rcsId: "265f5b05-e4e0-4895-83bd-38a47a0c6a1f",
            collectionId: {
              application: "urh",
              id: "f15480e8-a4d7-49ba-a5b0-21a608d4b9a7",
              source: "collections",
            },
          },
          {
            rcsId: "dd43e19d-6dda-49c6-8422-3655711c19c3",
            collectionId: {
              application: "urh",
              id: "1cec0b5f-9361-4c85-9339-d5821539f93c",
              source: "collections",
            },
          },
          {
            rcsId: "8c2abc0a-85fd-4cda-9003-f9be234a1620",
            collectionId: {
              application: "urh",
              id: "90c6d8cd-7d9a-4df7-b572-9864eb831ecb",
              source: "collections",
            },
          },
          {
            rcsId: "a5df578f-f3ff-4bc1-ae75-0573f544dbe2",
            collectionId: {
              application: "urh",
              id: "6a343a66-e5dd-49b0-878c-4a497d652cb3",
              source: "collections",
            },
          },
          {
            rcsId: "013b979b-7f57-47b6-85e9-d6a177fceb7e",
            collectionId: {
              application: "urh",
              id: "ebe5b92f-58af-41c2-9b03-4eff0313fad5",
              source: "collections",
            },
          },
        ],
        audit: { sub: "irvin.flack", iss: "olp" },
        draft: {},
        record: {
          created: "2022-11-28T03:56:10Z",
          modified: "2023-03-23T05:53:55Z",
          collection: "public",
          published: true,
          id: "2201-04-03",
          version: 34,
        },
        userAccess: {
          owner: ["irvin.flack"],
          editor: [],
          viewer: [],
          schools: [],
          scope: 2,
        },
        files: [],
        links: [],
        misc: {
          Views: 188,
          SharedToClassroom: 0,
          GoToResource: 140,
          SharedToTeams: 0,
          Likes: 0,
          LikedBy: [],
        },
      },
    ],
  },
};
