/* eslint-disable @typescript-eslint/no-var-requires */
import { AxiosAdapter, AxiosResponse, AxiosResponseHeaders } from "axios";

/**
 * Mock response delay in seconds.
 * A delay makes the mock experience more realistic,
 * giving us a chance to see spinners and any "pop-in" effect.
 */
const MOCK_RESPONSE_DELAY = 0.5 * 1000;

/**
 * An Axios adapter that returns mock data instead of doing real API calls.
 * This adapter completely circumvents real requests and should only be used in development environments.
 * @param config - The config object Axios passes to the adapter.
 * @returns A promise that always resolves with a mock response.
 */
export const mock_request_adapter: AxiosAdapter = (config) =>
  new Promise((res) => {
    const response: AxiosResponse<unknown> = {
      data: {},
      status: 200,
      statusText: "OK",
      headers: (config.headers ?? {}) as AxiosResponseHeaders,
      config: config,
      request: config,
    };

    const mockFilename = response.config["mock"];

    try {
      const mockData = require("../../../api/" + mockFilename).default;
      response["data"] = mockData;
    } catch (error) {
      console.error(error);
      response["status"] = 404;
      response["statusText"] = "Mock data not found.";
    }

    /**
     * Returning a Promise here so that we can use setTimeout
     * to simulate a delay in the response.
     */

    setTimeout(() => res(response), MOCK_RESPONSE_DELAY);
  });
