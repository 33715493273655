var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{staticClass:"mx-auto ml-0",style:({ 'max-width': '240px' })},[_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"110px","src":_vm.classroomPicture,"error":(_vm.classroomPicture = _vm.defaultPicture)}},[_c('v-checkbox',{staticClass:"classprofile v-input--is-focused",staticStyle:{"margin-left":"83%"},attrs:{"color":"#A0A5AE","dark":""},model:{value:(_vm.checkItem),callback:function ($$v) {_vm.checkItem=$$v},expression:"checkItem"}}),_c('v-card-title',{staticClass:"py-2 px-4"},[(_vm.classroom.classStatus)?_c('v-chip',{attrs:{"small":"","color":_vm.classroom.classStatus == _vm.classStatus.PUBLISH
              ? '#CBEDFD'
              : '#EBEBEB'}},[_c('span',{staticClass:"subtitle-2",style:({
              color:
                _vm.classroom.classStatus == _vm.classStatus.PUBLISH
                  ? '#002664'
                  : '#495054',
              'text-transform': 'uppercase',
            })},[_vm._v(_vm._s(_vm.classroom.classStatus))])]):_vm._e()],1)],1),_c('v-card-title',{staticClass:"pr-0"},[_c('img',{style:({ 'object-fit': 'contain', width: '30px', height: '30px' }),attrs:{"src":'./' +
          // @ts-ignore
          (this.classroom.classType === 'GoogleClassroom'
            ? 'googleclassroom.png'
            : 'microsoftclassroom.png'),"alt":_vm.classroom.classType}}),_c('span',{staticClass:"pl-3 subtitle-2",staticStyle:{"color":"#407ec9"}},[_vm._v(" "+_vm._s(_vm.classroom.classType === "GoogleClassroom" ? "Google Classroom" : "Microsoft Teams")+" ")])]),_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"title class-name",attrs:{"title":_vm.classroom.className}},[_vm._v(" "+_vm._s(_vm.classroom.className)+" ")])]),_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"body-2",staticStyle:{"color":"#495054","display":"flex"},attrs:{"title":_vm.details}},[_c('p',{staticClass:"class-details"},[_vm._v(" "+_vm._s(_vm.details)+" ")])])]),_c('v-card-actions',{staticClass:"pl-4 justify-space-between"},[(_vm.totalStudents)?_c('span',{staticClass:"subtitle-2",staticStyle:{"color":"#22272b"}},[_vm._v(_vm._s(_vm.totalStudents.length)+" "+_vm._s(_vm.totalStudents.length === 1 ? "student" : "students"))]):_vm._e(),_c('div',{staticClass:"text-right pr-2 actions-right"},[_c('v-menu',{attrs:{"bottom":"","close-on-content-click":false,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"width":"30px","height":"30px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticStyle:{"width":"270px"}},[_c('v-list-item-group',[_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{style:({
                    color: item.title == 'Delete' ? '#D7153A' : '',
                  }),on:{"click":() => {
                      _vm.performAction(item.action);
                    }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),(_vm.classroom.status != _vm.classStatus.ARCHIVE)?_c('v-list-item',{staticClass:"pt-2 pr-2",staticStyle:{"border-top":"1px solid #ccc","cursor":"pointer"}},[_c('div',{staticClass:"d-flex grow justify-space-between"},[_c('div',{staticClass:"subtitle-1",staticStyle:{"font-size":"16px","color":"#22272b"}},[_vm._v(" Sync timetable changes ")]),_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},model:{value:(_vm.timetablesync),callback:function ($$v) {_vm.timetablesync=$$v},expression:"timetablesync"}})],1)]):_vm._e()],2)],1)],1)],1)])],1),(_vm.classroom.classStatus === _vm.classStatus.ARCHIVE)?_c('Dialog',{attrs:{"openDialog":_vm.deletionDialogOpen,"title":"Delete class","maxWidth":"520px","displayCloseBtn":true,"message":_vm.deletionMessage,"actions":_vm.deletionActions,"return-handler":_vm.handleDialogReturn},on:{"close":function($event){_vm.openDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }