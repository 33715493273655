export default {
  aud: "6d1823a5-0470-4cb3-a7bc-1c777a930229",
  iss: "https://fs.det.nsw.edu.au/adfs",
  iat: 1675310981,
  nbf: 1675310981,
  exp: 1675314581,
  auth_time: 1675310522,
  nonce:
    "21,96,173,135,8,166,121,107,37,195,9,75,227,156,144,248,70,25,71,66,25,129,178,43,129,121,199,130,219,183,176,56",
  sub: "e4NgLLSCtDFIOCSg/uHNp+ds2CucxeT2di1wkBN+4Cc=",
  unique_name: "sukhjinder.saini1",
  sid: "46542d94-bf5a-42ae-b9c8-d6ad54f2848e",
  upn: "sukhjinder.saini1@det.nsw.edu.au",
  givenName: "Allena",
  sn: "Walker",
  email: "Sukhjinder.Saini1@det.nsw.edu.au",
  det_user_guid: "4cadabe5-eea0-47f9-8bf4-78b2514bc6bf",
  username: "sukhjinder.saini1",
  roles: ["SCHOOL.OTHERCORPORATE", "PRINCIPAL.DEPUTY.PS", "SCHOOL.TEACHER1"],
  city: "Eveleigh",
  title: "Front End Developer",
  locations: ["1001", "1002"],
  apptype: "Public",
  appid: "6d1823a5-0470-4cb3-a7bc-1c777a930229",
  authmethod:
    "urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport",
  ver: "1.0",
  scp: "openid",
};
