
import lodash from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";
import { ClassroomCheck, Computed, Data, Methods } from "./index.types";

import {
  ChipFilterItem,
  createSelectionFilter,
  createUniqueFilterItems,
} from "@/utils/chipFilter";

import { Banner, ChipFilter } from "@nswdoe/doe-ui-core";

import ProfileCard from "@/components/ProfileCard/ProfileCard.vue";
import router from "@/router";
import {
  Classroom,
  ClassroomStatus,
  ClassroomType,
} from "@/types/Classroom.types";
import { isPopulatedArray } from "@/utils/general";

export default Vue.extend<Data, Methods, Computed>({
  name: "Home",
  title: "Class Dashboard",
  methods: {
    updateSearch(value) {
      this.searchField = value;
    },
    checkClassroom(item) {
      // Check if the item exists in the checkItems array
      const classroomIndex = this.checkItems.findIndex(
        (classroom) => item.classId === classroom.classId
      );

      // Also grab the full object from the allClasses array
      const classroom = this.allClasses.find(
        (classroom) => item.classId === classroom.classId
      );

      // If the full object is not found, can't add it to the checkItems array
      if (!classroom) return;

      // Spread the classroom object and add the isChecked property
      const checkedItem = { ...classroom, isChecked: item.value };

      if (classroomIndex === -1) {
        this.checkItems.push(checkedItem);
      } else {
        Vue.set(this.checkItems, classroomIndex, checkedItem);
      }
    },
    startCreateClass(type: ClassroomType) {
      router.push({ name: "create", query: { type } });
    },
    /**
     * This method orchestrates the archival of classes from both the multi checkbox selection,
     * and from archiving single classes by pressing the button in the ellipses dropdown.
     */
    deleteClasses(classrooms: Classroom[]) {
      classrooms.forEach((classroom) => {
        if (classroom.classStatus !== ClassroomStatus.ARCHIVE) {
          const alert = {
            type: "warning",
            text: "Delete class",
            subtext: `Cannot delete classes in status ${classroom.classStatus}`,
          };

          // Dispatch an alert to the store
          this.$store.dispatch("alerts/addAlert", alert);
          return;
        }

        this.$store
          .dispatch("classCards/deleteClass", {
            ...classroom,
            status: ClassroomStatus.ARCHIVE,
          })
          .then(() => {
            const alert = {
              type: "success",
              text: "Selected class deleted",
            };

            // Dispatch an alert to the store
            this.$store.dispatch("alerts/addAlert", alert);
          })
          .catch(() => {
            const alert = {
              type: "error",
              text: "Selected class failed to delete",
            };

            // Dispatch an alert to the store
            this.$store.dispatch("alerts/addAlert", alert);
          });
      });

      // Uncheck all items after archiving
      this.checkItems.forEach((checkItem, index) => {
        if (checkItem.isChecked) {
          this.checkItems.splice(index, 1, { ...checkItem, isChecked: false });
        }
      });
    },
    /**
     * This method orchestrates the archival of classes from both the multi checkbox selection,
     * and from archiving single classes by pressing the button in the ellipses dropdown.
     */
    archiveClasses(classrooms: Classroom[]) {
      classrooms.forEach((classroom) => {
        if (
          classroom.classStatus !== ClassroomStatus.DRAFT &&
          classroom.classStatus !== ClassroomStatus.PUBLISH
        ) {
          const alert = {
            type: "warning",
            text: "Archived class",
            subtext: `Cannot archive classes in status ${classroom.classStatus}`,
          };

          // Dispatch an alert to the store
          this.$store.dispatch("alerts/addAlert", alert);
          return;
        }

        this.$store
          .dispatch("classCards/archiveClass", classroom)
          .then(() => {
            const alert = {
              type: "success",
              text: "Selected class archived",
            };

            // Dispatch an alert to the store
            this.$store.dispatch("alerts/addAlert", alert);
          })
          .catch(() => {
            const alert = {
              type: "error",
              text: "Selected class failed to archive",
            };

            // Dispatch an alert to the store
            this.$store.dispatch("alerts/addAlert", alert);
          });
      });

      // Uncheck all items after archiving
      this.checkItems.forEach((checkItem, index) => {
        if (checkItem.isChecked) {
          this.checkItems.splice(index, 1, { ...checkItem, isChecked: false });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      hasAdminAccess: "userAttributes/hasAdminAccess",
      allClasses: "classCards/allClasses",
      findAllSchools: "schoolsLookup/findAllSchools",
      profile: "userAttributes/profile",
    }),
    selectedClassCards() {
      return this.checkItems.filter((classInfo) => classInfo.isChecked);
    },
    showStatusFilter() {
      return this.$route.name === "home";
    },
    statusFilter() {
      if (!isPopulatedArray(this.allClasses)) {
        return [{ id: "1", name: "N/A" }];
      }

      /**
       * The BAs acceptance criteria for this filter is that it should only show
       * the statuses for active classes. That requires this filter to be hardcoded.
       */
      return [ClassroomStatus.DRAFT, ClassroomStatus.PUBLISH].reduce<
        ChipFilterItem[]
      >(
        (previous, status) =>
          createUniqueFilterItems(previous, {
            id: status,
            name: status,
          }),
        []
      );
    },
    schoolFilter() {
      if (!isPopulatedArray(this.allClasses)) {
        return [{ id: "1", name: "N/A" }];
      }

      return this.findAllSchools.reduce<ChipFilterItem[]>(
        (previous, { schoolCode, schoolFullName }) =>
          createUniqueFilterItems(previous, {
            id: schoolCode,
            name: schoolFullName,
          }),
        []
      );
    },
    filterForKLAs() {
      if (!isPopulatedArray(this.allClasses)) {
        return [{ id: "1", name: "N/A" }];
      }

      return this.allClasses
        .flatMap((classroom) =>
          classroom.klas.map((kla) => ({ ...classroom, kla }))
        )
        .reduce<ChipFilterItem[]>(
          (previous, { classId, kla }) =>
            createUniqueFilterItems(previous, { id: classId, name: kla }),
          []
        );
    },
    yearFilter() {
      if (!isPopulatedArray(this.allClasses)) {
        return [{ id: "1", name: "N/A" }];
      }

      return this.allClasses.reduce<ChipFilterItem[]>(
        (previous, { classId, classYearLevel }) =>
          createUniqueFilterItems(previous, {
            id: classId,
            name: classYearLevel,
          }),
        []
      );
    },
    classesData() {
      const filterField = this.searchField;

      const searchFilter = ({
        schoolCode,
        className,
        classType,
        classYearLevel,
        classStatus,
        klas,
      }: ClassroomCheck): boolean => {
        return (
          [
            schoolCode,
            className,
            classType,
            classYearLevel,
            classStatus,
            ...klas,
          ]
            // Filtering out null values so that comparison.includes below doesn't throw errors
            .filter((value) => !!value)
            .some((comparison) =>
              comparison.toLowerCase().includes(filterField.toLowerCase())
            )
        );
      };

      const isSchoolSelected = createSelectionFilter(
        this.schoolFilter,
        this.selectedSchools
      );

      const isKLASelected = createSelectionFilter(
        this.filterForKLAs,
        this.selectedKLA
      );

      const isYearSelected = createSelectionFilter(
        this.yearFilter,
        this.selectedYear
      );

      const isStatusSelected = createSelectionFilter(
        this.statusFilter,
        this.selectedStatus
      );

      /**
       * Filter classes in an order of priority:
       * 1. If the search field is populated, filter by the search field but also check that either no pills are selected or the pills match
       * 2. If no pills are selected, return all classes
       * 3. If pills are selected, filter by the pills
       *
       * For 3., the createSelectionFilter takes care of empty filters by returning true.
       * The only exception to this is the KLA filter, which is handled by the kla_match variable.
       * Due to classroom.klas also being an array, the .some will return false negatives if we don't ensure
       * that the selectedKLA array is populated beforehand.
       */
      const filteredClasses = this.allClasses.filter((classroom) => {
        const query_match = searchFilter(classroom);

        const no_pills_selected = [
          this.selectedSchools,
          this.selectedKLA,
          this.selectedYear,
          this.selectedStatus,
        ].every((selection) => selection.length === 0);

        const kla_match =
          this.selectedKLA.length == 0 ||
          classroom.klas.some((kla) => isKLASelected(kla));

        const pills_match =
          isSchoolSelected(classroom.schoolCode) &&
          isYearSelected(classroom.classYearLevel) &&
          isStatusSelected(classroom.classStatus) &&
          kla_match;

        if (filterField)
          return query_match && (no_pills_selected || pills_match);
        else if (no_pills_selected) return true;
        else return pills_match;
      });

      return lodash.sortBy(filteredClasses, ["status", "asc"]);
    },
  },
  data: () => ({
    searchField: "",
    checkItems: [] as (Classroom & { isChecked: boolean })[],
    selectedSchools: [] as string[],
    selectedYear: [],
    selectedKLA: [],
    selectedStatus: [],
  }),
  async mounted() {
    this.$store.dispatch("classCards/fetchClasses");

    const { locations } = this.profile;
    await this.$store.dispatch("schoolsLookup/fetchSchoolInfo", locations);

    this.checkItems = this.allClasses.map((classroom) => ({
      ...classroom,
      isChecked: false,
    }));
  },
  watch: {
    /**
     * If showStatusFilter changes, this means the subroute has changed.
     * We need to reset the selectedStatus, as the
     * filter element may have been hidden by the v-if.
     */
    showStatusFilter() {
      this.selectedStatus = [];
    },
  },
  components: {
    Banner,
    ChipFilter,
    ProfileCard,
  },
});
