
import Vue from "vue";
import { mapGetters } from "vuex";
import { FullscreenDialog } from "@nswdoe/doe-ui-core";

import router from "@/router";
import { ClassroomType } from "@/types/Classroom.types";

import CreateClass from "./CreateClass.vue";

export default Vue.extend({
  name: "CreateFullscreen",
  async created() {
    const { locations } = this.profile;

    await this.$store.dispatch("schoolsLookup/fetchSchoolInfo", locations);
    this.$store.dispatch("schoolsLookup/fetchRollSubjectClass");

    const { type } = router.app.$route.query;

    /**
     * Set the type of class to create based on the query param passed in
     */
    if (!(type && typeof type === "string")) {
      this.createClassType = ClassroomType.GOOGLE;
    } else {
      switch (type.toLowerCase()) {
        case "microsoft":
          this.createClassType = ClassroomType.MICROSOFT;
          break;
        case "googleclassroom":
        default:
          this.createClassType = ClassroomType.GOOGLE;
          break;
      }
    }

    console.log(type, this.createClassType);
  },
  data: () => ({
    createClassFlow: false,
    createClassType: ClassroomType.GOOGLE,
    showDialog: true,
  }),
  methods: {
    closeDialog() {
      router.push("/");
    },
  },
  components: {
    CreateClass,
    // Dialog,
    FullscreenDialog,
  },
  computed: {
    ...mapGetters({
      profile: "userAttributes/profile",
      hasAdminAccess: "userAttributes/hasAdminAccess",
      allClasses: "classCards/allClasses",
    }),
  },
});
