
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { mapGetters } from "vuex";

import FindPeople from "./FindPeople.vue";

import {
  ClassPeopleStaff,
  ClassPeopleStudent,
  StudentStatus,
} from "@/types/ClassPeople.types";
import { School } from "@/types/School.types";

import {
  AdsButton,
  AdsDataTable,
  Dialog,
  TabNavigation,
} from "@nswdoe/doe-ui-core";

import { StaffTableItem, StudentTableItem, TableHeader } from "./People.types";

import {
  autoDedupStudent,
  filterStaffBySearch,
  filterStudentBySearch,
  staffToTableItem,
  studentToTableItem,
} from "./People.util";
import { Classroom } from "@/types/Classroom.types";
import { OAuthProfile } from "@/types/User.types";

interface Computed {
  findSchoolByCode(schoolCode: string): School | undefined;
  classHelpers: unknown;
  profile: OAuthProfile;

  modalSchool: School | Record<string, unknown>;
  headers: TableHeader[];
  items: StaffTableItem[] | StudentTableItem[];
  tableSearchLabel: string;
}

interface Props {
  classInfo: PropValidator<Classroom>;
}

interface Methods {
  addPeople: (items: {
    staffs: ClassPeopleStaff[];
    deltaStudents: ClassPeopleStudent[];
  }) => void;
  removePeople: () => void;
  cancelFindPeople: () => void;
  changeTab: (index) => void;
}

interface Data {
  classModal: Classroom;
  selected: Array<
    | (ClassPeopleStudent & StudentTableItem)
    | (ClassPeopleStaff & StaffTableItem)
  >;

  activeTab: number;
  tableSearch: string;

  showFindPeopleDialog: boolean;

  sortBy: string;
  sortDesc: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default Vue.extend<Data, Methods, Computed, Props>({
  name: "People",
  created() {
    // Dispatch an alert specific to this step
    this.$store.dispatch("alerts/addAlert", {
      type: "success",
      text: "Student list generated",
      subtext:
        "Your student list has been prefilled based on your class selection",
    });
  },
  data() {
    return {
      selected: [],
      activeTab: 0,
      tableSearch: "",
      showFindPeopleDialog: false,
      classModal: { ...this.classInfo } as Classroom,
      sortBy: "name",
      sortDesc: false,
    };
  },
  methods: {
    changeTab(index) {
      /**
       * If the tab is changed, we should clear the selected items
       * so that the user doesn't accidentally remove people from the wrong tab
       */
      this.selected = [];
      this.activeTab = index;
    },
    addPeople({ staffs, deltaStudents }) {
      console.log("addPeople", { staffs, deltaStudents });

      const dedupStaff = staffs.filter(
        (staff) =>
          this.classModal.classPeople.staffs.find(
            ({ email }) => email === staff.email
          ) === undefined
      );

      const dedupStudents = deltaStudents.filter(
        (student) =>
          this.classModal.classPeople.deltaStudents.find(
            ({ email }) => email === student.email
          ) === undefined
      );

      console.log("dedupStaff", dedupStaff);
      console.log("dedupStudents", dedupStudents);

      this.classModal.classPeople.staffs.push(...dedupStaff);
      this.classModal.classPeople.deltaStudents.push(...dedupStudents);
    },
    removePeople() {
      // Staff can simply be removed from the array
      const removeStaff = (item: ClassPeopleStaff & StaffTableItem) => {
        const index = this.classModal.classPeople.staffs.findIndex(
          ({ email }) => email === item.email
        );

        this.classModal.classPeople.staffs.splice(index, 1);
      };

      // Students are never truly removed, they are just marked with status REMOVED in the delta array
      const removeStudent = (item: ClassPeopleStudent & StudentTableItem) => {
        // Check if student is already in delta array
        const deltaIndex = this.classModal.classPeople.deltaStudents.findIndex(
          ({ email }) => email === item.email
        );

        // Also check for their index in auto array
        const autoIndex = this.classModal.classPeople.autoStudents.findIndex(
          ({ email }) => email === item.email
        );

        const student =
          deltaIndex > -1
            ? this.classModal.classPeople.deltaStudents[deltaIndex]
            : this.classModal.classPeople.autoStudents[autoIndex];

        const updatedBy = `${this.profile.given_name} ${this.profile.family_name}`;

        const new_student_object = {
          ...student,
          status: StudentStatus.REMOVED,
          updatedBy,
        };

        // If student is already in delta array, mutate index
        if (deltaIndex > -1) {
          Vue.set(
            this.classModal.classPeople.deltaStudents,
            deltaIndex,
            new_student_object
          );
        }
        // Else, push to delta array
        else {
          this.classModal.classPeople.deltaStudents.push(new_student_object);
        }
      };

      if (this.activeTab === 0) {
        const isStaff = (item): item is ClassPeopleStaff & StaffTableItem =>
          "detUserId" in item;
        this.selected.filter(isStaff).forEach(removeStaff);
      } else {
        const isStudent = (
          item
        ): item is ClassPeopleStudent & StudentTableItem => "status" in item;
        this.selected.filter(isStudent).forEach(removeStudent);
      }
    },
    cancelFindPeople() {
      this.showFindPeopleDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      classHelpers: "schoolsLookup/classHelpers",
      findSchoolByCode: "schoolsLookup/findSchoolByCode",
      profile: "userAttributes/profile",
    }),

    /**
     * This computed property relates the school code we have in the classModal
     * back to a full school object from the mappedSchoolInfo object.
     */
    modalSchool() {
      return this.findSchoolByCode(this.classModal.schoolCode) ?? {};
    },
    headers() {
      const staffHeaders = [
        { text: `Username (${this.items.length})`, value: "name" },
        { text: "Role", value: "role" },
        { text: "School", value: "school" },
        { text: "Updated by", value: "updatedBy" },
      ];

      const studentHeaders = [
        { text: `Username (${this.items.length})`, value: "name" },
        { text: "Year", value: "year" },
        { text: "Schools", value: "school" },
        { text: "Updated by", value: "updatedBy" },
        { text: "Status", value: "status", align: "center" },
      ];

      return this.activeTab === 0 ? staffHeaders : studentHeaders;
    },
    items() {
      const { autoStudents, deltaStudents, staffs } =
        this.classModal.classPeople;

      // Search string for us to filter staff and students by
      const tableSearch = this.tableSearch;

      // If active tab is Staff, simply map staff array
      if (this.activeTab === 0)
        return staffs
          .map(staffToTableItem)
          .filter(filterStaffBySearch(tableSearch));
      // Otherwise, run logic for merging autoStudents and deltaStudents
      else
        return [
          // Remove students from autoStudents if they are in deltaStudents
          ...autoStudents.filter(autoDedupStudent(deltaStudents)),
          ...deltaStudents,
        ]
          .map(studentToTableItem)
          .filter(filterStudentBySearch(tableSearch));
    },
    tableSearchLabel() {
      return this.activeTab === 0 ? "Search Staff" : "Search Students";
    },
  },
  components: {
    AdsButton,
    AdsDataTable,
    Dialog,
    TabNavigation,
    FindPeople,
  },
  props: {
    classInfo: {
      type: Object,
      default: () => ({}),
    },
  },
});
