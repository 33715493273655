/* eslint-disable @typescript-eslint/no-var-requires */
import router from "@/router";
import Vue from "vue";
import { OAuth } from "@nswdoe/doe-ui-core";

/**
 * The injection of Buffer here is a workaround because the ADS library
 * uses a package that depends on the Buffer API, which is not available
 * in browsers :)
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
global.Buffer = global.Buffer || require("buffer").Buffer;

const authOptions = {
  router: router,
  pkce: true,
  authoriseUri: process.env.VUE_APP_CDI_AUTH_URL,
  tokenUri: process.env.VUE_APP_CDI_TOKEN_URL,
  logoutUri: process.env.VUE_APP_CDI_LOGOUT_URL,
  forceProdMode: true,
  secureApp: true,
  params: {
    client_id: process.env.VUE_APP_CDI_AUTH_CLIENT_ID,
    redirect_uri: encodeURIComponent(
      `${window.location.origin}/login/callback`
    ),
    scope: encodeURIComponent(
      `${process.env.VUE_APP_CDI_AUTH_CLIENT_ID}/.default openid profile`
    ),
  },
};

export const oauth = OAuth.install(Vue, authOptions);
