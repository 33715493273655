import mockEssentials from "../data/essentials";

/* eslint no-console: "off" */
const fetchApps = (staffId) => {
  console.log("api-essentials fetchApps", staffId);
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockEssentials);
    }, 100);
  });
};

export default {
  fetchApps,
};
