
import Vue from "vue";

import { AdsButton } from "@nswdoe/doe-ui-core";

import {
  loadGoogleApis,
  loadGoogleClient,
} from "../../utils/googleDriveUpload";

interface AttachmentFiles {
  name: string;
  url: string;
  iconLink: string;
}

interface Data {
  gapi: any;
  google: any;
  accessToken: null | any;
  pickerInited: boolean;
  gisInited: boolean;
  tokenClient: any;
  filesForAttach: AttachmentFiles[];
}
const API_KEY = process.env.VUE_APP_GOOGLE_DRIVE_API_KEY;
const SCOPES = "https://www.googleapis.com/auth/drive.metadata.readonly";
const CLIENT_ID = process.env.VUE_APP_GOOGLE_DRIVE_CLIENT_ID;
const APP_ID = process.env.VUE_APP_GOOGLE_DRIVE_APP_ID;
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
let authButton: HTMLElement | null = null;
let signOutButton: HTMLElement | null = null;
export default Vue.extend<Data, any, any>({
  name: "GOOGLEDRIVE",
  components: {
    AdsButton,
  },
  data: () => ({
    gapi: (window as any).gapi,
    google: (window as any).google,
    accessToken: null,
    pickerInited: false,
    gisInited: false,
    tokenClient: Object as any,
    filesForAttach: [] as Array<AttachmentFiles>,
  }),
  async created() {
    this.$store.dispatch("setLoader", true);
    await loadGoogleApis(this.gapiLoaded);
    await loadGoogleClient(this.gisLoaded);
  },
  mounted() {
    authButton = document.getElementById("authorize_button")
      ? document.getElementById("authorize_button")
      : null;
    signOutButton = document.getElementById("signout_button")
      ? document.getElementById("signout_button")
      : null;
    authButton!.style.visibility = "hidden";
    signOutButton!.style.display = "none";
  },
  methods: {
    gapiLoaded() {
      this.gapi = (window as any).gapi;
      this.gapi.load("client", this.initializeGapiClient);
    },
    async initializeGapiClient() {
      await this.gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: [DISCOVERY_DOC],
      });
      this.gapi.load("client:picker", this.initializePicker);
    },
    initializePicker() {
      this.pickerInited = true;
      this.maybeEnableButtons();
    },
    gisLoaded() {
      this.google = (window as any).google;
      console.log(this.google.accounts.oauth2);
      this.tokenClient = this.google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        prompt: "",
      });

      this.gisInited = true;
      this.maybeEnableButtons();
    },
    maybeEnableButtons() {
      this.$store.dispatch("setLoader", false);
      if (this.pickerInited && this.gisInited) {
        authButton!.innerText = "Sign In";
        authButton!.style.visibility = "visible";
      }
    },
    handleAuthClick() {
      this.tokenClient.callback = async (response) => {
        if (
          response &&
          response.error &&
          response.error == "interaction_required"
        ) {
          this.tokenClient.requestAccessToken({ prompt: "consent" });
        }
        this.accessToken = response.access_token;
        signOutButton!.style.display = "block";
        signOutButton!.style.visibility = "visible";
        await this.createPicker();
      };
      if (this.accessToken === null) {
        this.tokenClient.requestAccessToken({ prompt: "consent" });
      } else {
        this.tokenClient.requestAccessToken({ prompt: "" });
      }
    },
    handleSignoutClick() {
      if (this.accessToken) {
        this.google.accounts.oauth2.revoke(this.accessToken);
        this.accessToken = null;
        signOutButton!.style.display = "none";
        authButton!.innerText = "Sign In";
      }
    },
    createPicker() {
      const view = new this.google.picker.View(this.google.picker.ViewId.DOCS);
      //view.setMimeTypes('image/png,image/jpeg,image/jpg');
      const picker = new this.google.picker.PickerBuilder()
        .enableFeature(this.google.picker.Feature.NAV_HIDDEN)
        .enableFeature(this.google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(APP_ID)
        .setOAuthToken(this.accessToken)
        .addView(view)
        .addView(new this.google.picker.DocsUploadView())
        .setCallback(this.pickerCallback)
        .build();
      picker.setVisible(true);
    },
    removeAttachment(index) {
      this.filesForAttach.splice(index, 1);
      this.$emit("gDriveAttach", this.filesForAttach);
    },
    async pickerCallback(data) {
      if (data.action === this.google.picker.Action.PICKED) {
        const documents = data[this.google.picker.Response.DOCUMENTS];
        let res: { name: string; url: string; iconLink: string }[] = [];
        for (let i = 0; i < documents.length; i++) {
          let documentfileId = documents[i][this.google.picker.Document.ID];
          await this.gapi.client.drive.files
            .get({
              fileId: documentfileId,
              fields: "*",
            })
            .then((filesRes) => {
              res.push(filesRes.result);
            })
            .catch((e) => {
              console.log("error", e);
            });
        }
        authButton!.innerText = "View Drive";
        signOutButton!.style.display = "block";
        signOutButton!.style.visibility = "visible";
        if (res && Array.isArray(res) && res.length > 0) {
          const filesForAttachment: AttachmentFiles[] = res.map((result) => {
            let obj = { name: "", url: "", iconLink: "" };
            obj.name = result.name ? result.name : "";
            obj.url = result.url ? result.url : "";
            obj.iconLink = result.iconLink ? result.iconLink : "";
            return obj;
          });
          this.filesForAttach = filesForAttachment;
          this.$emit("gDriveAttach", filesForAttachment);
        }
      }
    },
  },
});
