import { Classroom, ClassroomType } from "@/types/Classroom.types";

/**
 * A step object in the Create Class modal.
 */
export interface ModalStep {
  name: string;
  status: ModalStepStatus;
  type: ModalStepTypes;
  description: string;
  component: Vue.Component;
  isValid: boolean;
}

/**
 * The types of steps in the model creation process.
 * This enumeration does not dictate the order of the steps.
 */
export enum ModalStepTypes {
  CLASSROOM_SETUP = "Classroom set-up",
  PEOPLE = "People",
  RESOURCES = "Resources",
  SUMMARY = "Summary",
}

/**
 * The status of a step in the model creation process.
 * Can be either incomplete or complete.
 */
export enum ModalStepStatus {
  INCOMPLETE = "Incomplete",
  COMPLETE = "Complete",
}

export interface Data {
  validatedStep: boolean;
  currentStep: number;
  classModal: Classroom;
  appSteps: Array<ModalStep>;

  // Hack to inject enumerations into the Vue template
  ModalStepTypes: Record<keyof typeof ModalStepTypes, unknown>;
  ClassroomType: Record<keyof typeof ClassroomType, unknown>;
}

export interface Methods {
  saveClass(): void;
  publishClass(): void;
  changeStep(stepId: number): void;
  updateClassModal(classModalValue: Classroom): void;
  nextStep(): void;
  previousStep(): void;
  setValidStep(validated: boolean): void;
}

export interface ContentItems {
  id: number;
  content: object;
  title: string;
}

export interface Computed {
  classHelpers: any;
  activeStep: ModalStep;
  rollClass: unknown[];
  publishText: string;
}

export interface Props {
  classType: ClassroomType[keyof ClassroomType];
}
