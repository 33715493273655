
import Vue from "vue";
import { mapGetters } from "vuex";
import { AdsFileInput } from "@nswdoe/doe-ui-core";
import { NameAndId } from "../../../types/ClassroomContent.types";
import { googleDriveUpload } from "../../../utils/googleDriveUpload";
import { isPopulatedArray } from "@/utils/general";

interface Data {
  uploadFiles: string[] | null;
  readyForAttch: Array<NameAndId>;
  isOverflow: boolean;
}

interface Props {
  dialogShowOrHide: boolean;
}

interface Methods {
  uploadToGoogle(resource: FileList): void;
  removeLink(i: number): void;
  isOverflowed(): boolean;
}
interface Computed {
  token: string;
}
export default Vue.extend<Data, Methods, Computed, Props>({
  name: "UploadFile",
  components: {
    AdsFileInput,
  },
  data() {
    return {
      uploadFiles: [],
      readyForAttch: [],
      isOverflow: false,
    };
  },
  computed: {
    ...mapGetters({ token: "googleAuthToken" }),
  },
  async created() {
    this.$store.dispatch("setLoader", true);
    await this.$store.dispatch("getGoogleAuthToken");
  },
  methods: {
    isOverflowed() {
      let element: HTMLElement | null = document.getElementById("fileList");
      if (!element) return false;
      return element.scrollHeight > element.clientHeight;
    },
    async uploadToGoogle(fileObj) {
      if (isPopulatedArray(fileObj)) {
        this.$store.dispatch("setLoader", true);
        await googleDriveUpload(fileObj, this.token)
          .then((res) => {
            if (res && res.data && res.data.id && res.data.name) {
              const { id, name } = res.data;
              this.readyForAttch.push({ id, name });
              this.$emit("attachFromUpload", this.readyForAttch);
            }
          })
          .catch((e) => {
            throw e;
          })
          .finally(() => {
            this.uploadFiles = [];
            this.$store.dispatch("setLoader", false);
          });
      }
    },
    removeLink(i) {
      this.readyForAttch.splice(i - 1, 1);
    },
  },
  props: {
    dialogShowOrHide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    readyForAttch() {
      setTimeout(() => {
        this.isOverflow = this.isOverflowed();
      }, 500);
    },
    dialogShowOrHide() {
      this.readyForAttch = [];
    },
  },
});
