export const API_RESOURCES = {
  CREATE_CLASS: { method: "POST", url: "/class" },
  DELETE_CLASS: { method: "DELETE", url: "/class" },
  PUBLISH_CLASS: { method: "POST", url: "/class" },
  ARCHIVE_CLASS: { method: "POST", url: "/class" },
  EDIT_CLASS: { method: "PUT", url: "/class" },
  GET_CLASSES: { method: "GET", url: "/class" },
  GET_SCHOOL_INFO: { method: "GET", url: "/school" },
  GET_USER_ATTRIBUTES: { method: "GET", url: "/user" },
  GET_DRH_RESOURCES: {
    method: "POST",
    url: "/urhSearch",
  },
  GET_DRH_FILTERS: {
    method: "GET",
    url: "/urhFilters",
  },
  GET_DRH_RECOMMEND: { method: "POST", url: "/urhRecommend" },
  GET_GOOGLE_API_TOKEN: { method: "POST", url: "/gtoken" },
  UPLOAD_FILES: {
    method: "POST",
    url: "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
  },

  GET_ROLL_SUBJECT_CLASS: { method: "GET", url: "/rollSubjectClass" },

  GET_SEARCH_PEOPLE: { method: "GET", url: "/people" },
};
