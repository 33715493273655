export default {
  urhResult: {
    _id: "61544d9ff75d7b7b916871b7",
    klas: [
      { id: "Creative arts", name: "Creative arts" },
      { id: "English", name: "English" },
      { id: "HSIE", name: "HSIE" },
      { id: "Languages", name: "Languages" },
      { id: "Mathematics", name: "Mathematics" },
      { id: "PDHPE", name: "PDHPE" },
      { id: "Science", name: "Science" },
      { id: "TAS", name: "TAS" },
    ],
    library: [
      { id: "dlrh", name: "DLRH Resources" },
      { id: "dlrh_myresource", name: "My Resources" },
    ],
    randntopics: [
      { id: "Addition", name: "Addition", type: "Numeracy" },
      { id: "Algebra", name: "Algebra", type: "Numeracy" },
      {
        id: "Conversion of units",
        name: "Conversion of units",
        type: "Numeracy",
      },
      { id: "Division", name: "Division", type: "Numeracy" },
      { id: "Fractions", name: "Fractions", type: "Numeracy" },
      { id: "Geometry", name: "Geometry", type: "Numeracy" },
      { id: "Length", name: "Length", type: "Numeracy" },
      { id: "Measurement", name: "Measurement", type: "Numeracy" },
      { id: "Multiplication", name: "Multiplication", type: "Numeracy" },
      {
        id: "Number (Mathematics)",
        name: "Number (Mathematics)",
        type: "Numeracy",
      },
      { id: "Number patterns", name: "Number patterns", type: "Numeracy" },
      { id: "Phonemes", name: "Phonemes", type: "Reading" },
      { id: "Phonics", name: "Phonics", type: "Reading" },
      { id: "Place value", name: "Place value", type: "Numeracy" },
      { id: "Proportions", name: "Proportions", type: "Numeracy" },
      {
        id: "Reading comprehension",
        name: "Reading comprehension",
        type: "Reading",
      },
      { id: "Reading fluency", name: "Reading fluency", type: "Reading" },
      {
        id: "Statistics and probability",
        name: "Statistics and probability",
        type: "Numeracy",
      },
      { id: "Subtraction", name: "Subtraction", type: "Numeracy" },
      {
        id: "Time (Measurement)",
        name: "Time (Measurement)",
        type: "Numeracy",
      },
      { id: "Vocabularies", name: "Vocabularies", type: "Reading" },
    ],
    sources: [
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "aboriginalhscattainment",
        name: "Aboriginal HSC Attainment",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "attendance",
        name: "Attendance",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "curriculum",
        name: "Curriculum",
      },
      {
        application: "urh",
        resourcecatid: ["tlr"],
        id: "eald",
        name: "EAL/D Education",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "feedbackandcomplaints",
        name: "Feedback and Complaints",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "financialmanagement",
        name: "Financial Management",
      },
      {
        application: "urh",
        resourcecatid: ["tlr"],
        id: "inclusivepractice",
        name: "Inclusive Practice",
      },
      {
        application: "urh",
        resourcecatid: ["tlr"],
        id: "readingandnumeracy",
        name: "Literacy & Numeracy",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "multicultural",
        name: "Multicultural and Anti-Racism Education ",
      },
      {
        application: "olp",
        resourcecatid: ["tlr"],
        id: "department",
        name: "NSW SLL Department",
      },
      {
        application: "olp",
        resourcecatid: ["tlr"],
        id: "public",
        name: "NSW SLL Public",
      },
      {
        application: "urh",
        resourcecatid: ["tlr"],
        id: "pathways",
        name: "Pathways",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "principaldevelopment",
        name: "Principal Development ",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "professionalethicalstandards",
        name: "Professional and Ethical Standards (PES)",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "seia",
        name: "School Excellence in Action",
      },
      {
        application: "urh",
        resourcecatid: ["sir"],
        id: "slec",
        name: "School Learning Environments and Change",
      },
      {
        application: "urh",
        resourcecatid: ["tlr"],
        id: "stem",
        name: "stem.T4L Learning Library",
      },
    ],
    type: [
      { id: "Audio", name: "Audio" },
      { id: "Classroom", name: "Classroom resource" },
      { id: "Interactive", name: "Interactive" },
      { id: "Teacher", name: "Teacher Guide" },
      { id: "Text", name: "Text" },
      { id: "Video", name: "Video" },
    ],
    years: [
      { id: "K", name: "Kindergarten", shortName: "K" },
      { id: "1", name: "Year 1", shortName: "1" },
      { id: "2", name: "Year 2", shortName: "2" },
      { id: "3", name: "Year 3", shortName: "3" },
      { id: "4", name: "Year 4", shortName: "4" },
      { id: "5", name: "Year 5", shortName: "5" },
      { id: "6", name: "Year 6", shortName: "6" },
      { id: "7", name: "Year 7", shortName: "7" },
      { id: "8", name: "Year 8", shortName: "8" },
      { id: "9", name: "Year 9", shortName: "9" },
      { id: "10", name: "Year 10", shortName: "10" },
      { id: "11", name: "Year 11", shortName: "11" },
      { id: "12", name: "Year 12", shortName: "12" },
    ],
    stagesandyears: [
      { name: "Early Stage 1", id: "K" },
      {
        name: "Stage 1",
        id: "stage_one",
        children: [
          { name: "Year 1", id: "1" },
          { name: "Year 2", id: "2" },
        ],
      },
      {
        name: "Stage 2",
        id: "stage_two",
        children: [
          { name: "Year 3", id: "3" },
          { name: "Year 4", id: "4" },
        ],
      },
      {
        name: "Stage 3",
        id: "stage_three",
        children: [
          { name: "Year 5", id: "5" },
          { name: "Year 6", id: "6" },
        ],
      },
      {
        name: "Stage 4",
        id: "stage_four",
        children: [
          { name: "Year 7", id: "7" },
          { name: "Year 8", id: "8" },
        ],
      },
      {
        name: "Stage 5",
        id: "stage_five",
        children: [
          { name: "Year 9", id: "9" },
          { name: "Year 10", id: "10" },
        ],
      },
      {
        name: "Stage 6",
        id: "stage_six",
        children: [
          { name: "Year 11", id: "11" },
          { name: "Year 12", id: "12" },
        ],
      },
    ],
    stages: [
      { id: "stage_k", name: "Early Stage 1" },
      { id: "stage_one", name: "Stage 1" },
      { id: "stage_two", name: "Stage 2" },
      { id: "stage_three", name: "Stage 3" },
      { id: "stage_four", name: "Stage 4" },
      { id: "stage_five", name: "Stage 5" },
      { id: "stage_6", name: "Stage 6" },
    ],
    format: [
      { id: "Audio", name: "Audio" },
      { id: "Document", name: "Document" },
      { id: "Image", name: "Image" },
      { id: "Other", name: "Other" },
      { id: "Presentation", name: "Presentation" },
      { id: "Spreadsheet", name: "Spreadsheet" },
      { id: "Video", name: "Video" },
      { id: "Webpage", name: "Web page" },
    ],
    myresourcetype: [
      { id: "assessment", name: "Assessment resource" },
      { id: "classroom", name: "Classroom resource" },
      { id: "lesson", name: "Lesson plan" },
      { id: "school", name: "School planning resource" },
      { id: "scope", name: "Scope and sequence" },
      { id: "teacher", name: "Teacher Guide" },
      { id: "teacherLearning", name: "Teaching and learning program" },
    ],
    myresourcetypelibraries: [
      { id: "createdbyme", name: "Created by me" },
      { id: "sharedwithme", name: "Shared with me" },
      { id: "sharedbyme", name: "Shared by me" },
    ],
    autosavetimer: { milliseconds: 120000 },
    focusarea: [
      {
        name: "Aboriginal HSC Attainment",
        id: "ahc",
        ownerid: "aboriginalhscattainment",
        children: [
          { name: "Community engagement", id: "aha.communityengagement" },
          { name: "Attendance", id: "aha.attendance" },
          { name: "Literacy and numeracy", id: "aha.literacyandnumeracy" },
        ],
      },
      {
        name: "Attendance",
        id: "att",
        ownerid: "attendance",
        children: [
          { name: "High expectations", id: "att.highexpectations" },
          {
            name: "Quality systems and processes",
            id: "att.qualitysystemsandprocesses",
          },
          { name: "Data systems", id: "att.datasystems" },
          { name: "Intervention-Student", id: "att.intervention-student" },
          { name: "Communication", id: "att.communication" },
        ],
      },
      {
        name: "Curriculum",
        id: "curriculum",
        ownerid: "curriculum",
        children: [
          { name: "Cyber safety", id: "curriculum.cybersafety" },
          { name: "Curriculum reform", id: "curriculum.reform" },
          {
            name: "Early learners and primary",
            id: "curriculum.earlylearnersandprimary",
          },
          {
            name: "High potential and gifted education",
            id: "curriculum.highpotentialandgiftededucation",
          },
          { name: "Secondary", id: "curriculum.secondary" },
        ],
      },
      {
        name: "EAL/D Education",
        id: "eald",
        ownerid: "eald",
        children: [
          {
            name: "Assessment and reporting",
            id: "eald.assessmentandreporting",
          },
          {
            name: "Supporting newly arrived students",
            id: "eald.supportingnewlyarrivedstudents",
          },
          { name: "Teaching and learning", id: "eald.teachingandlearning" },
          {
            name: "Whole school approach to EAL/D support",
            id: "eald.wholeschoolsupport",
          },
        ],
      },
      {
        name: "Feedback and Complaints",
        id: "feedbackandcomplaints",
        ownerid: "feedbackandcomplaints",
        children: [
          {
            name: "Parent and community complaints",
            id: "feedbackandcomplaints.parentcommunitycomplaints",
          },
        ],
      },
      {
        name: "Financial Management",
        id: "fm",
        ownerid: "financialmanagement",
        children: [
          { name: "School processes", id: "fm.schoolprocesses" },
          { name: "Alignment to SIP", id: "fm.alignmenttosip" },
          { name: "Planning for expenditure", id: "fm.planningforexpenditure" },
          { name: "Principal support", id: "fm.principalsupport" },
        ],
      },
      {
        name: "Inclusive Practice",
        id: "ip",
        ownerid: "inclusivepractice",
        children: [
          { name: "Adjustments", id: "ip.adjustments" },
          { name: "Behaviour", id: "ip.behaviour" },
          { name: "Bullying", id: "ip.bullying" },
          { name: "Classroom management", id: "ip.classroommanagement" },
          { name: "Inclusion", id: "ip.inclusion" },
          { name: "Social inclusion", id: "ip.socialinclusion" },
          { name: "Transitions", id: "ip.transitions" },
          { name: "Wellbeing", id: "ip.wellbeing" },
        ],
      },
      {
        name: "Literacy and Numeracy",
        id: "landn",
        ownerid: "readingandnumeracy",
        children: [
          { name: "Additive thinking", id: "landn.additivethinking" },
          { name: "Comprehension", id: "landn.comprehension" },
          { name: "Fluency", id: "landn.fluency" },
          {
            name: "Fractions and proportional reasoning",
            id: "landn.fractionsandproportionalreasoning",
          },
          {
            name: "Literacy numeracy guide",
            id: "landn.literacynumeracyguide",
          },
          {
            name: "Measurement and geometric reasoning",
            id: "landn.measurementandgeometricreasoning",
          },
          {
            name: "Multiplicative thinking",
            id: "landn.multiplicativethinking",
          },
          { name: "Number and place value", id: "landn.numberandplacevalue" },
          {
            name: "Number patterns and algebraic thinking",
            id: "landn.numberpatternsandalgebraicthinking",
          },
          { name: "Phonics", id: "landn.phonics" },
          { name: "Phonological awareness", id: "landn.phonologicalawareness" },
          {
            name: "Probability and statistical reasoning",
            id: "landn.probabilityandstatisticalreasoning",
          },
          { name: "Vocabulary", id: "landn.vocabulary" },
        ],
      },
      {
        name: "Multicultural and Anti-Racism Education",
        id: "multicultural",
        ownerid: "multicultural",
        children: [
          {
            name: "Anti-racism education for school planning",
            id: "multicultural.antiracismeducationforschoolplanning",
          },
        ],
      },
      {
        name: "Pathways",
        id: "pathways",
        ownerid: "pathways",
        children: [
          { name: "Career learning", id: "pathways.careerlearning" },
          {
            name: "Vocational education and training",
            id: "pathways.vocationaleducationandtraining",
          },
        ],
      },
      {
        name: "Principal Development",
        id: "principaldevelopment",
        ownerid: "principaldevelopment",
        children: [
          {
            name: "Performance and Accreditation",
            id: "principaldevelopment.performanceandaccreditation",
          },
          {
            name: "School Leadership Institute",
            id: "principaldevelopment.schoolleadershipinstitute",
          },
        ],
      },
      {
        name: "Professional and Ethical Standards (PES)",
        id: "professionalethicalstandards",
        ownerid: "professionalethicalstandards",
        children: [
          {
            name: "Complaints management",
            id: "professionalethicalstandards.complaintsmanagement",
          },
        ],
      },
      {
        name: "School Excellence in Action",
        id: "seia",
        ownerid: "seia",
        children: [
          {
            name: "Centre for Education Statistics and Evaluation",
            id: "seia.cese",
          },
          {
            name: "Implementation and progress monitoring support",
            id: "seia.implementationandprogressmonitoringsupport",
          },
          { name: "Leading Evaluation, Evidence and Data", id: "seia.leed" },
        ],
      },
      {
        name: "School Learning Environments and Change",
        id: "slec",
        ownerid: "slec",
        children: [
          { name: "Collaborative teaching", id: "slec.collaborativeteaching" },
          { name: "Educational change", id: "slec.educationalchange" },
          { name: "Learning modes", id: "slec.learningmodes" },
        ],
      },
      {
        name: "stem.T4L",
        id: "stem",
        ownerid: "stem",
        children: [{ name: "Digital literacy", id: "stem.digitalliteracy" }],
      },
    ],
    phase: [
      {
        resourcecatid: ["sir"],
        name: "School improvement cycle phase",
        id: "sicp",
        children: [
          { name: "Assess need", id: "sicp.assessneed" },
          { name: "Evaluate progress", id: "sicp.evaluateprogress" },
          { name: "Implement action", id: "sicp.implementaction" },
          { name: "Plan for improvement", id: "sicp.planforimprovement" },
        ],
      },
      {
        resourcecatid: ["tlr"],
        name: "Teaching and learning cycle phase",
        id: "tlcp",
        children: [
          {
            name: "Analysis for decision making",
            id: "tlcp.analysisfordecisionmaking",
          },
          {
            name: "Assessment, feedback and reporting",
            id: "tlcp.assessmentfeedbackandreporting",
          },
          { name: "Classroom practice", id: "tlcp.classroompractice" },
          {
            name: "Planning and programming",
            id: "tlcp.planningandprogramming",
          },
        ],
      },
    ],
    resourcecategories: [
      { id: "sir", name: "School Improvement Resource" },
      { id: "tlr", name: "Teaching and learning Resource" },
    ],
    resourcetype: [
      {
        resourcecatid: ["tlr"],
        id: "assessmentresource",
        name: "Assessment resource",
      },
      {
        resourcecatid: ["tlr"],
        id: "classroomresource",
        name: "Classroom resource",
      },
      { resourcecatid: ["sir"], id: "framework", name: "Framework" },
      {
        resourcecatid: ["sir"],
        id: "leadershipguide",
        name: "Leadership guide",
      },
      { resourcecatid: ["tlr"], id: "lessonplan", name: "Lesson plan" },
      { resourcecatid: ["sir"], id: "other", name: "Other" },
      { resourcecatid: ["sir"], id: "presentation", name: "Presentation" },
      {
        resourcecatid: ["sir"],
        id: "sir-schoolplanningresource",
        name: "School planning resource",
      },
      {
        resourcecatid: ["tlr"],
        id: "tlr-schoolplanningresource",
        name: "School planning resource",
      },
      {
        resourcecatid: ["tlr"],
        id: "scopeandsequence",
        name: "Scope and sequence",
      },
      { resourcecatid: ["tlr"], id: "teacherguide", name: "Teacher guide" },
      {
        resourcecatid: ["tlr"],
        id: "teachingandlearningprogram",
        name: "Teaching and learning program",
      },
      { resourcecatid: ["sir"], id: "template", name: "Template" },
    ],
    sefalignment: [
      {
        name: "Leading",
        id: "lead",
        children: [
          { name: "Educational leadership", id: "lead.educationalleadership" },
          {
            name: "Management practices and processes",
            id: "lead.managementpracticesandprocesses",
          },
          {
            name: "School planning, implementation and reporting",
            id: "lead.schoolplanningimplementationandreporting",
          },
          { name: "School resources", id: "lead.schoolresources" },
        ],
      },
      {
        name: "Learning",
        id: "learn",
        children: [
          { name: "Assessment", id: "learn.assessment" },
          { name: "Curriculum", id: "learn.curriculum" },
          { name: "Learning culture", id: "learn.learningculture" },
          { name: "Reporting", id: "learn.reporting" },
          {
            name: "Student performance measures",
            id: "learn.studentperformancemeasures",
          },
          { name: "Wellbeing", id: "learn.wellbeing" },
        ],
      },
      {
        name: "Teaching",
        id: "teach",
        children: [
          { name: "Data skills and use", id: "teach.dataskillsanduse" },
          {
            name: "Effective classroom practice",
            id: "teach.effectiveclassroompractice",
          },
          {
            name: "Learning and development",
            id: "teach.learninganddevelopment",
          },
          { name: "Professional standards", id: "teach.professionalstandards" },
        ],
      },
    ],
    schooltype: [
      { id: "central", name: "Central Schools" },
      { id: "primary", name: "Primary Schools" },
      { id: "specific", name: "Schools for Specific Purposes" },
      { id: "secondary", name: "Secondary Schools" },
    ],
    sipterms: [
      { id: "21st.century.learning", name: "21st Century learning" },
      {
        id: "aboriginal.and.torres.strait.islander.peoples",
        name: "Aboriginal and Torres Strait Islander peoples",
      },
      { id: "acara", name: "ACARA" },
      { id: "accreditation", name: "Accreditation" },
      { id: "anti-bullying", name: "Anti-bullying" },
      { id: "assessment", name: "Assessment" },
      { id: "assessment.for.of.and.as", name: "Assessment for, of and as" },
      { id: "attendance", name: "Attendance" },
      {
        id: "attendance.and.engagement.dashboard",
        name: "Attendance and engagement dashboard",
      },
      { id: "attendance.data", name: "Attendance data" },
      { id: "attendance.flyer", name: "Attendance flyer" },
      { id: "attendance.level", name: "Attendance level" },
      { id: "attendance.matters", name: "Attendance matters" },
      { id: "attendance.policy", name: "Attendance policy" },
      { id: "attendance.process.map", name: "Attendance process map" },
      { id: "attendance.rate", name: "Attendance rate" },
      { id: "attendance.target", name: "Attendance target" },
      {
        id: "australian.early.development.census",
        name: "Australian Early Development Census",
      },
      { id: "behaviour.specialists", name: "Behaviour specialists" },
      {
        id: "behaviour.support.and.management",
        name: "Behaviour support and management",
      },
      { id: "behavioural.insights", name: "Behavioural insights" },
      { id: "building.upgrade", name: "Building upgrade" },
      { id: "bump.it.up", name: "Bump It Up" },
      { id: "cese", name: "CESE" },
      { id: "child.protection.education", name: "Child protection education" },
      { id: "child.wellbeing.unit", name: "Child Wellbeing Unit" },
      { id: "climate.change", name: "Climate Change" },
      { id: "coaching", name: "Coaching" },
      { id: "collaborative.practice", name: "Collaborative practice" },
      { id: "community.of.practice", name: "Community of practice" },
      { id: "complex.school.environment", name: "Complex school environment" },
      { id: "complexity", name: "Complexity" },
      { id: "consent.education", name: "Consent education" },
      { id: "consistency.of.judgement", name: "Consistency of judgement" },
      { id: "continuum.of.care", name: "Continuum of care" },
      { id: "co-teaching", name: "co-teaching" },
      { id: "covid", name: "COVID" },
      {
        id: "curiosity.and.powerful.learning",
        name: "Curiosity and powerful learning",
      },
      { id: "curriculum", name: "Curriculum" },
      { id: "curriculum.reform", name: "Curriculum reform" },
      { id: "data.use", name: "Data use" },
      { id: "differentiation", name: "Differentiation" },
      {
        id: "disability.and.inclusive.education",
        name: "Disability and inclusive education",
      },
      { id: "discrimination", name: "Discrimination" },
      { id: "eal.d", name: "EAL/D" },
      { id: "ebs", name: "EBS" },
      { id: "education.research", name: "Education research" },
      { id: "effect.size", name: "Effect size" },
      {
        id: "effective.classroom.practice",
        name: "Effective classroom practice",
      },
      { id: "english", name: "English" },
      { id: "equity", name: "Equity" },
      { id: "evaluation", name: "Evaluation" },
      { id: "every.day.counts", name: "Every day counts" },
      { id: "explicit.teaching", name: "Explicit teaching" },
      { id: "extra-curricular", name: "Extra-curricular" },
      { id: "facilities", name: "Facilities" },
      { id: "feedback", name: "Feedback" },
      { id: "formative.assessment", name: "Formative assessment" },
      { id: "gifted.and.talented", name: "Gifted and talented" },
      { id: "growth.mindset", name: "Growth mindset" },
      { id: "gtil", name: "GTIL" },
      { id: "high.expectations", name: "High expectations" },
      { id: "homework", name: "Homework" },
      { id: "hsc", name: "HSC" },
      { id: "hsc.minimum.standard", name: "HSC minimum standard" },
      { id: "improved.student.learning", name: "Improved student learning" },
      { id: "incident.support.unit", name: "Incident support unit" },
      {
        id: "individualised.learning.plans",
        name: "Individualised learning plans",
      },
      {
        id: "influencing.attendance.showcase",
        name: "Influencing attendance showcase",
      },
      {
        id: "innovative.learning.environment",
        name: "Innovative Learning Environment",
      },
      { id: "instructional.leadership", name: "Instructional leadership" },
      { id: "learning.culture", name: "Learning culture" },
      { id: "learning.modes", name: "Learning Modes" },
      { id: "learning.progressions", name: "Learning progressions" },
      { id: "learning.space", name: "Learning Space" },
      { id: "leed", name: "LEED" },
      { id: "lesson.planning", name: "Lesson planning" },
      { id: "literacy", name: "Literacy" },
      {
        id: "management.practices.and.processes",
        name: "Management practices and processes",
      },
      { id: "maths", name: "Maths" },
      { id: "minutes.matter", name: "Minutes matter" },
      { id: "mypl", name: "MyPL" },
      { id: "naplan", name: "NAPLAN" },
      { id: "natural.disaster", name: "Natural disaster" },
      { id: "nesa", name: "NESA" },
      { id: "numeracy", name: "Numeracy" },
      { id: "operational.reports", name: "Operational reports" },
      { id: "out.of.home.care", name: "Out of Home Care" },
      {
        id: "parent.and.community.engagement",
        name: "Parent and community engagement",
      },
      {
        id: "performance.and.development.plan",
        name: "Performance and development plan",
      },
      {
        id: "positive.behaviour.for.learning",
        name: "Positive behaviour for learning",
      },
      { id: "post-construction.review", name: "Post-construction review" },
      { id: "post-occupancy.evaluation", name: "Post-occupancy evaluation" },
      { id: "professional.learning", name: "Professional learning" },
      { id: "professional.standards", name: "Professional standards" },
      {
        id: "quality.teaching.and.learning",
        name: "Quality teaching and learning",
      },
      { id: "quality.teaching.round", name: "Quality teaching round" },
      { id: "reading", name: "Reading" },
      { id: "release", name: "Release" },
      { id: "reporting", name: "Reporting" },
      { id: "school.complexity.band", name: "School complexity band" },
      { id: "school.complexity.factors", name: "School Complexity Factors" },
      { id: "school.excellence.cycle", name: "School excellence cycle" },
      {
        id: "school.excellence.framework",
        name: "School excellence framework",
      },
      { id: "school.operations", name: "School operations" },
      { id: "school.planning", name: "School planning" },
      { id: "school.resources", name: "School resources" },
      { id: "scope.and.sequence", name: "Scope and sequence" },
      { id: "scout", name: "SCOUT" },
      {
        id: "self.regulation.and.metacognition",
        name: "Self regulation and metacognition",
      },
      { id: "strategic.school.support", name: "Strategic School Support" },
      { id: "student.agency", name: "student agency" },
      { id: "student.engagement", name: "Student engagement" },
      {
        id: "student.performance.measures",
        name: "Student performance measures",
      },
      { id: "student.physical.health", name: "Student physical health" },
      { id: "summative.assessment", name: "Summative assessment" },
      { id: "sustainable.environment", name: "Sustainable environment" },
      {
        id: "teaching.and.learning.cycle",
        name: "Teaching and learning cycle",
      },
      {
        id: "teaching.and.learning.programs",
        name: "Teaching and learning programs",
      },
      { id: "technology", name: "Technology" },
      { id: "transition", name: "Transition" },
      { id: "ttfm", name: "TTFM" },
      { id: "university", name: "University" },
      { id: "valid", name: "VALID" },
      { id: "value.add", name: "Value add" },
      { id: "wellbeing", name: "Wellbeing" },
      { id: "what.works.best", name: "What Works Best" },
      { id: "writing", name: "Writing" },
    ],
    application: [
      { id: "olp", name: "NSW SLL" },
      { id: "urh", name: "URH" },
    ],
    mycollectionlibraries: [
      { id: "createdbyme", name: "Created by me" },
      { id: "sharedwithme", name: "Shared with me" },
      { id: "sharedbyme", name: "Shared by me" },
    ],
    scope: [
      { id: 0, value: "Internal" },
      { id: 2, value: "Public" },
    ],
  },
};
