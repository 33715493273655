import axios from "axios";

import { mock_request_adapter } from "./adapters/mock_request_adapter";
import { error_handling_interceptor } from "./interceptors/error_interceptor";

/**
 * A predicate to determine whether mock data should be
 * given instead of doing real API calls.
 */
const USE_MOCK_DATA =
  process.env.NODE_ENV === "development" &&
  String(process.env.VUE_APP_MOCK_API).toLowerCase() === "true";

/**
 * Base API URL that is used by all internal API calls.
 */
const BASE_API_URL = process.env.VUE_APP_ROOT_API;

/**
 * Default API Axios instance that is used by all API calls.
 *
 * In case we are in a development environment and we want to use mock data,
 * an adapter is injected into the instance to handle mock data.
 *
 * This instance is also configured with interceptors to handle
 * extra functionality such as error handling.
 */
const default_api_instance = axios.create({
  baseURL: BASE_API_URL,
  adapter: USE_MOCK_DATA ? mock_request_adapter : undefined,
});

// Inject interceptors for extra functionality such as error handling.
error_handling_interceptor(default_api_instance);

export default default_api_instance;
