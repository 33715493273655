import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Classroom from "@/components/Classrooms/Classrooms.vue";
import SearchAction from "@/components/Classrooms/SearchAction.vue";

import Home from "@/views/Home/index.vue";
import { HomeArchiveProps, HomeProps } from "@/router/props";
import CreateFullscreen from "@/components/CreateClass/CreateFullscreen.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    alias: ["/index.html", "/home"],
    component: Home,
    children: [
      {
        path: "/",
        name: "home",
        components: {
          default: Classroom,
          search: SearchAction,
        },
        props: HomeProps,
      },
      {
        path: "archive",
        name: "archive",
        components: {
          default: Classroom,
          search: SearchAction,
        },
        props: HomeArchiveProps,
      },
    ],
  },
  {
    path: "/posts",
    name: "posts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Posts/index.vue"),
  },
  {
    path: "/create",
    name: "create",
    component: CreateFullscreen,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

export default router;
