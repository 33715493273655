<template>
  <div class="add-image-dialog__content">
    <v-row
      class="ma-0"
      align="center"
    >
      <v-col
        cols="12"
        class="d-flex flex-row justify-space-around px-0"
      >
        <AdsTextField
          :class="{
            'mr-6': $vuetify.breakpoint.smAndUp,
            'mr-3': $vuetify.breakpoint.xsOnly,
          }"
          prepend-inner-icon="search"
          label="Search Unsplash.com"
          v-model="unsplashQuery"
          hide-details
          @keyup.enter="searchUnsplash"
          class="grow"
        />
        <AdsButton
          :loading="loading"
          class="search-button"
          :buttonText="$vuetify.breakpoint.xsOnly ? '' : 'Search'"
          icon="search"
          :iconOutlined="true"
          secondary
          @click="searchUnsplash"
        />
      </v-col>
    </v-row>
    <v-card-actions class="pa-0">
      <v-row
        v-if="$store.getters['unsplash/photos'].length > 0"
        class="images__container"
        ref="images-container"
        v-infinite-scroll="loadMoreUnsplashPic"
        :infinite-scroll-disabled="infiniteScrollBusy"
        infinite-scroll-distance="20"
        style="max-height: 30vh"
      >
        <v-col
          class="pa-2"
          :cols="colSize.xs"
          :lg="colSize.lg"
          :md="colSize.md"
          :sm="colSize.sm"
          v-for="(photo, index) in $store.getters['unsplash/photos']"
          :key="'photo_' + index"
        >
          <v-btn
            v-if="!loading"
            :ripple="false"
            class="image__wrapper"
            @click="selectImage(photo)"
            @keyup.enter="selectImage(photo)"
            :id="`${photo.id}_${index}`"
            :aria-label="`${photo.alt_description} - ${
              selectedPhoto && selectedPhoto.id === photo.id
                ? 'selected'
                : 'not selected'
            }`"
          >
            <v-img
              :id="`${photo.id}_${index}`"
              :aspect-ratio="1.85"
              :src="photo.urls.small"
              width="100%"
            >
              <v-icon
                class="selected-photo--icon"
                v-if="selectedPhoto && selectedPhoto.id === photo.id"
              >
                check
              </v-icon>
            </v-img>
          </v-btn>
          <v-responsive
            v-else
            :aspect-ratio="1.85"
            :max-width="600"
            :max-height="600"
          >
            <v-skeleton-loader type="image" />
          </v-responsive>
        </v-col>
      </v-row>
      <span v-else>No results.</span>
    </v-card-actions>
  </div>
</template>

<script>
import { AdsTextField, AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: "UnsplashDialogContent",
  components: {
    AdsTextField,
    AdsButton,
  },
  data() {
    return {
      loading: false,
      infiniteScrollBusy: false,
      onTopScrollsToBottom: false,
      photosPerPage: [6, 20],
      perPage: 20,
      unsplashQuery: "",
      selectedPhoto: "",
      colSize: {
        lg: "4",
        md: "4",
        sm: "6",
        xs: "12",
      },
    };
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$store.commit("unsplash/SET_PER_PAGE", val);
          switch (val) {
            case 6:
              this.colSize = {
                lg: "4",
                md: "4",
                sm: "6",
                xs: "12",
              };
              break;
            case 20:
              this.colSize = {
                lg: "3",
                md: "3",
                sm: "6",
                xs: "12",
              };
              break;
          }
          this.$store.dispatch("unsplash/getPhotos").then((result) => {
            if (result.type === "success") {
              this.$store.commit(
                "unsplash/SET_PHOTOS",
                result.response.results
              );
              this.$store.commit("unsplash/SET_TOTAL", result.response.total);
            }
          });
        }
      },
    },
  },
  methods: {
    async searchUnsplash() {
      if (this.unsplashQuery !== "") {
        this.loading = true;
        const result = await this.$store.dispatch(
          "unsplash/getPhotos",
          this.unsplashQuery
        );
        if (result.type === "success") {
          this.$store.commit("unsplash/SET_PHOTOS", result.response.results);
          this.resetScroll();
        }
        this.loading = false;
      }
    },
    selectImage(photo) {
      this.selectedPhoto = photo;
      this.$listeners.input({ type: "unsplash", url: photo.urls.full });
    },
    async loadMoreUnsplashPic() {
      /**
       * Inorder for infinite scroll to trigger, we must set infiniteScrollBusy to false
       */
      this.loading = true;
      this.infiniteScrollBusy = true;
      // Set user scroll behaviour
      this.$store.commit(
        "unsplash/SET_PAGE",
        this.$store.state.unsplash.page + 1
      );
      const result = await this.$store.dispatch(
        "unsplash/getPhotos",
        this.unsplashQuery
      );
      if (result.type === "success") {
        this.$store.commit("unsplash/SET_PHOTOS", result.response.results);
        this.resetScroll();
      }
      this.loading = false;
      this.infiniteScrollBusy = false;
    },
    async getPreviousPhotos() {
      this.infiniteScrollBusy = true;
      this.$store.commit(
        "unsplash/SET_PAGE",
        this.$store.state.unsplash.page - 1
      );
      const result = await this.$store.dispatch(
        "unsplash/getPhotos",
        this.unsplashQuery
      );
      if (result.type === "success") {
        this.$store.commit("unsplash/SET_PHOTOS", result.response.results);
        this.resetScroll();
      }
      this.infiniteScrollBusy = false;
    },
    resetScroll() {
      const scrollableDiv = this.$refs["images-container"];
      if (scrollableDiv) {
        scrollableDiv.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";
.search-button {
  height: 56px !important;
}
.add-image-dialog__content {
  padding: 0 40px 24px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 500px;
  max-height: 500px;
  min-height: 500px;
  overflow-y: hidden;

  .heading {
    color: $ads-dark;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 8px 0;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .sub-title {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .images__container {
    margin: 0 -8px;
    padding: 0 10px 0 0;
    overflow-y: scroll;
    max-height: 500px;

    /* Works on Firefox */
    & {
      scrollbar-width: thin !important;
      scrollbar-color: $ads-light-40 !important;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $ads-white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $ads-light-40;
      border-radius: 20px;
    }

    .image__wrapper {
      background: transparent;
      width: 100%;
      height: fit-content;
      padding: 0;

      &:focus {
        outline: none !important;
      }

      &:focus-visible {
        outline: 3px solid $ads-navy !important;
      }

      .v-image {
        border-radius: 4px;

        .v-responsive__content {
          position: relative;

          .selected-photo--icon {
            position: absolute;
            bottom: 6px;
            right: 6px;
            padding: 6px;
            background-color: $ads-white;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .images__footer {
    margin-bottom: 12px;
    max-height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .pagination-count {
      span {
        width: 140px;
        margin-right: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .per-page__label {
      padding-right: 12px;
    }

    .v-select {
      max-width: 50px;
      padding-top: 0;
      padding-bottom: 4px;
    }

    ::v-deep.v-text-field > {
      .v-input__control > {
        .v-input__slot {
          &:before {
            border-color: transparent;
          }
        }
      }
    }

    .v-input {
      input {
        max-height: 20px;
      }
    }
  }
}

.v-skeleton-loader {
  height: 100%;
}

@media only screen and (max-width: 700px) {
  .add-image-dialog__content {
    padding: 0 12px;

    .images__footer {
      font-size: 12px;

      .per-page__label {
        padding-top: 4px;
      }

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .add-image-dialog__content {
    //height: 100% !important;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    position: relative;

    .heading {
      padding: 0 0 12px 0;

      .title {
        font-size: 20px;
      }

      .sub-title {
        font-size: 14px;
      }
    }

    .images__container {
      max-height: calc(100vh - 257px);
      padding-bottom: 130px;
    }

    .actions {
      background-color: $ads-white;
      width: 100%;
      padding: 12px;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .images__footer {
      width: 100%;
      font-size: 14px;

      .pagination-count {
        span {
          width: 100px;
        }
      }

      .v-select {
        font-size: 14px;
      }
    }

    .search-button {
      min-width: 40px !important;
      max-width: 40px !important;
      height: 56px !important;

      ::v-deep.v-btn__content {
        .buttonText {
          display: none !important;
        }
      }
    }
  }
}
</style>
