
import { AdsButton, Alert, HStepper } from "@nswdoe/doe-ui-core";
import Vue from "vue";
import { mapGetters } from "vuex";

import Step1 from "./Steps/Step1/Step1.vue";
import Step2 from "./Steps/Step2/Step2.vue";
import Step3 from "./Steps/Step3/Step3.vue";
import Step4 from "./Steps/Step4/Step4.vue";

import {
  Computed,
  Data,
  Methods,
  Props,
  ModalStepTypes,
  ModalStepStatus,
} from "./CreateClass.types";
import {
  Classroom,
  ClassroomStatus,
  ClassroomType,
} from "@/types/Classroom.types";

import { appSteps, initialClassModal } from "./CreateClass.constants";

export default Vue.extend<Data, Methods, Computed, Props>({
  name: "CreateClass",
  created() {
    this.classModal = {
      ...initialClassModal,
      classType: this.classType as ClassroomType,
    };
  },
  data: () => ({
    validatedStep: false,
    currentStep: 1,
    classModal: initialClassModal,
    appSteps,

    // A workaround to get enumeration values into the template
    ModalStepTypes: ModalStepTypes,
    ClassroomType: ClassroomType,
  }),
  computed: {
    ...mapGetters({
      classHelpers: "schoolsLookup/classHelpers",
    }),
    publishText() {
      switch (this.classType) {
        case ClassroomType.GOOGLE:
          return "Publish to Google Classroom";
        case ClassroomType.MICROSOFT:
          return "Publish to Microsoft Teams";
        default:
          return "Publish";
      }
    },
    activeStep() {
      return this.appSteps[this.currentStep - 1];
    },
    rollClass() {
      if (this.classHelpers && this.classHelpers.classRoll) {
        let rollClasses = this.classHelpers.classRoll;
        if (Array.isArray(rollClasses) && rollClasses.length > 0) {
          let findRoll = rollClasses.filter(
            (rollClassInfo) =>
              rollClassInfo.year == this.classModal.classYearLevel
          );
          if (Array.isArray(findRoll) && findRoll.length > 0) {
            return findRoll[0].roll;
          }
        }
      }
      return [];
    },
  },
  components: {
    HStepper,
    AdsButton,
    Alert,
    Step1,
    Step2,
    Step3,
    Step4,
  },
  methods: {
    setValidStep(validated) {
      this.activeStep.isValid = validated;
    },
    async saveClass() {
      try {
        if (!!this.classModal.classId) {
          // classId implies that the class already exists, therefore we must update
          await this.$store.dispatch("classCards/updateClass", this.classModal);
        } else {
          // Else, create a new class
          const classroom = (await this.$store.dispatch(
            "classCards/createClass",
            this.classModal
          )) as Awaited<Classroom>;

          this.classModal.classId = classroom.classId;
          this.classModal.createdBy = classroom.createdBy;
        }
      } catch (error) {
        this.$store.dispatch("alerts/addAlert", {
          type: "error",
          text: "Failed to save",
          subtext: "The class failed to be saved, please try again.",
        });
      }
    },
    async publishClass() {
      this.classModal.classStatus = ClassroomStatus.PUBLISH;

      try {
        // classId implies that the class already exists, therefore we must update
        await this.$store.dispatch("classCards/publishClass", this.classModal);
        this.$store.dispatch("alerts/addAlert", {
          type: "success",
          text: "Class published",
          subtext: "The class has been successfully published.",
        });
      } catch (error) {
        this.$store.dispatch("alerts/addAlert", {
          type: "error",
          text: "Failed to publish",
          subtext: "The class failed to be published, please try again.",
        });
      }
    },
    changeStep(stepId) {
      if (
        this.currentStep == 1 &&
        this.classModal.classYearLevel.length > 0 &&
        this.classModal.className != "" &&
        !this.classModal.klas.length &&
        this.classModal.classId != "" &&
        this.classModal.topics.length > 0
      ) {
        Vue.set(this.appSteps, this.currentStep - 1, {
          ...this.appSteps[this.currentStep - 1],
          status: ModalStepStatus.COMPLETE,
        });
      } else {
        Vue.set(this.appSteps, this.currentStep - 1, {
          ...this.appSteps[this.currentStep - 1],
          status: ModalStepStatus.INCOMPLETE,
        });
      }

      this.currentStep = stepId;
    },
    updateClassModal(classModalValue) {
      this.classModal = classModalValue;
    },
    nextStep() {
      if (this.currentStep < this.appSteps.length) {
        this.saveClass();
        this.changeStep(this.currentStep + 1);
      }
    },
    previousStep() {
      if (this.currentStep > 1) this.changeStep(this.currentStep - 1);
    },
  },
  props: {
    classType: {
      type: String,
      default: "",
    },
  },
});
