import Vue, { PropType } from "vue";

import api from "@/api";
import { Classroom, ClassroomStatus } from "@/types/Classroom.types";
import { isArray } from "@/utils/general";

export default {
  namespaced: true,
  state: {
    allClasses: [] as PropType<Classroom>,
  },
  getters: {
    allClasses: (state: { allClasses: Classroom[] }) => state.allClasses,
  },
  mutations: {
    /**
     * Use this method to add a class to the allClasses array
     */
    ADD_CLASS(state: { allClasses: Classroom[] }, classroom: Classroom) {
      state.allClasses.push(classroom);
    },
    /**
     * Use this method to completely replace the allClasses with a new array
     */
    SET_CLASSES(state: { allClasses: Classroom[] }, classes: Classroom[]) {
      state.allClasses = classes;
    },
    /**
     * Use this method to update a specific class in the allClasses array
     * As a fallback, if the class is not found, it will be added to the array
     */
    UPDATE_CLASS(state: { allClasses: Classroom[] }, classroomToUpdate) {
      const itemIndex = state.allClasses.findIndex(
        (classInfo) => classInfo.classId == classroomToUpdate.classId
      );
      if (itemIndex != -1) {
        Vue.set(state.allClasses, itemIndex, classroomToUpdate);
      } else {
        state.allClasses.push(classroomToUpdate);
      }
    },
    REMOVE_CLASS(state: { allClasses: Classroom[] }, { classId }: Classroom) {
      const itemIndex = state.allClasses.findIndex(
        (classroom) => classroom.classId == classId
      );
      if (itemIndex != -1) {
        Vue.delete(state.allClasses, itemIndex);
      }
    },
  },
  actions: {
    fetchClasses({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }
      api.classApi
        .fetchClasses()
        .then((response) => {
          if (response) {
            if (response.data.classes && isArray(response.data.classes)) {
              commit("SET_CLASSES", response.data.classes);
            }
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    async createClass({ commit, rootState }, classroom) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      const createdClassroom = await api.classApi
        .saveClass({ data: classroom })
        .then((response) => {
          if (response && response.data && response.data.class) {
            commit("ADD_CLASS", response.data.class);
            return response.data.class;
          } else throw new Error("No class returned from API");
        })
        .catch((error: Error) => {
          throw error;
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });

      return createdClassroom;
    },
    async updateClass({ commit, rootState }, classroom) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      const updatedClassroom = await api.classApi
        .updateClass({ data: classroom })
        .then((response) => {
          console.log("Response from updateClass", response);
          if (response && response.data && response.data.class) {
            commit("UPDATE_CLASS", response.data.class);
            return response.data.class;
          } else throw new Error("No class returned from API");
        })
        .catch((error: Error) => {
          throw error;
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });

      return updatedClassroom;
    },
    async publishClass({ commit }, classroom: Classroom) {
      commit("SET_IS_LOADING", true, { root: true });

      const publishedClass = await api.classApi
        .publishClass({ data: classroom })
        .then((response) => {
          if (response && response.data && response.data.classDetails) {
            commit("UPDATE_CLASS", response.data.classDetails);
            return response.data.classDetails;
          } else throw new Error("No class returned from API");
        })
        .catch((error: Error) => {
          throw error;
        })
        .finally(() => {
          commit("SET_IS_LOADING", false, { root: true });
        });

      return publishedClass;
    },
    async archiveClass({ commit, rootState }, classroom: Classroom) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      const archivedClassroom = {
        ...classroom,
        classStatus: ClassroomStatus.ARCHIVE,
      };

      return await api.classApi
        .archiveClass(archivedClassroom)
        .then((response) => {
          if (response) {
            commit("UPDATE_CLASS", archivedClassroom);
          }
        })
        .catch((error: Error) => {
          console.error(error);
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    async deleteClass({ commit, rootState }, classroom: Classroom) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      if (classroom.classStatus !== ClassroomStatus.ARCHIVE) {
        throw new Error(
          `Cannot delete class in status ${classroom.classStatus}`
        );
      }

      return await api.classApi
        .deleteClass(classroom)
        .then((response) => {
          console.log("response", response);
          if (response) {
            commit("REMOVE_CLASS", classroom);
          }
        })
        .catch((error: Error) => {
          throw error;
        })
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
  },
};
