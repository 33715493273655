export default {
  message: "Schools retrieved succesfully",
  schools: [
    {
      schoolCode: "1001",
      schoolSpecialityTypeName: "Not Specialist",
      schoolGenderCompositionCode: "Co-ed",
      schoolPrincipalNetworkName: "Iron Cove",
      schoolAddressLine1:
        "Abbotsford Public School, 350 Great N Rd, Abbotsford NSW 2046, Australia",
      schoolSuburbName: "Abbotsford",
      schoolPostalCode: "2046",
      schoolPhoneNumber: "9713 6220",
      schoolEmailAddress: "abbotsford-p.school@det.nsw.edu.au",
      schoolLocalGovernmentAreaName: "CANADA BAY",
      schoolStateElectorateName: "DRUMMOYNE",
      schoolFederalElectorateName: "Reid",
      schoolFullName: "Abbotsford Public School",
      schoolShortName: "Abbotsford",
      schoolLevelTypeName: "Primary Schools",
      schoolLevelSubtypeCode: "6",
      schoolSelectiveTypeName: "N",
      schoolPreschoolIndicator: "N",
      schoolOpportunityClassIndicator: "N",
      schoolDistanceEducationCode: "No",
      schoolIntensiveEnglishCenterIndicator: "N",
      schoolLateOpeningIndicator: "No",
      schoolOperationalDirectorateName: "Metropolitan South",
      schoolAgeIdentifier: "44402",
      schoolAddressLine2: "NSW",
      schoolLastYearEnrolmentNumber: "2020",
      schoolIndexCommunitySocioEducationalAdvantageValue: 1108,
      schoolAustStatisticalGeographyStandardRemotenessArea:
        "Major Cities of Australia",
      schoolStatisticalAreaLevel4Name: "Sydney - Inner West",
      schoolYearArray: [
        "KG",
        "Year 1",
        "Year 2",
        "Year 3",
        "Year 4",
        "Year 5",
        "Year 6",
      ],
    },
    {
      schoolCode: "1002",
      schoolSpecialityTypeName: "Not Specialist",
      schoolGenderCompositionCode: "Co-ed",
      schoolPrincipalNetworkName: "Upper Hunter",
      schoolAddressLine1: "Segenhoe St, Aberdeen NSW 2336, Australia",
      schoolSuburbName: "Aberdeen",
      schoolPostalCode: "2336",
      schoolPhoneNumber: "6543 7271",
      schoolEmailAddress: "aberdeen-p.school@det.nsw.edu.au",
      schoolLocalGovernmentAreaName: "UPPER HUNTER",
      schoolStateElectorateName: "UPPER HUNTER",
      schoolFederalElectorateName: "New England",
      schoolFullName: "Aberdeen Public School",
      schoolShortName: "Aberdeen",
      schoolLevelTypeName: "Primary Schools",
      schoolLevelSubtypeCode: "6",
      schoolSelectiveTypeName: "N",
      schoolPreschoolIndicator: "N",
      schoolOpportunityClassIndicator: "N",
      schoolDistanceEducationCode: "No",
      schoolIntensiveEnglishCenterIndicator: "N",
      schoolLateOpeningIndicator: "No",
      schoolOperationalDirectorateName: "Regional North and West",
      schoolAgeIdentifier: "49333",
      schoolAddressLine2: "NSW",
      schoolLastYearEnrolmentNumber: "2020",
      schoolIndexCommunitySocioEducationalAdvantageValue: 907,
      schoolAustStatisticalGeographyStandardRemotenessArea:
        "Inner Regional Australia",
      schoolStatisticalAreaLevel4Name: "Hunter Valley exc Newcastle",
      schoolYearArray: [
        "KG",
        "Year 1",
        "Year 2",
        "Year 3",
        "Year 4",
        "Year 5",
        "Year 6",
        "Year 7",
        "Year 8",
        "Year 9",
        "Year 10",
      ],
    },
  ],
};
