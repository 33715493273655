import Vue from "vue";

/**
 * VueX module for user data.
 */
export default {
  namespaced: true,
  getters: {
    profile: () => Vue.prototype.$OAuth.profile ?? {},
    /**
     * A getter method to determine if the received user attributes have admin access
     */
    hasAdminAccess: () => {
      const profile = Vue.prototype.$OAuth.profile;

      if (!profile || !profile.det_roles) return false;

      const specialRoles = [
        "SUBEXECUTIVE",
        "ASSISTANTPRINCIPAL",
        "PRINCIPAL.DEPUTY.HS",
        "PRINCIPAL.DEPUTY.PS",
        "PRINCIPAL",
        "HEADTEACHER",
      ];

      // Forcing possible string value into array type
      const roles = Array.isArray(profile.det_roles)
        ? profile.det_roles
        : [profile.det_roles];

      // Evaluates to true if any roles includes one of the above specialRoles
      const hasSpecialRole = roles.some((role) => specialRoles.includes(role));

      // Evaluates to true if any roles are equal to teacher role
      const isTeacher = roles.includes("SCHOOL.TEACHER");

      return isTeacher || hasSpecialRole;
    },
  },
};
