
import { Classroom } from "@/types/Classroom.types";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { autoDedupStudent } from "../Step2/People.util";
import { StudentStatus } from "@/types/ClassPeople.types";

export default Vue.extend({
  name: "Step4",
  mounted() {
    // The calculation needs to happen at mount() because the element is not
    // rendered until before this point.
    this.calculateStudentsToDisplay();
  },
  computed: {
    classModal() {
      return this.$props.classInfo as Classroom;
    },
  },
  methods: {
    /**
     * Given an HTML element ID, this function calculates the maximum number of characters
     * that can be displayed within that element's width.
     * @param id - The ID of the HTML element
     */
    calculateCharactersByID(id: string) {
      // Get the element with ID received as a parameter
      const truncateElement = document.getElementById(id);

      if (!truncateElement) return 0;

      /**
       * This is an approximate width in pixels per character only.
       * We don't need it to be exact, we will always round down.
       */
      const APPROX_FONT_WIDTH = 8;

      // Calculate the maximum number of characters that can be displayed
      return Math.floor(truncateElement.offsetWidth / APPROX_FONT_WIDTH);
    },
    /**
     * This function calculates the students that can be displayed and the students that need to be hidden,
     * based on the screen size rendered at the time this function is called.
     */
    calculateStudentsToDisplay() {
      const elementId = "students__truncate";
      const maxCharacters = this.calculateCharactersByID(elementId);

      let usedCharacters = 0;

      const { autoStudents, deltaStudents } = this.classModal.classPeople;

      const dedupedStudents = [
        // Remove students from autoStudents if they are in deltaStudents
        ...autoStudents.filter(autoDedupStudent(deltaStudents)),
        ...deltaStudents,
      ].filter(({ status }) => status !== StudentStatus.REMOVED);

      const { display, hide } = dedupedStudents.reduce(
        (previous, current) => {
          // The calculation should account that we need to add a comma and space for each item
          const fullLength = (current + ", ").length;

          const name = current.givenName + " " + current.familyName;

          if (fullLength + usedCharacters <= maxCharacters) {
            previous.display.push(name);
            usedCharacters += fullLength;
          } else {
            previous.hide.push(name);
          }

          return previous;
        },
        { display: [], hide: [] } as { display: string[]; hide: string[] }
      );

      this.students.display = display;
      this.students.hide = hide;
    },
  },
  data: () => ({
    // This value is calculated in the mounted() hook
    students: { display: [] as string[], hide: [] as string[] },
  }),
  props: {
    classInfo: {
      type: Object,
      default: () => ({}),
    } as unknown as PropValidator<Classroom>,
  },
});
