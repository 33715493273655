
import { Classroom, ClassroomStatus } from "@/types/Classroom.types";
import { ClassroomAction } from "@/types/ClassroomActions.types";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import { mapGetters } from "vuex";
import { Dialog } from "@nswdoe/doe-ui-core";
import { ClassPeopleStudent, StudentStatus } from "@/types/ClassPeople.types";
import { autoDedupStudent } from "../CreateClass/Steps/Step2/People.util";

interface Props {
  classroom: Classroom;
}

interface Methods {
  performAction(action: ClassroomAction): void;
  hideMenu(): void;
  handleDialogReturn(result: DeletionAction["name"]): void;
  confirmDeletion(): void;
}

interface DeletionAction {
  name: "Ok" | "Cancel";
  color: string;
  size: string;
  outlined: boolean;
}

interface Data {
  checkItem: boolean;
  timetablesync: boolean;
  showMenu: boolean;
  classStatus: typeof ClassroomStatus;
  deletionActions: DeletionAction[];
  deletionDialogOpen: boolean;
  deletionMessage: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default Vue.extend<Data, Methods, any, Props>({
  name: "ClassProfile",
  components: { Dialog },
  data: () => ({
    // Fallback image in case class picture is broken or not set.
    defaultPicture: "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
    classroomPicture: "https://cdn.vuetifyjs.com/images/cards/docks.jpg",
    checkItem: false,
    timetablesync: false,
    classStatus: ClassroomStatus,
    showMenu: false,
    deletionDialogOpen: false,
    deletionActions: [
      { name: "Cancel", color: "#041E42", size: "large", outlined: true },
      { name: "Ok", color: "#041E42", size: "large", outlined: false },
    ],
    deletionMessage: "Are you sure?",
  }),
  created() {
    this.classroomPicture = this.classroom.classPicture?.url
      ? this.classroom.classPicture?.url
      : this.defaultPicture;

    // If classroom has a live Google/Teams classroom attached to it,
    // deletion message needs to reflect that.
    if (this.classroom.classRoomOrTeamsId) {
      if (this.classroom.classRoomOrTeamsId.includes("GOOGLECLASSROOM")) {
        this.deletionMessage =
          "This will also delete the Google classroom. Are you sure?";
      } else {
        this.deletionMessage =
          "This will also delete the Microsoft Team. Are you sure?";
      }
    }
  },
  watch: {
    checkItem(value) {
      this.$upwardBroadcast("classrooms:check", {
        classId: this.classroom.classId,
        value,
      });
    },
  },
  methods: {
    confirmDeletion() {
      this.deletionDialogOpen = true;
    },
    handleDialogReturn(result) {
      this.deletionDialogOpen = false;

      if (result === "Ok") {
        this.$upwardBroadcast("classrooms:delete", [this.classroom]);
      }
    },
    performAction(action: ClassroomAction) {
      this.hideMenu();
      switch (action) {
        case ClassroomAction.ARCHIVE:
          this.$upwardBroadcast("classrooms:archive", [this.classroom]);
          break;
        case ClassroomAction.DELETE:
          this.confirmDeletion();
          break;
        default:
          console.log("Received action %s", action.toLowerCase());
          break;
      }
    },
    hideMenu() {
      this.showMenu = false;
    },
  },
  props: {
    classroom: {
      type: Object,
      default: () => ({}),
    } as unknown as PropValidator<Classroom>,
  },
  computed: {
    ...mapGetters({
      hasAdminAccess: "userAttributes/hasAdminAccess",
    }),
    details(): string {
      return [this.classroom.classYearLevel, ...this.classroom.klas]
        .filter((value) => !!value)
        .join(", ");
    },
    /**
     * A computed property merging autoStudents and deltaStudents,
     * whilst also dedupping autoStudents.
     */
    totalStudents(): ClassPeopleStudent[] {
      const { autoStudents, deltaStudents } = this.classroom.classPeople;
      return [
        // Remove students from autoStudents if they are in deltaStudents
        ...autoStudents.filter(autoDedupStudent(deltaStudents)),
        ...deltaStudents,
      ].filter(({ status }) => status !== StudentStatus.REMOVED);
    },
    items(): Array<{ action: ClassroomAction; title: string }> {
      const createTitle =
        this.classroom.classType === "Microsoft Teams"
          ? "Go to Microsoft Teams Classroom"
          : "Go to Google Classroom";

      const standardItems = [
        { action: ClassroomAction.CREATE, title: createTitle },
        { action: ClassroomAction.SHARE, title: "Share" },
        { action: ClassroomAction.EDIT, title: "Edit" },
        { action: ClassroomAction.DUPLICATE, title: "Duplicate" },
        { action: ClassroomAction.ARCHIVE, title: "Archive" },
      ];

      const archiveItems = [
        { action: ClassroomAction.DUPLICATE, title: "Duplicate" },
        { action: ClassroomAction.DELETE, title: "Delete" },
      ];

      const restrictedItems = [
        { action: ClassroomAction.CREATE, title: createTitle },
        { action: ClassroomAction.SHARE, title: "Share" },
      ];

      const adminItems =
        this.classroom.classStatus === ClassroomStatus.ARCHIVE
          ? archiveItems
          : standardItems;

      return this.hasAdminAccess ? adminItems : restrictedItems;
    },
  },
});
