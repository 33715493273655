
import Vue from "vue";
import { isArray, isPopulatedArray } from "@/utils/general";
import DrhResource from "./DRHCards.vue";

import {
  ResourceDocument,
  DrhFilterModel,
  DrhFilterApi,
  ResourceCategory,
} from "../../../types/DrhResources.types";
import { ChipFilter, AdsTextField } from "@nswdoe/doe-ui-core";
import { mapGetters } from "vuex";
import { Classroom } from "@/types/Classroom.types";
export interface Methods {
  resourceSelected(resourceInfo: ResourceSelected): void;
  searchDrhWithQuery(): void;
  resetFilters(): void;
}
interface Computed {
  drhCardItems: ResourceDocument[];
  drhResourceCards: ResourceDocument[];
  filters: DrhFilterApi;
  resourcecategories: ResourceCategory[] | unknown;
}
interface Data {
  drhFilters: unknown | DrhFilterApi;
  searchText: string;
  filterModel: DrhFilterModel;
  selectedResources: ResourceSelected[];
}
interface ResourceSelected {
  resourceId: string;
  value: boolean;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  name: "DrhResources",
  methods: {
    resourceSelected(resourceInfo) {
      const indexOfSelected = this.selectedResources.findIndex(
        (resource) => resource.resourceId == resourceInfo.resourceId
      );

      if (resourceInfo.value == true && indexOfSelected != -1) {
        this.selectedResources[indexOfSelected - 1].value = resourceInfo.value;
      } else if (resourceInfo.value == true) {
        const resoureObj: ResourceSelected = {
          resourceId: resourceInfo.resourceId,
          value: resourceInfo.value,
        };
        this.selectedResources.push(resoureObj);
      } else if (indexOfSelected != -1 && resourceInfo.value == false) {
        this.selectedResources.splice(indexOfSelected, 1);
      }
      const finalResourcesObj = this.drhResourceCards.filter((resource) =>
        this.selectedResources.find(
          ({ resourceId }) => resourceId === resource.document.ItemID
        )
      );
      this.$emit("resourceSelected", finalResourcesObj);
    },
    searchDrhWithQuery() {
      const value = this.filterModel;
      let searchParams = {};
      Object.keys(value).forEach((filterType) => {
        if (value[filterType].length > 0) {
          const filterVal = value[filterType].join(",");
          switch (filterType) {
            case "owner":
              searchParams["Source"] = filterVal;
              break;
            case "format":
              searchParams["Format"] = filterVal;
              break;
            case "stage":
              searchParams["Year"] = filterVal;
              break;
            case "kla":
              searchParams["KLA"] = filterVal;
              break;
            case "type":
              searchParams["ResourceType"] = filterVal;
          }
        }
      });
      if (this.searchText) {
        searchParams["keyword"] = this.searchText;
      }
      this.$store.dispatch("drh/fetchDrh", searchParams);
    },
    resetFilters() {
      this.filterModel = {
        owner: [],
        format: [],
        stage: [],
        kla: [],
        type: [],
      };
    },
  },
  created() {
    let searchParams = {};
    if (this.$props.classInfo && !!this.$props.classInfo.year) {
      this.filterModel.stage = [
        this.$props.classInfo.year.substring(
          this.$props.classInfo.year.indexOf("Year") + 5
        ),
      ];
      searchParams["Year"] = this.filterModel.stage.join(",");
    }
    if (this.$props.classInfo && !!this.$props.classInfo.area) {
      this.filterModel.kla = isArray(this.$props.classInfo.area)
        ? this.$props.classInfo.area
        : [this.$props.classInfo.area];
      searchParams["KLA"] = this.filterModel.kla.join(",");
    }
    this.selectedResources = [];
    if (!isPopulatedArray(this.drhCardItems)) {
      this.$store.dispatch("drh/fetchDrh", searchParams);
    }
    this.$store.dispatch("drh/fetchDrhFilters");
  },
  computed: {
    ...mapGetters({
      drhCardItems: "drh/drhCardItems",
      filters: "drh/drhFilters",
    }),
    drhResourceCards() {
      let drhCards =
        this.drhCardItems && isPopulatedArray(this.drhCardItems)
          ? this.drhCardItems
          : [];
      if (
        this.$props.resourcesAlreadyAdded &&
        isPopulatedArray(this.$props.resourcesAlreadyAdded.drhResources)
      ) {
        const addedResources = this.$props.resourcesAlreadyAdded.drhResources;
        drhCards = drhCards.filter(
          (resourceCard) =>
            !addedResources.find(
              (alreadyAddedCard) =>
                alreadyAddedCard.document.ItemID ===
                resourceCard.document.ItemID
            )
        );
      }
      return drhCards;
    },
    resourcecategories() {
      return this.filters && this.filters.resourcecategories
        ? this.filters.resourcecategories.map((category) => {
            const subtypes = this.filters.resourcetype.filter(
              (resourceType) => {
                return resourceType.resourcecatid[0] == category.id;
              }
            );
            return { id: category.id, name: category.name, children: subtypes };
          })
        : [];
    },
  },
  data() {
    return {
      drhFilters: {
        stagesandyears: [],
        klas: [],
        sources: [],
        owner: [],
        format: [],
        stage: [],
        resourcetype: [],
        resourcecategories: [],
      },
      searchText: "",
      filterModel: {
        owner: [],
        format: [],
        stage: [],
        kla: [],
        type: [],
      },
      selectedResources: [],
    };
  },
  watch: {
    dialogShowOrHide() {
      if (this.$props.classInfo && !!this.$props.classInfo.year) {
        this.filterModel.stage = [
          this.$props.classInfo.year.substring(
            this.$props.classInfo.year.indexOf("Year") + 5
          ),
        ];
      }
      if (this.$props.classInfo && !!this.$props.classInfo.area) {
        this.filterModel.kla = isArray(this.$props.classInfo.area)
          ? this.$props.classInfo.area
          : [this.$props.classInfo.area];
      }
      this.selectedResources = [];
      this.resetFilters();
    },
    filterModel: {
      handler(drhFilterModel) {
        if (
          drhFilterModel &&
          (isPopulatedArray(drhFilterModel.format) ||
            isPopulatedArray(drhFilterModel.kla) ||
            isPopulatedArray(drhFilterModel.owner) ||
            isPopulatedArray(drhFilterModel.stage) ||
            isPopulatedArray(drhFilterModel.type))
        ) {
          this.searchDrhWithQuery();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    AdsTextField,
    ChipFilter,
    DrhResource,
  },
  props: {
    resourcesAlreadyAdded: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dialogShowOrHide: {
      type: Boolean,
      default: false,
    },
    removedContent: {
      type: Object,
      default: () => {
        return {};
      },
    },
    classInfo: {
      type: Object as unknown as Classroom,
      default: () => {
        return {};
      },
    },
  },
});
