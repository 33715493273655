
import { OAuthProfile } from "@/types/User.types";
import Vue from "vue";
import { mapGetters } from "vuex";

interface Data {
  weekdayDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Methods {}

interface Computed {
  profile: OAuthProfile;
}

export default Vue.extend<Data, Methods, Computed>({
  name: "ProfileCard",
  computed: {
    ...mapGetters({
      profile: "userAttributes/profile",
    }),
  },
  data: () => ({
    weekdayDate: new Intl.DateTimeFormat("en-AU", {
      weekday: "long", // For example, "Thursday"
      year: "numeric",
      month: "long", // For example, "August"
      day: "numeric",
      timeZone: "Australia/Sydney",
    }).format(new Date()),
  }),
});
