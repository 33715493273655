import { Alert } from "@/types/Resources/Alert.types";
import Vue, { PropType } from "vue";

// Specifying a 15 second timeout for alerts auto-deletion.
const DELETION_TIMEOUT = 15 * 1000;

export default {
  namespaced: true,
  state: {
    allAlerts: [] as PropType<Alert[]>,
  },
  getters: {
    allAlerts: (state: { allAlerts: Alert[] }) => state.allAlerts,
  },
  mutations: {
    REMOVE_ALERT(state: { allAlerts: Alert[] }, alertToUpdate: Alert) {
      const itemIndex = state.allAlerts.findIndex(
        (alert) => alert.id == alertToUpdate.id
      );
      if (itemIndex != -1) {
        // Grabbing the item and making sure all timeouts are cleared before deleting it.
        const item = state.allAlerts[itemIndex];
        item.timeouts?.forEach((timeout) => clearTimeout(timeout));

        Vue.delete(state.allAlerts, itemIndex);
      }
    },
    ADD_ALERT(state: { allAlerts: Alert[] }, alertToAdd: Alert) {
      const id = state.allAlerts.length.toString();

      // Creates a timeout that will automatically delete the alert after a certain amount of time.
      const autoDeleteTimeout = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - The this object does have a commit method, but it's not typed. Just getting around the issue.
        this.commit("alerts/REMOVE_ALERT", { id });
      }, DELETION_TIMEOUT);

      state.allAlerts.push({
        ...alertToAdd,
        id,
        timeouts: [autoDeleteTimeout],
      });
    },
  },
  actions: {
    removeAlert({ commit }, alert) {
      commit("REMOVE_ALERT", alert);
    },
    addAlert({ commit }, alert) {
      commit("ADD_ALERT", alert);
    },
  },
};
