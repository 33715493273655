import { ResourceDocument } from "./DrhResources.types";

export interface NameAndLink {
  name: string;
  url: string;
}
export interface NameAndId {
  name: string;
  id: string;
}
export interface SelectionTypes {
  drhResources: Array<ResourceDocument>;
  links: Array<string>;
  gDrive: Array<NameAndLink>;
  uploadedFiles: Array<NameAndId>;
}

export interface ContentItems {
  id: number;
  title: string;
  type: ClassroomResourceType;
  content: SelectionTypes;

  instructions: string;
  icon: string;
}

export interface ContentTypes {
  title: ClassroomResourceType;
  value: ClassroomResourceType;
}

export enum ClassroomResourceType {
  QUIZ = "Quiz",
  ASSIGNMENT = "Assignment",
  READING_MATERIAL = "Reading Material",
  QUESTION = "Question",
  TOPIC = "Topic",
}
