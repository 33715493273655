<template>
  <div class="add-url-dialog__content">
    <v-row class="mb-4">
      <v-col>
        <h3 class="mb-2">Upload Image</h3>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      align="center"
    >
      <v-col
        cols="12"
        class="d-flex flex-row justify-space-between px-0"
      >
        <v-file-input
          label="Select your file"
          outlined
          placeholder="upload image"
          :class="{
            'mr-6': $vuetify.breakpoint.smAndUp,
            'mr-3': $vuetify.breakpoint.xsOnly,
          }"
          accept="image/png, image/jpeg, image/bmp"
          v-model="files"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UploadImage",
  data() {
    return {
      files: [],
    };
  },
  computed: {
    ...mapGetters({ uploadedPic: "upLoadedS3Url" }),
  },
  watch: {
    files(val) {
      if (val) {
        this.UploadS3Pic(val);
      }
    },
  },
  methods: {
    async UploadS3Pic(pictureObj) {
      let pictureS3Url = "";
      await this.$store.dispatch("imageUpload", pictureObj);
      pictureS3Url =
        this.uploadedPic && typeof this.uploadedPic == "object"
          ? this.uploadedPic
          : "";
      await this.$listeners.input({ type: "upload", url: pictureS3Url });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.add-url-dialog__content {
  padding: 0 40px 24px 40px;
  position: relative;
  width: 100%;
  max-height: 500px;

  span {
    color: $ads-dark;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
