import { AxiosInstance } from "axios";

/**
 * Inject a response interceptor to handle errors from API calls
 * @param instance - Axios instance to inject the interceptor into
 */
export const error_handling_interceptor = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
  );
};
