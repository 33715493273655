import API from "@/utils/api";
import { API_RESOURCES } from "@/utils/constants";
import { createAuthHeaders } from "../helpers";

/**
 * An API call to fetch school information based on user's locations property
 * @param obj.params.codes - A stringified array of school codes
 */
export const fetchSchoolInfo = ({
  params,
}: {
  params: Record<string, string> & { codes: string };
}) => {
  return API({
    method: API_RESOURCES.GET_SCHOOL_INFO.method,
    url: API_RESOURCES.GET_SCHOOL_INFO.url,
    params,
    mock: "data/schoolsLookup",
  });
};

export const fetchRollSubjectClass = () => {
  return API({
    method: API_RESOURCES.GET_ROLL_SUBJECT_CLASS.method,
    url: API_RESOURCES.GET_ROLL_SUBJECT_CLASS.url,
    mock: "data/rollSubjectClass",
  });
};

export const fetchTopics = (userId = "") => {
  return API({
    method: "GET",
    mock: "data/topics",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });
};

export const fetchSections = (userId = "") => {
  return API({
    method: "GET",
    mock: "data/sections",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });
};

export const fetchLearningArea = (userId = "") => {
  return API({
    method: "GET",
    mock: "data/learningArea",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });
};

export const fetchClassHelpers = (userId = "") => {
  return API({
    method: "GET",
    mock: "data/classHelpers",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });
};

export default {
  fetchSchoolInfo,
  fetchRollSubjectClass,
  fetchTopics,
  fetchSections,
  fetchLearningArea,
  fetchClassHelpers,
};
