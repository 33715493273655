import {
  ClassPeopleStaff,
  ClassPeopleStudent,
} from "@/types/ClassPeople.types";
import { StaffTableItem, StudentTableItem } from "./People.types";

/**
 * Add properties required for StudentTableItem.
 */
export const studentToTableItem = (
  student: ClassPeopleStudent
): ClassPeopleStudent & StudentTableItem => ({
  name: student.givenName + " " + student.familyName,
  year: "",
  school: "",
  ...student,
});

/**
 * Add properties required for StaffTableItem.
 */
export const staffToTableItem = (
  staff: ClassPeopleStaff
): ClassPeopleStaff & StaffTableItem => ({
  name: [staff.givenName, staff.familyName].join(" "),
  role: "",
  school: "",
  ...staff,
});

/**
 * Filter function to filter StudentTableItem objects by search string.
 * @param searchStr - A search string from our search bar input
 * @returns - A filter function, that in turn returns a boolean
 */
export const filterStudentBySearch =
  (searchStr: string) =>
  ({ name, school, updatedBy, status, year }: StudentTableItem): boolean =>
    [name, status, year, school, updatedBy].some(
      (attribute) =>
        attribute.toLowerCase().search(searchStr.toLowerCase()) > -1
    );

/**
 * Filter function to filter StaffTableItem objects by search string.
 * @param searchStr - A search string from our search bar input
 * @returns - A filter function, that in turn returns a boolean
 */
export const filterStaffBySearch =
  (searchStr: string) =>
  ({ name, role, school, updatedBy }: StaffTableItem): boolean =>
    [name, role, school, updatedBy].some(
      (attribute) =>
        attribute.toLowerCase().search(searchStr.toLowerCase()) > -1
    );

/**
 * Filter function to remove students from autoStudents array
 * if they are already present in deltaStudents array.
 */
export const autoDedupStudent =
  (deltaStudents: ClassPeopleStudent[]) =>
  (autoStudent: ClassPeopleStudent): boolean =>
    deltaStudents.find(
      (deltaStudent) =>
        deltaStudent.email === autoStudent.email &&
        deltaStudent.srn === autoStudent.srn
    ) === undefined;
