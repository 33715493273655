import api from "../api";

export const loadGoogleApis = (_callback: any) => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  document.body.appendChild(script);
  script.onload = () => _callback();
};

export const loadGoogleClient = (_callback: any) => {
  const apiScript = document.createElement("script");
  apiScript.src = "https://accounts.google.com/gsi/client";
  document.body.appendChild(apiScript);
  apiScript.onload = () => _callback();
};

export const googleDriveUpload = (fileObj: FileList, token: string) => {
  return api.googleDriveApi.uploadMultipart(fileObj, token);
};
