<template>
  <v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
    <NavigationDrawer :items="navDrawerItems" />

    <Notifications style="top: 64px" :notificationData="notificationsData" />

    <AppBar
      color="primary"
      appName="my-nsw-gov"
      title="Class Dashboard"
      :showNavIcon="true"
      :showNotifications="true"
      :enableHomeLink="false"
    >
      <template #appIcon>
        <AppIcon
          appName="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>

      <template #profile>
        <Profile
          :givenName="profile.given_name"
          :surname="profile.family_name"
          :jobTitle="profile.title"
          :updateProfile="false"
          :changePassword="false"
          :updateSecretQuestion="false"
          :portalSettings="false"
          :logout="true"
          :logoutHandler="() => $OAuth.logout()"
        />
      </template>
    </AppBar>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <transition-group name="slide" tag="div" class="appAlerts">
      <Alert
        v-for="(alert, index) in alerts"
        :key="index"
        :showAlert="true"
        :allowDismiss="true"
        class="appAlerts--alert"
        :absolute="true"
        :inPage="true"
        :text="alert.text"
        :subtext="alert.subtext"
        :type="alert.type"
        :actionText="undefined"
        :backgroundColour="true"
        @dismiss="() => dismissAlert(alert)"
      />
    </transition-group>

    <!-- App-wide component -->
    <BlockUI v-if="$store.state.isLoading" />
  </v-app>
</template>

<script>
import {
  AppBar,
  NavigationDrawer,
  Profile,
  AppIcon,
  BlockUI,
  Notifications,
  Alert,
} from "@nswdoe/doe-ui-core";
import notifications from "@/api/data/notifications";
import { mapGetters } from "vuex";
import API from "@/utils/api";

export default {
  name: "App",
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    AppIcon,
    Notifications,
    Alert,
  },
  watch: {
    /**
     * Watching for changes in the OAuth object.
     * If the OAuth object is updated, the Authorization header will be updated.
     */
    $OAuth: {
      handler() {
        const { Authorization } = this.$OAuth.buildHttpHeader();
        API.defaults.headers.common["Authorization"] = Authorization ?? "";
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      alerts: "alerts/allAlerts",
      profile: "userAttributes/profile",
    }),
  },
  methods: {
    dismissAlert(alert) {
      this.$store.dispatch("alerts/removeAlert", alert);
    },
  },
  data() {
    return {
      // Sample data for NavigationDrawer
      navDrawerItems: [
        {
          title: "Classes",
          icon: "mdi-human-male-board",
          iconOutlined: true,
          route: "/",
          items: [
            {
              title: "Current classes",
              route: "/",
            },
            {
              title: "Archived classes",
              route: "/archive",
            },
          ],
        },
      ],
      // Sample data for Notifications
      notificationsData: notifications,
    };
  },
};
</script>

<style lang="scss">
// use pre-defined ADS scss classes for the whole project, note here the styles are non-scoped in App.vue
@import "./scss/ads";
#adsDialogComponent {
  background-color: #f4f4f7;
}
.container {
  padding: 12px 30px;

  .container {
    padding: 0;
  }
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.appAlerts {
  bottom: 20px;
  left: 20px;
  display: flex;
  max-width: max(35vw, 350px);
  margin-left: 0;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 5px;
  z-index: 1000;
  float: left;
  position: absolute;
}

// The below rules are for the transition group animations.

// Defines the transition duration for any changes to an element in our transition group
.slide-item {
  transition: all 0.4s;
}

// Defines the entering and leaving animation for the elements in our transition group
.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-out;
}

/**
* Set the initial state for elements that are leaving from the DOM. 
* This slides them from the bottom left corner of the screen.
*/
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translate(-100%, 100%);
}

/**
* Set the final state for elements that are entering to the DOM.
* This slides them to the bottom left corner of the screen.
*/
.slide-enter-to,
.slide-leave {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-leave-active {
  position: absolute;
}

.slide-move {
  transition: transform 0.4s;
}
</style>
