
import Vue from "vue";

import ClassProfile from "@/components/ClassProfile/ClassProfile.vue";
import { HomeMode } from "@/router/props";
import { Classroom, ClassroomStatus } from "@/types/Classroom.types";
import { PropValidator } from "vue/types/options";
import { mapGetters } from "vuex";

interface Props {
  mode: HomeMode;
  classesData: Classroom[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Empty = {};

export default Vue.extend<
  Empty,
  Empty,
  { classrooms: Classroom[]; hasAdminAccess: boolean },
  Props
>({
  computed: {
    ...mapGetters({ hasAdminAccess: "userAttributes/hasAdminAccess" }),
    classrooms() {
      // Removes all items with status of ARCHIVE
      const activeModeFilter = (classroom: Classroom) =>
        classroom.classStatus !== ClassroomStatus.ARCHIVE;

      // Removes all items with status other than ARCHIVE
      const archiveModeFilter = (classroom: Classroom) =>
        classroom.classStatus === ClassroomStatus.ARCHIVE;

      // If mode prop is ACTIVE, then use activeModeFilter, otherwise use archiveModeFilter
      const modeFilter =
        this.mode === HomeMode.ACTIVE ? activeModeFilter : archiveModeFilter;

      return this.classesData.filter(modeFilter);
    },
  },
  props: {
    mode: {
      type: String,
      default: () => HomeMode.ACTIVE,
    } as PropValidator<HomeMode>,
    classesData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    ClassProfile,
  },
});
