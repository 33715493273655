export default [
  {
    id: 1183,
    requestId: "18cf3c77-9ef2-4e72-8043-7a7114810364",
    appName: "StaffPortalApp",
    fromAppName: "Critical Update",
    displayMethod: "",
    emailAddress: "finhs.principal01@tst.det.nsw.edu.au",
    role: "",
    channels: ["other"],
    segments: null,
    messageTitle: "Banner notification testing13",
    messageBody: "COVID Update",
    htmlBody:
      "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
    messageType: "text/plain",
    messageDeeplink: "https://google.com",
    messageDeeplinkType: "essentials",
    receivedDatetime: "2021-08-12T08:11:19Z",
    messageContent: {
      body: "COVID Update",
      data: {
        time: "03:37 PM",
        type: "essentials",
        deeplink: "https://google.com",
      },
      title: "Banner notification testing13",
      htmlBody:
        "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
      messageType: "text/plain",
    },
    payload:
      '{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "",\r\n  "emails" : ["FINHS.Principal01@tst.det.nsw.edu.au","principal.0004@tst.det.nsw.edu.au"],\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "Crtical Update",\r\n  "message": {\r\n    "title": "Banner notification testing13",\r\n    "body": "COVID Update",\r\n "htmlBody": "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target=\'_blank\' href=\'https://sbs.com.au\'>SBS linkDoris test</a></p><B>",\r\n        "data": {\r\n      "deeplink": "https://google.com",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
  },
  {
    id: 1181,
    requestId: "f7ea51f0-bf06-4a34-90ac-8fb4c289ecd4",
    appName: "StaffPortalApp",
    fromAppName: "Critical Update",
    displayMethod: "portal_banner",
    emailAddress: "finhs.principal01@tst.det.nsw.edu.au",
    role: "",
    channels: ["other"],
    segments: null,
    messageTitle: "Banner notification testing13",
    messageBody: "COVID Update",
    htmlBody:
      "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
    messageType: "text/plain",
    messageDeeplink: "https://google.com",
    messageDeeplinkType: "essentials",
    receivedDatetime: "2021-08-12T08:07:13Z",
    messageContent: {
      body: "COVID Update",
      data: {
        time: "03:37 PM",
        type: "essentials",
        deeplink: "https://google.com",
      },
      title: "Banner notification testing13",
      htmlBody:
        "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
      messageType: "text/plain",
    },
    payload:
      '{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "portal_banner",\r\n  "emails" : ["FINHS.Principal01@tst.det.nsw.edu.au","principal.0004@tst.det.nsw.edu.au"],\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "Crtical Update",\r\n  "message": {\r\n    "title": "Banner notification testing13",\r\n    "body": "COVID Update",\r\n "htmlBody": "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target=\'_blank\' href=\'https://sbs.com.au\'>SBS linkDoris test</a></p><B>",\r\n        "data": {\r\n      "deeplink": "https://google.com",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
  },
  {
    id: 1176,
    requestId: "7fccd73a-5de6-4c89-825e-3096b054fd10",
    appName: "StaffPortalApp",
    fromAppName: "MyPL",
    displayMethod: "portal_banner",
    emailAddress: "",
    role: "school.principal",
    channels: ["MYPL_CHANNEL"],
    segments: null,
    messageTitle:
      "Corruption Prevention for Corporate Staff eLearning course expiry reminder",
    messageBody:
      "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL",
    htmlBody:
      "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href='https://sbs.com.au'>SBS link</a></p></B>",
    messageType: "text/plain",
    messageDeeplink: "https://google.com.au",
    messageDeeplinkType: "deeplink type or essential or link type ROLES",
    receivedDatetime: "2021-08-11T06:39:32Z",
    messageContent: {
      body: "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL",
      data: {
        time: "03:37 PM",
        type: "deeplink type or essential or link type ROLES",
        deeplink: "https://google.com.au",
      },
      title:
        "Corruption Prevention for Corporate Staff eLearning course expiry reminder",
      htmlBody:
        "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href='https://sbs.com.au'>SBS link</a></p></B>",
      messageType: "text/plain",
    },
    payload:
      '{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "portal_banner",\r\n    "channels": [\r\n    "MYPL_CHANNEL"\r\n  ],\r\n  "fromAppName": "MyPL",\r\n  "message": {\r\n    "title": "Corruption Prevention for Corporate Staff eLearning course expiry reminder",\r\n    "body": "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3\\/07\\/2021.Please re-enrol as soon as possible.Regards MyPL",\r\n "htmlBody": "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3\\/07\\/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href=\'https://sbs.com.au\'>SBS link</a></p></B>",\r\n        "data": {\r\n      "deeplink": "https://google.com.au",\r\n      "type": "deeplink type or essential or link type ROLES",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  },\r\n  "audience": {\r\n      "type": "everyone",\r\n     "roles": ["SCHOOL.PRINCIPAL", "SCHOOL.TEACHER", "SCHOOL.STUDENT", "DET.CONTRACTOR", "SCHOOL.CORPORATE"]\r\n    }\r\n}\r\n ',
  },
  {
    id: 1174,
    requestId: "c968f995-cd69-4d50-9ff1-7777c31ad89d",
    appName: "StaffPortalApp",
    fromAppName: "Critical Update",
    displayMethod: "portal_banner",
    emailAddress: "finhs.principal01@tst.det.nsw.edu.au",
    role: "",
    channels: ["other"],
    segments: null,
    messageTitle: "Banner notification testing12",
    messageBody: "COVID Update",
    htmlBody:
      "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
    messageType: "text/plain",
    messageDeeplink: "https://google.com",
    messageDeeplinkType: "essentials",
    receivedDatetime: "2021-08-11T04:29:02Z",
    messageContent: {
      body: "COVID Update",
      data: {
        time: "03:37 PM",
        type: "essentials",
        deeplink: "https://google.com",
      },
      title: "Banner notification testing12",
      htmlBody:
        "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target='_blank' href='https://sbs.com.au'>SBS linkDoris test</a></p><B>",
      messageType: "text/plain",
    },
    payload:
      '{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "portal_banner",\r\n  "emails" : ["FINHS.Principal01@tst.det.nsw.edu.au","principal.0004@tst.det.nsw.edu.au"],\r\n    "channels": [\r\n    "other"\r\n  ],\r\n  "fromAppName": "Crtical Update",\r\n  "message": {\r\n    "title": "Banner notification testing12",\r\n    "body": "COVID Update",\r\n "htmlBody": "<B><p>outbreak total soars past 4000 cases as young man dies in NSW.SBS Episodes.<a target=\'_blank\' href=\'https://sbs.com.au\'>SBS linkDoris test</a></p><B>",\r\n        "data": {\r\n      "deeplink": "https://google.com",\r\n      "type": "essentials",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  }\r\n}',
  },
  {
    id: 1163,
    requestId: "94a5786f-c8c3-451e-948e-a9cecd268ae4",
    appName: "StaffPortalApp",
    fromAppName: "MyPL",
    displayMethod: "",
    emailAddress: "",
    role: "school.principal",
    channels: ["MYPL_CHANNEL"],
    segments: null,
    messageTitle:
      "Corruption Prevention for Corporate Staff eLearning course expiry reminder",
    messageBody:
      "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL",
    htmlBody:
      "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href='https://sbs.com.au'>SBS link</a></p></B>",
    messageType: "text/plain",
    messageDeeplink: "https://google.com.au",
    messageDeeplinkType: "deeplink type or essential or link type ROLES",
    receivedDatetime: "2021-07-01T08:12:21Z",
    messageContent: {
      body: "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL",
      data: {
        time: "03:37 PM",
        type: "deeplink type or essential or link type ROLES",
        deeplink: "https://google.com.au",
      },
      title:
        "Corruption Prevention for Corporate Staff eLearning course expiry reminder",
      htmlBody:
        "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3/07/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href='https://sbs.com.au'>SBS link</a></p></B>",
      messageType: "text/plain",
    },
    payload:
      '{\r\n  "applicationName": "StaffPortalApp",\r\n  "displayMethod" : "",\r\n    "channels": [\r\n    "MYPL_CHANNEL"\r\n  ],\r\n  "fromAppName": "MyPL",\r\n  "message": {\r\n    "title": "Corruption Prevention for Corporate Staff eLearning course expiry reminder",\r\n    "body": "Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3\\/07\\/2021.Please re-enrol as soon as possible.Regards MyPL",\r\n "htmlBody": "<B><p>Dear Doris Justin Wilson,You have previously completed Corruption Prevention for Corporate Staff eLearning course. This enrolment expires on 3\\/07\\/2021.Please re-enrol as soon as possible.Regards MyPL. SBS Episodes. <a href=\'https://sbs.com.au\'>SBS link</a></p></B>",\r\n        "data": {\r\n      "deeplink": "https://google.com.au",\r\n      "type": "deeplink type or essential or link type ROLES",\r\n      "time": "03:37 PM"\r\n    },\r\n    "messageType": "text/plain"\r\n  },\r\n  "audience": {\r\n      "type": "everyone",\r\n     "roles": ["SCHOOL.PRINCIPAL", "SCHOOL.TEACHER", "SCHOOL.STUDENT", "DET.CONTRACTOR", "SCHOOL.CORPORATE"]\r\n    }\r\n}\r\n ',
  },
];
