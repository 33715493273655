import API from "@/utils/api";
import { API_RESOURCES } from "@/utils/constants";
import { createAuthHeaders } from "../helpers";

type SearchPeopleRequest = {
  params: { searchStr?: string };
};

export const SearchPeople = ({ params }: SearchPeopleRequest) => {
  return API({
    method: API_RESOURCES.GET_SEARCH_PEOPLE.method,
    url: API_RESOURCES.GET_SEARCH_PEOPLE.url,
    params,
    mock: "data/people",
    headers: createAuthHeaders({ "Content-Type": "application/json" }),
  });
};

export default { SearchPeople };
