import API from "@/utils/api";
import { API_RESOURCES } from "@/utils/constants";
export const fetchDrh = (paramFilters) => {
  return API({
    method: API_RESOURCES.GET_DRH_RESOURCES.method,
    url: API_RESOURCES.GET_DRH_RESOURCES.url,
    mock: "data/resourcecards",
    params: paramFilters,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const fetchSuggested = () => {
  return API({
    method: API_RESOURCES.GET_DRH_RECOMMEND.method,
    url: API_RESOURCES.GET_DRH_RECOMMEND.url,
    mock: "data/recommend",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const fetchDrhFilters = () => {
  return API({
    method: API_RESOURCES.GET_DRH_FILTERS.method,
    url: API_RESOURCES.GET_DRH_FILTERS.url,
    mock: "data/drhFilters",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export default { fetchDrh, fetchSuggested, fetchDrhFilters };
