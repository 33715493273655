export default [
  {
    year: 1,
    roll: ["1A", "1B"],
  },
  {
    year: 3,
    roll: ["3A", "3B"],
  },
];
