import { PropType } from "vue";

import api from "@/api";

import { URHResources, URHFilters } from "@/types/Resources.types";

export default {
  namespaced: true,
  state: {
    drhCardItems: [] as unknown as PropType<URHResources>,
    drhFilters: {} as unknown as PropType<URHFilters>,
    drhRecommend: [] as unknown as PropType<URHResources>,
  },
  getters: {
    drhCardItems: (state: { drhCardItems: URHResources[] }) =>
      state.drhCardItems,
    drhRecommend: (state: { drhRecommend: URHResources[] }) =>
      state.drhRecommend,
    drhFilters: (state: { drhFilters: URHFilters }) => state.drhFilters,
  },
  mutations: {
    SET_CARDS(state: { drhCardItems: URHResources[] }, cards: URHResources[]) {
      state.drhCardItems = cards;
    },
    SET_RECOMMEND(
      state: { drhRecommend: URHResources[] },
      cards: URHResources[]
    ) {
      state.drhRecommend = cards;
    },
    SET_FILTERS(state: { drhFilters: URHFilters }, filter: URHFilters) {
      state.drhFilters = filter;
    },
  },
  actions: {
    fetchDrh({ commit, rootState }, queryString: string) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }
      api.drhApi
        .fetchDrh(queryString)
        .then((response) => {
          if (response && response.data && response.data.urhResult) {
            commit("SET_CARDS", response.data.urhResult.resources);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    fetchDrhFilters({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }
      api.drhApi
        .fetchDrhFilters()
        .then((response) => {
          if (response && response.data && response.data.urhResult) {
            commit("SET_FILTERS", response.data.urhResult);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    fetchSuggested({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }
      api.drhApi
        .fetchSuggested()
        .then((response) => {
          if (response && response.data && response.data.recommendation) {
            commit("SET_RECOMMEND", response.data.recommendation);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
  },
};
