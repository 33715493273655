type UpdatedByUsername = string;

export interface ClassPeopleStaff {
  detUserId: string;
  email: string;
  givenName: string;
  middleName?: string;
  familyName: string;
  nswEduIdentifier: string;
  /**
   * If this property is filled in, it means the staff has been invited to a class.
   */
  userId?: string;
  updatedBy: "Roll Class" | "Subject Class" | "Timetable" | UpdatedByUsername;
}

/**
 * The status of a student in a class.
 */
export enum StudentStatus {
  /**
   * This means the student is automatically added to the class.
   * Also known as "synchronised students".
   */
  AUTO = "Auto",
  /**
   * This means the student is manually added to the class by staff.
   */
  MANUAL = "Manual",
  /**
   * This means the student has been removed from the class.
   * The student may have been removed from the autoStudents array (WITHOUT MUTATING IT),
   * or removed from the deltaStudents array if they were already present in it (by mutating deltaStudents).
   */
  REMOVED = "Removed",
}

export interface ClassPeopleStudent {
  srn: string;
  givenName: string;
  middleName?: string;
  familyName: string;
  nswEduIdentifier?: string;
  email: string;
  /**
   * If this property is filled in, it means the student has been invited to a class.
   */
  userId?: string;

  status: StudentStatus;
  updatedBy: "Roll Class" | "Subject Class" | "Timetable" | UpdatedByUsername;
}

/**
 * This interface defines the structure of the people who are a part of a class.
 */
export interface ClassPeople {
  staffs: ClassPeopleStaff[];
  /**
   * This is an array of students where autoStudents and deltaStudents are merged together
   * for classroom invitations to be sent. The frontend should not concern itself with this array.
   */
  students: ClassPeopleStudent[];
  /**
   * The students who are automatically added to the class through the backend.
   * This is where "synchronised students" come from.
   *
   * NOTE: THIS ARRAY SHOULD NEVER BE MODIFIED BY THE FRONTEND.
   */
  autoStudents: ClassPeopleStudent[];
  /**
   * This is the comparison between the students from autoStudents
   * and students modified by the frontend.
   *
   * Important notes:
   * - A student that should be removed from autoStudents, will not be removed from autoStudents,
   * but instead will be added to deltaStudents with a status of "REMOVED".
   *
   * - Any student manually added to the class will be added to deltaStudents with a status of "MANUAL",
   * do not mutate autoStudents.
   *
   */
  deltaStudents: ClassPeopleStudent[];
}
