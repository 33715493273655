
import Vue from "vue";

import { AdsButton } from "@nswdoe/doe-ui-core";
import { SearchPeople } from "@/api/modules/peopleSearch";
import {
  ClassPeopleStaff,
  ClassPeopleStudent,
} from "@/types/ClassPeople.types";
import { StudentStatus } from "@/types/ClassPeople.types";
import { mapGetters } from "vuex";
import { isPopulatedArray } from "@/utils/general";

interface AutocompleteItem {
  disabled: boolean;
  name: string;
  givenName: string;
  middleName?: string;
  familyName: string;
  uniqueId: string;
  school: string;
  text: string;
  type: "Student" | "Staff" | "student" | "staff";
  year: string;
  email: string;
}

interface AutocompleteHeader {
  header: string;
}

export default Vue.extend({
  name: "FindPeople",
  computed: {
    ...mapGetters({
      profile: "userAttributes/profile",
    }),
  },
  data: () => ({
    loading: false,
    search: null as string | null,
    // This will be set when the user selects an item from the autocomplete dropdown
    autoCompleteSelection: null,
    // Items for the autocomplete component
    items: [] as (AutocompleteItem | AutocompleteHeader)[],
    // An array of all items selected by the user through the autocomplete dropdown (shown by the data table)
    selected: [] as AutocompleteItem[],
    addMoreClicked: false,
    headers: [
      { text: "Username", value: "name" },
      { text: "Year", value: "year" },
      { text: "School", value: "school" },
      { text: "", value: "remove", align: "right" },
    ],
  }),
  watch: {
    search(searchStr: string) {
      // This will change as the user types in the autocomplete search field
      if (searchStr && searchStr.length > 2) {
        SearchPeople({ params: { searchStr } })
          .then(({ data }) => {
            /**
             * Only overwrite the items array if no items are already present,
             * previous results should still be valid
             */
            if (
              !(data && "people" in data && isPopulatedArray(data.people)) &&
              this.items.length === 0
            ) {
              this.items = [];
              return;
            }

            const data_items: AutocompleteItem[] = data.people.map(
              (item): AutocompleteItem => ({
                text: [
                  item.givenName + " " + item.familyName,
                  item.year,
                  item.school,
                  item.type,
                ]
                  // Filter empty values
                  .filter((value) => !!value)
                  .join(", "),
                disabled: false,
                name: item.givenName + " " + item.familyName,
                ...item,
              })
            );

            /**
             * Set the autocomplete component items,
             * including previously fetched results, and newly fetched results.
             */
            this.items = [...this.items, ...data_items];
          })
          .catch(() => {
            // Do not overwrite the items array if items are already present, previous results should still be valid
            if (this.items.length === 0) {
              this.items = [];
            }
          });
      }
    },
    autoCompleteSelection(selectedValue) {
      // This will change when the user selects an item from the dropdown
      if (selectedValue) {
        const alreadySelected = this.selected.find(
          (item) => item.name === selectedValue.name
        );

        if (!alreadySelected) {
          this.selected.push(selectedValue);
        }

        this.autoCompleteSelection = null;
      }
    },
  },
  methods: {
    removeItem(item) {
      this.selected = this.selected.filter((i) => i.name !== item.name);
    },
    /**
     * Custom filter function for the autocomplete component.
     * This function filters by familyName, givenName, and uniqueId.
     * If the passed in queryText contains spaces, it will be broken up
     * and each word will be matched against the filter values.
     */
    filterItems(
      item: AutocompleteItem | AutocompleteHeader,
      queryText: string
    ) {
      // Let the header always be visible
      if ("header" in item) return true;

      const { familyName, givenName, uniqueId } = item;

      // All key fields in item were undefined, so ignore.
      if (!familyName && !givenName && !uniqueId) {
        return false;
      }

      const filter_values = [
        familyName.toLocaleLowerCase(),
        givenName.toLocaleLowerCase(),
        uniqueId.toLocaleLowerCase(),
      ];

      // Splitting the query text by space to allow for multiple words to be searched.
      const queryTextSplit = queryText.split(" ");

      // Returns true if any of the query text keywords match against any filter values.
      return filter_values
        .filter((value) => !!value)
        .some((item) => {
          return queryTextSplit.some((query) => {
            return item.indexOf(query.toLocaleLowerCase()) > -1;
          });
        });
    },
    confirm() {
      /**
       * Note: The People API has not been implemented yet,
       * so the fields with empty strings below are due
       * to not knowing what the API will return.
       */

      /**
       * Updated by can only be "Roll Class",  "Subject Class" or "Timetable"
       * when a student is initially set on auto students.
       * Any time after that, updatedBy will be the active user's name.
       */
      const updatedBy = `${this.profile.given_name} ${this.profile.family_name}`;

      const staffs = this.selected
        .filter((selected) => selected.type.toLocaleLowerCase() === "staff")
        .map(
          (item): ClassPeopleStaff => ({
            detUserId: "",
            email: item.email,
            familyName: item.familyName,
            givenName: item.givenName,
            updatedBy,
            nswEduIdentifier: "",
          })
        );

      const deltaStudents = this.selected
        .filter((selected) => selected.type.toLocaleLowerCase() === "student")
        .map(
          (item): ClassPeopleStudent => ({
            email: item.email,
            familyName: item.familyName,
            givenName: item.givenName,
            middleName: item.middleName,
            srn: "",
            status: StudentStatus.MANUAL,
            updatedBy,
            nswEduIdentifier: "",
            userId: "",
          })
        );

      console.log({ staffs, deltaStudents });

      this.$emit("find:confirm", { staffs, deltaStudents });
    },
    cancel() {
      this.$emit("find:cancel");
    },
  },
  components: {
    AdsButton,
  },
});
