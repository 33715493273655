export default [
  {
    givenName: "Aaron",
    familyName: "Rodgers",
    type: "Student",
    school: "Castle Hill Public School",
    year: "Year 7",
    email: "aaron.rodgers@det.nsw.edu.au",
  },
  {
    givenName: "Lewis",
    familyName: "Hamilton",
    type: "Staff",
    school: "Castle Hill Public School",
    email: "lewis.hamilton@det.nsw.edu.au",
  },
  {
    givenName: "Alex",
    familyName: "Volkanovski",
    type: "Student",
    school: "Castle Hill Public School",
    year: "Year 9",
    email: "alex.volkanovski@det.nsw.edu.au",
  },
  {
    givenName: "Roger",
    familyName: "Federer",
    type: "Staff",
    school: "Castle Hill Public School",
    email: "roger.federer@det.nsw.edu.au",
  },
];
