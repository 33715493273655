import { isPopulatedArray } from "@/utils/general";

/**
 * Type alias for ChipFilter available for the user to select.
 */
export type ChipFilterItem = {
  id: string;
  name: string;
};

/**
 * Type alias for ChipFilter items that have been selected by the user.
 */
export type ChipFilterSelection = Array<string>;

/**
 * An ADS ChipFilter receives a full @type {ChipFilterItem} object,
 * but returns only a @type {ChipFilterSelection} with the ID of selected items.
 *
 * Pass in array of all items to this helper, and the reactive model value
 * for selected IDs, and this helper will return an array of only the @type {ChipFilterItem} objects
 * that are currently selected.
 * @param items - All the items passed in to a filter.
 * @param selection - The reactive model value used by the filter.
 * @returns An array of the items params that are currently selected.
 */
export const chipSelectionToFilterItem = (
  items: ChipFilterItem[],
  selection: ChipFilterSelection
): ChipFilterItem[] => {
  return selection.reduce((previous, current) => {
    // Look for current selected ID in the array of items
    const findCurrent = items.find((item) => item.id === current);

    // If ID is found in array of items, then push to final result
    if (findCurrent) {
      previous.push(findCurrent);
    }

    return previous;
  }, [] as ChipFilterItem[]);
};

/**
 * The items passed to a ChipFilter should be unique arrays of @type {ChipFilterItem}.
 * This method is a reducer helper that checks the previous array being reduced,
 * if an id and name are already present, it does nothing. If an id and name are
 * not in said previous array, then that item gets pushed to the array.
 * @param previous - The first argument of the reducer function, an array of @type {ChipFilterItem}
 * @param current.id - The ID of the filter item that should be unique in the array
 * @param current.name - the name of the filter item that should be unique in the array
 * @returns An array of ChipFilterItem(s) that should be unique
 * @example
 * this.allClasses.reduce<ChipFilterItem[]>(
 *  (previous, { classId, subject }) =>
 *    createUniqueFilterItems(previous, { id: classId, name: subject }), []
 * );
 */
export const createUniqueFilterItems = (
  previous: ChipFilterItem[],
  { id, name }: { id: string; name: string }
): ChipFilterItem[] => {
  const inFilter = previous.some(
    (chipFilter) => chipFilter.id === id || chipFilter.name === name
  );

  if (!inFilter) {
    previous.push({ id, name });
  }

  return previous;
};

/**
 * Once the users have selected filters with the ChipFilter,
 * this function can be used to filter the available items to return
 * only the ones that match the selection
 * @param filterItems - All items available for the user to select
 * @param currentSelection - Items from filterItems that the user has selected
 * @returns Another method, which can be called by an Array.filter to return only the items that match this current selection
 * @example
 * const years = [{ id: "1", name: "1999" }, { id: "2", name: "2000" }]
 * const selectedYears = ["1"];
 *
 * const isYearSelected = filterItemsBySelection(years, selectedYears);
 * const filteredClasses = this.classes.filter((classroom) =>
 *  // Will only return classes in year 1999
 *  isYearSelected(classroom.classYear)
 * );
 */
export const createSelectionFilter = (
  filterItems: ChipFilterItem[],
  currentSelection: ChipFilterSelection
): ((value: string) => boolean) => {
  // If selection is not populated, skip filtering
  const isPopulated = isPopulatedArray(currentSelection);
  if (!isPopulated) return () => true;

  // Converting a ChipFilterSelection back to its full ChipFilterItem
  const selectedValues = chipSelectionToFilterItem(
    filterItems,
    currentSelection
  )
    // Using a flatMap so that we can filter either by chip ID or by chip name value
    .flatMap(({ name, id }) => [name, id]);

  return (value: string): boolean => selectedValues.includes(value);
};
