import API from "@/utils/api";

import { API_RESOURCES } from "@/utils/constants";
import { isPopulatedArray } from "@/utils/general";

export const getGoogleAuthToken = () => {
  return API({
    method: API_RESOURCES.GET_GOOGLE_API_TOKEN.method,
    url: API_RESOURCES.GET_GOOGLE_API_TOKEN.url,
    mock: "data/googleAuthToken",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const uploadMultipart = async (
  fileObj: FileList,
  access_token: string
) => {
  let metadata = {};
  if (isPopulatedArray(fileObj)) {
    metadata = {
      name: fileObj[0].name, // Filename at Google Drive
      mimeType: fileObj[0].type,
    };
  }

  const form = new FormData();
  form.append(
    "metadata",
    new Blob([JSON.stringify(metadata)], {
      type: "application/json; charset=UTF-8",
    }),
    fileObj[0].name
  );

  form.append(
    "media",
    new Blob([fileObj[0]], {
      type: fileObj[0].type,
    })
  );

  return API({
    method: API_RESOURCES.UPLOAD_FILES.method,
    url: API_RESOURCES.UPLOAD_FILES.url,
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json",
    },
    data: form,
  });
  /* commenting out for now will enable if required public url
    .then((res) => {
    const fileId = res.data.id;
    return API({
      method: "POST",
      url: [
        "https://www.googleapis.com/drive/v3/files",
        fileId,
        "permissions",
      ].join("/"),
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        role: "reader",
        type: "anyone",
      }),
    }).then(() => {
      return API({
        method: "GET",
        url: ["https://www.googleapis.com/drive/v3/files", fileId].join("/"),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        params: { fields: "webViewLink, webContentLink" },
      });
    });
  });*/
};
export default { getGoogleAuthToken, uploadMultipart };
