/**
 * Enumeration with display modes the Home component can use.
 */
export enum HomeMode {
  // Displays all classes that are not archived
  ACTIVE = "ACTIVE",
  // Displays only classes that are archived
  ARCHIVE = "ARCHIVE",
}

interface Props {
  /**
   * The default component is the main component where a list of Classroom cards is displayed.
   */
  default: { mode: HomeMode };
  /**
   * The search component is the top component with the search bar and action buttons to the side.
   */
  search: { mode: HomeMode };
}

/**
 * Props for the components at the "/" route.
 */
export const HomeProps: Props = {
  default: { mode: HomeMode.ACTIVE },
  search: { mode: HomeMode.ACTIVE },
};

/**
 * Props for the components at the "/archive" route.
 */
export const HomeArchiveProps: Props = {
  default: { mode: HomeMode.ARCHIVE },
  search: { mode: HomeMode.ARCHIVE },
};
