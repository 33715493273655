<template>
  <div
    class="grow"
    style="max-width: 50%"
  >
    <v-row class="image__row mb-5">
      <v-col
        cols="12"
        :class="{ image__col: true, 'image__col--mobile': regularUrl }"
      >
        <v-img
          v-if="regularUrl"
          :src="regularUrl"
          :aspect-ratio="1.85"
        >
          <v-btn
            icon
            class="edit-button"
            @click.stop="showDialog = true"
            aria-label="edit image"
          >
            <FeatureIcon
              :size="$vuetify.breakpoint.xsOnly ? '36' : '48'"
              icon="mdi-pencil-outline"
            />
          </v-btn>
          <template #alt> No Pic available to show </template>
        </v-img>
        <template v-else>
          <v-responsive :aspect-ratio="1.85">
            <v-card
              role="button"
              class="image-area"
              outlined
              @click="showDialog = true"
              @keyup.enter="showDialog = true"
            >
              <v-card-text class="card-content">
                <v-icon class="add-image--icon">
                  mdi-chart-box-plus-outline
                </v-icon>
              </v-card-text>
            </v-card>
          </v-responsive>
        </template>
      </v-col>
    </v-row>
    <Dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :openDialog="showDialog"
      title=" Add an image"
      sub-title="Choose an image for your resource card."
      icon="mdi-image-plus"
      :width="
        $vuetify.breakpoint.mdAndUp
          ? 1200
          : $vuetify.breakpoint.smAndUp
          ? 600
          : 360
      "
    >
      <v-divider />
      <template slot="text">
        <div class="tab-content__wrapper">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="10"
                class="pt-0"
              >
                <v-tabs
                  slider-color="#CE0037"
                  color="#121212"
                  background-color="transparent"
                >
                  <v-tab
                    @click="showUnsplashLibrary"
                    class="page--tab"
                    :style="[
                      isUnsplashLibrary
                        ? { 'border-bottom': '4px solid #CE0037 !important' }
                        : { border: 'none' },
                    ]"
                  >
                    UNSPLASH
                  </v-tab>
                  <v-tab
                    @click="showImageUrl"
                    class="page--tab"
                    :style="[
                      isImageUrl
                        ? { 'border-bottom': '4px solid #CE0037 !important' }
                        : { border: 'none' },
                    ]"
                  >
                    URL
                  </v-tab>
                  <v-tab
                    @click="showImageUpload"
                    class="page--tab"
                    :style="[
                      isImageUpload
                        ? { 'border-bottom': '4px solid #CE0037 !important' }
                        : { border: 'none' },
                    ]"
                  >
                    UPLOAD
                  </v-tab>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <v-row v-show="isUnsplashLibrary">
              <UnsplashDialogContent
                v-bind="$attrs"
                v-on="$listeners"
              />
            </v-row>
            <v-row v-show="isImageUrl">
              <AddFromUrl
                v-bind="$attrs"
                v-on="$listeners"
              />
            </v-row>
            <v-row v-show="isImageUpload">
              <UploadImage
                v-bind="$attrs"
                v-on="$listeners"
              />
            </v-row>
          </v-card-text>
          <v-divider class="mb-6" />
          <v-card-actions class="pa-4 pt-0">
            <v-spacer />
            <AdsButton
              button-text="Cancel"
              tertiary
              @click="showDialog = false"
            />
            <AdsButton
              button-text="Done"
              class="btn-dark"
              @click="showDialog = false"
            />
          </v-card-actions>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FeatureIcon, AdsButton, Dialog } from "@nswdoe/doe-ui-core";
import UploadImage from "./UploadImage.vue";
import UnsplashDialogContent from "./UnsplashDialogContent.vue";
import AddFromUrl from "./AddFromUrl.vue";

export default {
  name: "AddNewImage",
  inheritAttrs: false,
  components: {
    UploadImage,
    UnsplashDialogContent,
    FeatureIcon,
    Dialog,
    AdsButton,
    AddFromUrl,
  },
  data() {
    return {
      isImageUpload: false,
      isUnsplashLibrary: true,
      isImageUrl: false,
      showDialog: false,
    };
  },
  computed: {
    regularUrl() {
      return this.$attrs.value.url;
    },
  },
  methods: {
    showImageUpload(event) {
      if (event.target.innerText === "UPLOAD") {
        this.isImageUpload = true;
        this.isUnsplashLibrary = false;
        this.isImageUrl = false;
      }
    },
    showUnsplashLibrary(event) {
      if (event.target.innerText === "UNSPLASH") {
        this.isImageUpload = false;
        this.isUnsplashLibrary = true;
        this.isImageUrl = false;
      }
    },
    showImageUrl(event) {
      if (event.target.innerText === "URL") {
        this.isImageUpload = false;
        this.isUnsplashLibrary = false;
        this.isImageUrl = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.tab-content__wrapper {
  margin-top: 50px;

  .v-row {
    width: 100%;
  }
}

.image__row {
  .image__col {
    .v-image {
      border-radius: 4px;

      .edit-button {
        margin: 12px;

        &:focus {
          outline: none !important;
        }

        &:focus-visible {
          outline: 10px auto $ads-navy !important;
        }
      }
    }

    .image-area {
      border: 2px dashed $ads-navy;
      min-height: 100%;
      display: flex;

      &:focus {
        &::before {
          opacity: 0;
        }
      }

      &:focus-visible {
        border-width: 4px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .add-image--icon {
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .image__row {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .image__row {
    margin-bottom: 20px;

    .image__col {
      &.image__col--mobile {
        padding: 0;
      }

      .v-image {
        border-radius: 0;

        .edit-button {
          margin: 4px;
        }
      }

      .image-area {
        .card-content {
          .add-image--icon {
            font-size: 48px;
          }
        }
      }
    }
  }
}
</style>
