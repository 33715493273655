import { render, staticRenderFns } from "./ClassProfile.vue?vue&type=template&id=31b21126&scoped=true&"
import script from "./ClassProfile.vue?vue&type=script&lang=ts&"
export * from "./ClassProfile.vue?vue&type=script&lang=ts&"
import style0 from "./ClassProfile.vue?vue&type=style&index=0&id=31b21126&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b21126",
  null
  
)

export default component.exports