var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (Array.isArray(_vm.suggestedResources) && _vm.suggestedResources.length > 0)?_c('div',[_c('div',{staticClass:"title mx-10",staticStyle:{"color":"#002664"}},[_vm._v("Suggested for you")]),_c('div',{staticClass:"pt-2 d-flex justify-space-between mx-10",staticStyle:{"color":"#495054"}},[_c('div',{staticStyle:{"color":"#495054"}},[_vm._v("Based on your classroom set-up")]),(!_vm.$props.showOptions)?_c('div',{staticClass:"subtitle-2"},[_c('a',{staticClass:"text-decoration-none",staticStyle:{"color":"#407ec9"},on:{"click":function($event){return _vm.$emit('showMoreResources')}}},[_vm._v("See more suggestions")])]):_vm._e()]),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleResize),expression:"handleResize"}],staticClass:"wrapper"},[_c('div',{ref:"slider-content__wrapper",staticClass:"content__wrapper",style:({
        'min-width': '100%',
        'margin-left': _vm.$props.negativeMargin,
        'margin-right': _vm.$props.negativeMargin,
      })},[(
          _vm.isOverflowing && _vm.scrollPosition > 0 && _vm.$vuetify.breakpoint.smAndUp
        )?_c('div',{staticClass:"prev-arrow__wrapper"},[_c('v-btn',{attrs:{"fab":"","color":"#fff","small":""},on:{"click":function($event){return _vm.handleClick('prev')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-left ")])],1)],1):_vm._e(),_c('ul',{ref:"slider-content__container",staticClass:"content__container",style:({ padding: `0 ${_vm.margin - 12}px` }),on:{"scroll":_vm.handleScroll}},[_vm._l((_vm.suggestedResources),function(resource,index){return _c('li',{key:index,staticClass:"pa-3 pr-1 pb-1"},[_c('v-card',{staticStyle:{"max-width":"290px","height":"284px","max-height":"284px","overflow-wrap":"unset","white-space":"nowrap"}},[_c('v-img',{attrs:{"height":"150px","width":"290px","src":resource.ThumbnailImage,"aspect-ratio":"0.8"}},[(_vm.$props.showOptions)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"left":"83%","top":"9%","opacity":"0.8"},attrs:{"dark":"","fab":"","small":"","color":"#ffffff"}},on),[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("add_circle_outline")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"width":"300px"},attrs:{"bottom":""}},[_c('v-list-item',{staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Add to:")])]),_vm._l((_vm.contentCategory),function(typeOfContent,indexOfType){return _c('v-list-item',{key:'index_' + indexOfType,style:({
                        'border-top':
                          indexOfType === _vm.contentCategory.length - 1
                            ? '1px solid #ccc'
                            : '',
                      }),on:{"click":function($event){return _vm.addResource(resource, true, typeOfContent.title)}}},[_vm._v(_vm._s(typeOfContent.title))])})],2)],1):_c('v-btn',{staticStyle:{"left":"83%","top":"9%","opacity":"0.8"},attrs:{"dark":"","fab":"","small":"","color":"#ffffff"},on:{"click":function($event){return _vm.addResource(resource, false, '')}}},[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v("add_circle_outline")])],1)],1),_c('v-card-title',{staticClass:"pa-3",staticStyle:{"line-height":"1rem"}},[_c('div',{staticClass:"d-flex grow flex-column",staticStyle:{"width":"270px"}},[_c('div',{staticClass:"subtitle-2",staticStyle:{"color":"#407ec9","min-width":"0px","overflow":"hidden","text-overflow":"ellipsis"},attrs:{"title":resource.Name,"aria-label":resource.Name}},[_vm._v(" "+_vm._s(resource.Name)+" ")]),_c('div',{staticClass:"title-2 pt-3 font-weight-bold",staticStyle:{"color":"#002664"}},[_vm._v(" "+_vm._s(resource.ResourceType)+" ")]),_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"body-2",staticStyle:{"color":"#4c4f55"}},[_vm._v(" Updated: "+_vm._s(_vm.formatResourceUpdated(new Date(resource.DateModified)))+" ")]),_c('div',{staticClass:"d-flex pt-2 justify-space-between"},[_c('div',{staticClass:"body-2 align-self-center",staticStyle:{"color":"#4c4f55"}},[_vm._v(" Years: "+_vm._s(resource.Year?.join(","))+" ")]),(!!resource.ResourceURL)?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","height":"20","width":"20","href":resource.ResourceURL,"target":"_blank"}},[_c('v-icon',{staticStyle:{"color":"#002664"},attrs:{"size":"15"}},[_vm._v("open_in_new")])],1)],1):_vm._e()])])])])],1)],1)})],2),(
          (_vm.isOverflowing &&
            _vm.scrollPosition < _vm.endPosition &&
            _vm.$vuetify.breakpoint.smAndUp) ||
          (_vm.isOverflowing && _vm.endPosition === 0 && _vm.$vuetify.breakpoint.smAndUp)
        )?_c('div',{staticClass:"next-arrow__wrapper"},[_c('v-btn',{attrs:{"fab":"","color":"#fff","small":""},on:{"click":function($event){return _vm.handleClick('next')}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)],1):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }