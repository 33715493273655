export const createAuthHeaders = (header) => {
  const accessToken = localStorage.getItem("access_token");
  const tokenType = localStorage.getItem("token_type");
  const idToken = localStorage.getItem("id_token");
  const authHeaders =
    accessToken && tokenType && idToken
      ? {
          Authorization: tokenType + " " + accessToken,
          "id-token": idToken,
          "X-Correlation-ID": +new Date(),
        }
      : {};
  return {
    ...authHeaders,
    ...(header || {}),
  };
};
