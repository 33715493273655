
import Vue from "vue";

import { ResourceCard } from "../../../types/DrhResources.types";
export interface Data {
  checkItem: boolean;
}

export interface Computed {
  resourceData: ResourceCard;
}

export interface Methods {
  getDate(date: Date): string;
  callEmit(value: unknown): void;
}
export default Vue.extend<Data, Methods, Computed, unknown>({
  name: "DrhCard",
  data() {
    return {
      checkItem: false,
    };
  },
  watch: {
    checkItem(value) {
      this.callEmit(value);
    },
  },
  methods: {
    getDate: (date) => {
      return new Intl.DateTimeFormat("en-AU", {
        year: "numeric",
        month: "long", // For example, "August"
        day: "numeric",
        timeZone: "Australia/Sydney",
      }).format(new Date(date));
    },
    callEmit(value) {
      const resourceId = this.resourceData.ItemID;
      this.$emit("resourceCardClick", { resourceId, value });
    },
  },
  computed: {
    resourceData() {
      return this.$props.resource ? this.$props.resource : null;
    },
  },
  props: {
    resource: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
