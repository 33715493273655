/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import classApi from "./modules/classApi";
import attributeApi from "./modules/userAttributes";
import essentials from "./modules/essentials";
import schoolsLookup from "./modules/schoolsLookup";
import drhApi from "./modules/drhApi";
import googleDriveApi from "./modules/googleDriveApi";
export default {
  drhApi,
  classApi,
  attributeApi,
  essentials,
  schoolsLookup,
  googleDriveApi,
};
