import api from "@/api";
import { ClassHelpers } from "@/types/ClassHelpers.types";
import { RollSubjectClass } from "@/types/RollSubjectClass.types";
import { School } from "@/types/School.types";
import { PropType } from "vue";
import Vue from "vue";

/**
 * VueX module for schools data.
 */
export default {
  namespaced: true,
  state: {
    // Using a Record instead of Map because Vue 2 doesn't have support for Map :)
    mappedSchoolInfo: {} as PropType<Record<string, School>>,
    rollSubjectClass: [] as RollSubjectClass[],
    classHelpers: {
      rollClass: [],
      topics: [],
      learningArea: [],
    },
  },
  getters: {
    /**
     * Use this getter to retrieve a school by its code.
     * Tries to grab a @type {School} from the mappedSchoolInfo object,
     * where the object key is the school code.
     * @example
     * ...mapGetters({ findSchoolByCode: "schoolsLookup/findSchoolByCode" });
     * findSchoolByCode("1234");
     */
    findSchoolByCode:
      (state: { mappedSchoolInfo: Record<string, School> }) =>
      (schoolCode: string) =>
        state.mappedSchoolInfo[schoolCode],
    /**
     * Use this getter to retrieve an array of all
     * @type {School} objects in the store.
     */
    findAllSchools: (state: { mappedSchoolInfo: Record<string, School> }) =>
      Object.values(state.mappedSchoolInfo)
        // Sort schools by name in alphabetical order
        .sort((a, b) => a.schoolFullName.localeCompare(b.schoolFullName)),
    classHelpers: (state: { classHelpers: any }) => state.classHelpers,
    rollClass: (state: { classHelpers: any }) => state.classHelpers.rollClass,
    topics: (state: { classHelpers: ClassHelpers }) =>
      state.classHelpers.topics,
    learningArea: (state: { classHelpers: any }) =>
      state.classHelpers.learningArea,
    rollSubjectClass: (state: { rollSubjectClass: RollSubjectClass[] }) =>
      state.rollSubjectClass,
  },
  mutations: {
    SET_SCHOOL_INFO(
      state: { mappedSchoolInfo: Record<string, School> },
      schools: School[]
    ) {
      for (const school of schools) {
        Vue.set(state.mappedSchoolInfo, school.schoolCode, school);
      }
    },
    SET_CLASSHELPERS(state: { classHelpers: any }, classHelpers: any) {
      state.classHelpers = classHelpers;
    },
    SET_HELPERBYNAME(state: { classHelpers: any }, helperObj) {
      state.classHelpers[helperObj.name] = helperObj.value;
    },
    SET_ROLL_SUBJECT_CLASSES(
      state: { rollSubjectClass: RollSubjectClass[] },
      rollSubjectClass: RollSubjectClass[]
    ) {
      state.rollSubjectClass = rollSubjectClass;
    },
  },
  actions: {
    async fetchSchoolInfo(
      { commit, rootState, state: { mappedSchoolInfo } },
      locations
    ) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      /**
       * Reducer function to remove location codes that are already in the store.
       * @param previous - An array of locations to query API by
       * @param current - A location string
       */
      const removeAlreadyMapped = (previous: string[], current: string) => {
        // In case current is not in the mappedSchoolInfo, add it to the previous array.
        if (mappedSchoolInfo && !mappedSchoolInfo[current]) {
          previous.push(current);
        }

        return previous;
      };

      const locationsArray: string[] = (
        Array.isArray(locations) ? locations : [locations]
      ).reduce(removeAlreadyMapped, []);

      // If all locations are already in the store, don't make the API call.
      if (locationsArray.length <= 0) return;

      // JSON.stringify because codes needs to be in the format of "["1234", "5678"]"
      const params = { codes: JSON.stringify(locationsArray) };

      await api.schoolsLookup
        .fetchSchoolInfo({ params })
        .then((response) => {
          if (response && response.data && response.data.schools) {
            commit("SET_SCHOOL_INFO", response.data.schools);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    fetchRollSubjectClass({ commit, rootState }) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      // Examples to use the api module:
      api.schoolsLookup
        .fetchRollSubjectClass()
        .then((response) => {
          if (response) {
            console.log("fetchRollSubjectClass", response);
            commit("SET_ROLL_SUBJECT_CLASSES", response.data.rollSubjectClass);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
    fetchClassHelpers({ commit, rootState }, userId) {
      if (!rootState.isLoading) {
        commit("SET_IS_LOADING", true, { root: true });
      }

      // Examples to use the api module:
      api.schoolsLookup
        .fetchClassHelpers(userId)
        .then((response) => {
          if (response) {
            commit("SET_CLASSHELPERS", response.data);
          }
        })
        .catch((error: Error) => console.log(error))
        .finally(() => {
          if (rootState.isLoading) {
            commit("SET_IS_LOADING", false, { root: true });
          }
        });
    },
  },
};
