export default {
  message: "Roll/Subject Classes retrieved successfully",
  rollSubjectClass: [
    {
      schoolCode: "1001",
      classType: "ROLL_CLASS",
      rollorSubjectCode: "2 GREEN",
      yearLevel: "Year 2",
      students: [
        {
          srn: "123456789987654321",
          givenName: "test",
          familyName: "test",
          email: "test.test@det-test.edu.au",
        },
        {
          srn: "111111111111111",
          givenName: "test1",
          familyName: "test1",
          email: "test1.test1@det-test.edu.au",
        },
        {
          srn: "22222222222222",
          givenName: "test2",
          familyName: "test2",
          email: "test2.test2@det-test.edu.au",
        },
      ],
      staffs: [
        {
          detUserId: "test9.test9",
          givenName: "test9",
          familyName: "test9",
          email: "test9.test9@det-test.edu.au",
        },
      ],
    },
  ],
};
