
import Vue from "vue";

import { mapGetters } from "vuex";

import { isPopulatedArray } from "../../../../utils/general";

import { ClassroomResourceType } from "../../../../types/ClassroomContent.types";

import { Data, Methods, Computed } from "./Suggestions.types";

export default Vue.extend<Data, Methods, Computed, unknown>({
  name: "Suggestions",
  created() {
    if (!isPopulatedArray(this.suggestedResources))
      this.$store.dispatch("drh/fetchSuggested");
  },
  computed: {
    ...mapGetters({
      suggestedResources: "drh/drhRecommend",
    }),
    margin() {
      const value = this.$props.negativeMargin.substring(
        1,
        this.$props.negativeMargin.length - 2
      );
      return Math.floor(Number(value));
    },
    contentWidth() {
      let count = this.suggestedResources.length;
      return (Number(this.cardSize) + 24) * count - 24;
    },
    isOverflowing() {
      return this.contentWidth > this.wrapperWidth;
    },
  },
  props: {
    showOptions: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [Number, Boolean],
      default: false,
    },
    row: {
      type: String,
      default: "",
    },
    resources: {
      type: Array,
      default: () => [],
    },
    negativeMargin: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      contentCategory: [
        {
          title: ClassroomResourceType.ASSIGNMENT,
          value: ClassroomResourceType.ASSIGNMENT,
        },
        {
          title: ClassroomResourceType.QUIZ,
          value: ClassroomResourceType.QUIZ,
        },
        {
          title: ClassroomResourceType.READING_MATERIAL,
          value: ClassroomResourceType.READING_MATERIAL,
        },
        {
          title: ClassroomResourceType.QUESTION,
          value: ClassroomResourceType.QUESTION,
        },
        {
          title: ClassroomResourceType.TOPIC,
          value: ClassroomResourceType.TOPIC,
        },
      ],
      cardSize: this.$vuetify.breakpoint.smAndUp ? "370" : "300",
      scrollPosition: 0,
      endPosition: 0,
      wrapperWidth: 0,
    };
  },
  watch: {
    $route() {
      setTimeout(() => {
        const el: HTMLDivElement | any = this.$refs["slider-content__wrapper"];
        if (el) {
          this.wrapperWidth = el.offsetWidth;
        }
      }, 500);
      const containerEl: HTMLBodyElement | any =
        this.$refs["slider-content__container"];
      containerEl?.scrollTo({
        left: this.scrollPosition,
      });
    },
  },
  methods: {
    addResource(resourceObj, isNewCategory, categoryType = "") {
      this.$emit("attachSuggested", {
        isNewCategory,
        resourceObj: [{ document: resourceObj }], //adding document property to be in sync with drhResources
        categoryType,
      });
    },
    handleScroll(e) {
      const el = e.srcElement;
      this.scrollPosition = Math.ceil(el.scrollLeft);
    },
    handleClick(direction) {
      const containerEl: HTMLDivElement | any =
        this.$refs["slider-content__container"];
      const maxScrollLeft = containerEl.scrollWidth - containerEl.clientWidth;
      const cardAndPaddingSize = Number(this.cardSize) + 24;
      let distanceToScroll;
      if (this.$vuetify.breakpoint.mdAndUp) {
        distanceToScroll = cardAndPaddingSize * 2;
      } else {
        distanceToScroll = cardAndPaddingSize;
      }
      let scrollPosition = this.scrollPosition;
      if (direction === "prev") {
        if (scrollPosition - distanceToScroll < 0) {
          scrollPosition = 0;
        } else {
          scrollPosition = scrollPosition - distanceToScroll;
        }
      }
      if (direction === "next") {
        if (scrollPosition + distanceToScroll > maxScrollLeft) {
          scrollPosition = maxScrollLeft;
        } else {
          scrollPosition = scrollPosition + distanceToScroll;
        }
      }

      this.endPosition = maxScrollLeft;
      containerEl.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    },
    formatResourceUpdated(date) {
      return new Intl.DateTimeFormat("en-AU", {
        year: "numeric",
        month: "long", // For example, "August"
        day: "numeric",
        timeZone: "Australia/Sydney",
      }).format(date);
    },
    handleResize() {
      setTimeout(() => {
        const el: HTMLDivElement | any = this.$refs["slider-content__wrapper"];
        if (el) {
          this.wrapperWidth = el.offsetWidth;
        }
        const containerEl: HTMLDivElement | any =
          this.$refs["slider-content__container"];
        if (containerEl) {
          this.endPosition = containerEl.scrollWidth - containerEl.clientWidth;
        }
      }, 500);
    },
  },
  mounted() {
    this.$nextTick(() => {
      const el: HTMLDivElement | any = this.$refs["slider-content__wrapper"];
      if (el) {
        this.wrapperWidth = el.offsetWidth;
      }
    });
  },
});
