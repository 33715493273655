var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"top_info d-flex pt-7 pb-5"},[_c('div',{staticClass:"school_info"},[(_vm.classModal.schoolCode != '')?_c('div',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.modalSchool.schoolFullName)+" ")]):_vm._e(),_c('div',{staticClass:"body-2",staticStyle:{"color":"#22272b"}},[_vm._v(" "+_vm._s([_vm.classModal.classYearLevel, _vm.classModal.class, ..._vm.classModal.klas] .filter((entry) => !!entry) .join(", "))+" ")])]),_c('AdsButton',{style:(_vm.selected && _vm.selected.length
          ? 'color:#D7153A !important'
          : 'color:#495054 !important;'),attrs:{"tertiary":"","icon":"remove_circle_outline","disabled":!_vm.selected.length,"buttonText":"Remove"},on:{"click":_vm.removePeople}}),_c('AdsButton',{attrs:{"secondary":"","icon":"group_add","buttonText":"Add people"},on:{"click":function($event){_vm.showFindPeopleDialog = true}}})],1),_c('AdsDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"show-select":"","selectable-key":"name","item-key":"name","search-label":"Find people","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"py-4 px-8 border-1-px",attrs:{"prepend-inner-icon":"mdi-magnify","label":_vm.tableSearchLabel,"aria-label":"search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}},'v-text-field',_vm.$attrs,false),_vm.$listeners)),_c('TabNavigation',{staticClass:"customTabs border-1-px",attrs:{"items":[
          { id: 0, tab: 'Staff' },
          { id: 1, tab: 'Students' },
        ]},on:{"tabChange":_vm.changeTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})]},proxy:true},{key:`item.status`,fn:function({
        // @ts-ignore
        item,
      }){return [_c('div',{class:'subtitle-2 pa-1 status_chip chip_' +
          String(item.status).toLowerCase()},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('Dialog',{attrs:{"openDialog":_vm.showFindPeopleDialog,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"displayCloseBtn":true,"width":_vm.$vuetify.breakpoint.mdAndUp
        ? 1200
        : _vm.$vuetify.breakpoint.smAndUp
        ? 600
        : 360},on:{"close":function($event){_vm.showFindPeopleDialog = false}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"dialog-grid"},[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-icon',[_vm._v("group_add")])],1),_c('h1',[_vm._v("Add people")]),_c('p',[_vm._v(" Any manual changes applied here will be excluded from future automated synchronisation of students ")])],1)]),_c('template',{slot:"text"},[_c('FindPeople',{on:{"find:cancel":function($event){_vm.showFindPeopleDialog = false},"find:confirm":(items) => {
            _vm.addPeople(items);
            _vm.showFindPeopleDialog = false;
          }}})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }