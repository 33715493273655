import API from "@/utils/api";
import { API_RESOURCES } from "@/utils/constants";
import { createAuthHeaders } from "../helpers";
import { Classroom } from "@/types/Classroom.types";

export const fetchClasses = ({ pageSize = 10, pageNum = 1 } = {}) => {
  return API({
    method: API_RESOURCES.GET_CLASSES.method,
    url: API_RESOURCES.GET_CLASSES.url,
    mock: "data/classes",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
    params: { pageSize, pageNum },
  });
};

export const saveClass = ({ data }: { data: Classroom }) => {
  return API({
    method: API_RESOURCES.CREATE_CLASS.method,
    url: API_RESOURCES.CREATE_CLASS.url,
    mock: "data/classes",
    data,
  });
};

export const updateClass = ({ data }: { data: Classroom }) => {
  return API({
    method: API_RESOURCES.EDIT_CLASS.method,
    url: [API_RESOURCES.EDIT_CLASS.url, data.classId].join("/"),
    mock: "data/classes",
    data,
  });
};

export const publishClass = ({ data }: { data: Classroom }) =>
  API({
    method: API_RESOURCES.PUBLISH_CLASS.method,
    url: [API_RESOURCES.PUBLISH_CLASS.url, data.classId, "publish"].join("/"),
    mock: "data/classes",
  });

export const archiveClass = (classroom: Classroom) =>
  API({
    method: API_RESOURCES.ARCHIVE_CLASS.method,
    url: [API_RESOURCES.ARCHIVE_CLASS.url, classroom.classId, "archive"].join(
      "/"
    ),
    mock: "data/classes",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });

export const deleteClass = (classroom: Classroom) =>
  API({
    method: API_RESOURCES.DELETE_CLASS.method,
    url: [API_RESOURCES.DELETE_CLASS.url, classroom.classId].join("/"),
    mock: "data/classes",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });

export default {
  fetchClasses,
  saveClass,
  updateClass,
  archiveClass,
  deleteClass,
  publishClass,
};
