var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grow",staticStyle:{"max-width":"50%"}},[_c('v-row',{staticClass:"image__row mb-5"},[_c('v-col',{class:{ image__col: true, 'image__col--mobile': _vm.regularUrl },attrs:{"cols":"12"}},[(_vm.regularUrl)?_c('v-img',{attrs:{"src":_vm.regularUrl,"aspect-ratio":1.85},scopedSlots:_vm._u([{key:"alt",fn:function(){return [_vm._v(" No Pic available to show ")]},proxy:true}],null,false,1681657950)},[_c('v-btn',{staticClass:"edit-button",attrs:{"icon":"","aria-label":"edit image"},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = true}}},[_c('FeatureIcon',{attrs:{"size":_vm.$vuetify.breakpoint.xsOnly ? '36' : '48',"icon":"mdi-pencil-outline"}})],1)],1):[_c('v-responsive',{attrs:{"aspect-ratio":1.85}},[_c('v-card',{staticClass:"image-area",attrs:{"role":"button","outlined":""},on:{"click":function($event){_vm.showDialog = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.showDialog = true}}},[_c('v-card-text',{staticClass:"card-content"},[_c('v-icon',{staticClass:"add-image--icon"},[_vm._v(" mdi-chart-box-plus-outline ")])],1)],1)],1)]],2)],1),_c('Dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"openDialog":_vm.showDialog,"title":" Add an image","sub-title":"Choose an image for your resource card.","icon":"mdi-image-plus","width":_vm.$vuetify.breakpoint.mdAndUp
        ? 1200
        : _vm.$vuetify.breakpoint.smAndUp
        ? 600
        : 360}},[_c('v-divider'),_c('template',{slot:"text"},[_c('div',{staticClass:"tab-content__wrapper"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"10"}},[_c('v-tabs',{attrs:{"slider-color":"#CE0037","color":"#121212","background-color":"transparent"}},[_c('v-tab',{staticClass:"page--tab",style:([
                    _vm.isUnsplashLibrary
                      ? { 'border-bottom': '4px solid #CE0037 !important' }
                      : { border: 'none' },
                  ]),on:{"click":_vm.showUnsplashLibrary}},[_vm._v(" UNSPLASH ")]),_c('v-tab',{staticClass:"page--tab",style:([
                    _vm.isImageUrl
                      ? { 'border-bottom': '4px solid #CE0037 !important' }
                      : { border: 'none' },
                  ]),on:{"click":_vm.showImageUrl}},[_vm._v(" URL ")]),_c('v-tab',{staticClass:"page--tab",style:([
                    _vm.isImageUpload
                      ? { 'border-bottom': '4px solid #CE0037 !important' }
                      : { border: 'none' },
                  ]),on:{"click":_vm.showImageUpload}},[_vm._v(" UPLOAD ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isUnsplashLibrary),expression:"isUnsplashLibrary"}]},[_c('UnsplashDialogContent',_vm._g(_vm._b({},'UnsplashDialogContent',_vm.$attrs,false),_vm.$listeners))],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isImageUrl),expression:"isImageUrl"}]},[_c('AddFromUrl',_vm._g(_vm._b({},'AddFromUrl',_vm.$attrs,false),_vm.$listeners))],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isImageUpload),expression:"isImageUpload"}]},[_c('UploadImage',_vm._g(_vm._b({},'UploadImage',_vm.$attrs,false),_vm.$listeners))],1)],1),_c('v-divider',{staticClass:"mb-6"}),_c('v-card-actions',{staticClass:"pa-4 pt-0"},[_c('v-spacer'),_c('AdsButton',{attrs:{"button-text":"Cancel","tertiary":""},on:{"click":function($event){_vm.showDialog = false}}}),_c('AdsButton',{staticClass:"btn-dark",attrs:{"button-text":"Done"},on:{"click":function($event){_vm.showDialog = false}}})],1)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }