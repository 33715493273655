
import Vue from "vue";
import { mapGetters } from "vuex";
import { AdsSelect, AdsTextField } from "@nswdoe/doe-ui-core";
import AddNewImage from "../../../AddNewImage/AddNewImage.vue";

import { isPopulatedArray, isArray } from "@/utils/general";
import { Data, Methods, Computed } from "./Step1.types";
import { StudentStatus } from "@/types/ClassPeople.types";

// Temporarily importing JSON data, will later be from API calls
import learningArea from "@/api/data/learningArea";
import topics from "@/api/data/topics";

// Default input value for when no data is available
const no_data = [{ value: "", text: "No data available" }];

export default Vue.extend<Data, Methods, Computed, unknown>({
  name: "Step1",
  data: () => ({
    isValid: false,
  }),
  created() {
    if (!this.classModal.className) {
      this.classModal.className = this.profile.family_name ?? "";
    }
  },
  computed: {
    ...mapGetters({
      schoolsForUser: "schoolsLookup/findAllSchools",
      findSchoolByCode: "schoolsLookup/findSchoolByCode",
      rollSubjectClass: "schoolsLookup/rollSubjectClass",
      classHelpers: "schoolsLookup/classHelpers",
      profile: "userAttributes/profile",
    }),
    schoolSelector() {
      if (!isArray(this.schoolsForUser)) {
        return no_data;
      }

      return this.schoolsForUser.map((school) => ({
        value: school.schoolCode,
        title: school.schoolFullName,
      }));
    },
    yearSelector() {
      if (!isPopulatedArray(this.schoolsForUser)) {
        return ["No data available"];
      }

      const selectedSchool = this.findSchoolByCode(this.classModal.schoolCode);

      let schoolYears = selectedSchool?.schoolYearArray ?? [
        "No data available",
      ];

      const selectedClass = this.rollSubjectClass.find(
        ({ rollorSubjectCode }) => rollorSubjectCode === this.classModal.class
      );

      // If a class is selected before a year, filter the years by that class
      if (!!selectedClass) {
        schoolYears = schoolYears.filter(
          (yearLevel) => yearLevel === selectedClass.yearLevel
        );
      }

      return schoolYears;
    },
    classSelector() {
      if (!isPopulatedArray(this.rollSubjectClass)) {
        return no_data;
      }

      let classes = this.rollSubjectClass;

      // If a year is selected before a class, filter the classes by that year
      if (!!this.classModal.classYearLevel) {
        classes = classes.filter(
          ({ yearLevel, schoolCode }) =>
            yearLevel === this.classModal.classYearLevel &&
            schoolCode === this.classModal.schoolCode
        );
      }

      return classes.map((classroom) => ({
        value: classroom.rollorSubjectCode,
        text: classroom.rollorSubjectCode,
      }));
    },
    topicSelector() {
      // TODO: This data comes from URH API, currently not implemented
      return topics.map((topic) => ({
        value: topic.id,
        text: topic.name,
      }));
      // if (!isPopulatedArray(this.classHelpers.topics)) {
      //   return no_data;
      // }

      // return this.classHelpers.topics.map((topic) => topic.name);
    },
    learningAreaSelector() {
      // TODO: This data comes from URH API, currently not implemented
      return learningArea.map((learningArea) => ({
        value: learningArea.id,
        text: learningArea.name,
      }));
    },
    classModal() {
      return this.$props.classInfo;
    },
  },
  components: {
    AdsSelect,
    AdsTextField,
    AddNewImage,
  },
  watch: {
    isValid(val) {
      this.$emit("active:isValid", val);
    },
    classModal: {
      handler(val) {
        this.$emit("classModalChange", val);
      },
      deep: true,
    },
    /**
     * Update classPeople for Step 2 based on the class that is selected in Step 1.
     */
    "classModal.class": function (newValue) {
      const selectedRollSubjectClass = this.rollSubjectClass.find(
        ({ rollorSubjectCode }) => rollorSubjectCode === newValue
      );

      if (!selectedRollSubjectClass) {
        this.classModal.classPeople = {
          autoStudents: [],
          deltaStudents: [],
          students: [],
          staffs: [],
        };

        return;
      }

      const { classType, students, staffs } = selectedRollSubjectClass;

      const updatedBy =
        classType === "ROLL_CLASS"
          ? "Roll Class"
          : classType === "SUBJECT_CLASS"
          ? "Subject Class"
          : "Timetable";

      this.classModal.classPeople = {
        deltaStudents: [],
        students: [],
        autoStudents: students.map((student) => ({
          ...student,
          status: StudentStatus.AUTO,
          updatedBy,
        })),
        staffs: staffs.map((staff) => ({
          ...staff,
          updatedBy,
        })),
      };
    },
  },
  props: {
    classInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
