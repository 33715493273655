<template>
  <div class="add-url-dialog__content">
    <v-row class="mb-4">
      <v-col>
        <h3 class="mb-2">Upload via URL</h3>
        <span>Be sure to copy and paste the actual web link of the image.</span>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      align="center"
    >
      <v-col
        cols="12"
        class="d-flex flex-row justify-space-between px-0"
      >
        <AdsTextField
          :class="{
            'mr-6': $vuetify.breakpoint.smAndUp,
            'mr-3': $vuetify.breakpoint.xsOnly,
          }"
          label="Image URL"
          placeholder="Paste"
          hide-details
          v-model="imageLink"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AdsTextField } from "@nswdoe/doe-ui-core";

export default {
  name: "AddFromUrl",
  components: {
    AdsTextField,
  },
  data() {
    return {
      imageLink: "",
    };
  },
  watch: {
    imageLink(val) {
      if (val) {
        this.$listeners.input({ type: "url", url: val });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.add-url-dialog__content {
  padding: 0 40px 24px 40px;
  position: relative;
  width: 100%;
  max-height: 500px;

  span {
    color: $ads-dark;
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
