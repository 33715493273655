import { ClassPeople } from "./ClassPeople.types";
import { ContentItems } from "./ClassroomContent.types";

export enum ClassroomStatus {
  DRAFT = "Draft",
  ARCHIVE = "Archived",
  PUBLISH = "Published",
  DELETE = "Deleted",
}

export enum ClassroomType {
  GOOGLE = "GoogleClassroom",
  MICROSOFT = "MICROSOFT",
}

export interface PeopleStaff {
  detUserId: string;
  email: string;
  userId: string;
}

export interface PeopleStudent {
  email: string;
  srn: string;
  userId: string;
}

export interface ClassroomResource {
  resType: string;
  resUrl: string;
}

export interface Classroom {
  /**
   * classRoomOrTeamsId is the id of the class once it has been published to
   * Google Classroom or Microsoft Teams. This property does not exist prior to
   * publishing.
   */
  classRoomOrTeamsId?: string;
  classId: string;

  className: string;
  classType: ClassroomType;
  classPeople: ClassPeople;
  classPicture: { type: "unsplash"; url: string };
  classStatus: ClassroomStatus;
  classContents: ContentItems[];
  classYearLevel: string;

  /**
   * The name of the class from /rollSubjectClass
   * Can come from a ROLL_CLASS, SUBJECT_CLASS or TIMETABLE
   */
  class: string;

  /**
   * Key Learning Area - KLAs
   */
  klas: string[];
  topics: string[];
  schoolCode: string;

  /**
   * Used for backend purposes only
   */
  subjectInfo?: unknown;

  /**
   * When publishing a class,
   * determines wheter it will be provisioned or active in Google Classrooms.
   * @default "ACTIVE"
   */
  gClassPublishStatus?: "PROVISIONED" | "ACTIVE";

  canStudentComments: boolean;

  createdBy: string;
}
