import moduleUnsplash from "@/store/modules/unsplash";

export default {
  message: "Classes retrieved successfully",
  classes: [
    {
      className: "New ENGLISH class",
      classType: "GoogleClassroom",
      classYearLevel: "Year 7",
      schoolCode: "1001",
      classStatus: "Draft",
      klas: ["ENGLISH Vocabulary"],
      topics: ["Industrial Vocabulary", "Software"],
      classPicture: {
        type: "unsplashed",
        url: "something@resourcehub.com.au",
      },
      subjectCode: "Year 7 English",
      classPeople: {
        students: [
          {
            srn: "srn000000000001",
            email: "leon.zhang15@det.nsw.edu.au",
            userId: "107021438866111750863",
          },
          {
            srn: "srn000000000002",
            email: "roger.lee14@det.nsw.edu.au",
            userId: "118088879947219038531",
          },
        ],
        staffs: [
          {
            detUserId: "swati.fursule1",
            email: "swati.fursule1@det.nsw.edu.au",
            userId: "113818857753474343977",
          },
        ],
      },
      classContents: [
        {
          type: "Quiz",
          title: "first quiz",
          instructions: "Read the instructions before the quiz",
          resources: [
            {
              resType: "URH",
              resUrl: "someresource.hub.com",
            },
          ],
        },
      ],
      classId: "a3fbeac3-173c-4041-953f-fcffd2f69b94",
      classRoomOrTeamsId: "603450118463",
    },
    {
      className: "New MATHS class",
      classType: "GoogleClassroom",
      classYearLevel: "Year 12",
      schoolCode: "1003",
      classStatus: "Archived",
      klas: ["MATHS Extension"],
      topics: ["Experiment", "Software"],
      classPicture: {
        type: "unsplashed",
        url: "something@resourcehub.com.au",
      },
      subjectCode: "Year 10 Adv",
      classPeople: {
        students: [
          {
            srn: "srn000000000001",
            email: "leon.zhang15@det.nsw.edu.au",
            userId: "107021438866111750863",
          },
          {
            srn: "srn000000000002",
            email: "roger.lee14@det.nsw.edu.au",
            userId: "118088879947219038531",
          },
        ],
        staffs: [
          {
            detUserId: "swati.fursule1",
            email: "swati.fursule1@det.nsw.edu.au",
            userId: "113818857753474343977",
          },
        ],
      },
      classContents: [
        {
          type: "Quiz",
          title: "first quiz",
          instructions: "Read the instructions before the quiz",
          resources: [
            {
              resType: "URH",
              resUrl: "someresource.hub.com",
            },
          ],
        },
      ],
      classId: "835ba955-6828-433d-8f70-ee032f63eade",
      classRoomOrTeamsId: "603903774746",
    },
    {
      className: "Copy of New MATHS class",
      classType: "GoogleClassroom",
      classYearLevel: "Year 12",
      schoolCode: "1003",
      classStatus: "Archived",
      klas: ["MATHS Extension"],
      topics: ["Experiment", "Software"],
      classPicture: {
        type: "unsplashed",
        url: "something@resourcehub.com.au",
      },
      subjectCode: "Year 10 Adv",
      classPeople: {
        students: [
          {
            srn: "srn000000000001",
            email: "leon.zhang15@det.nsw.edu.au",
            userId: "107021438866111750863",
          },
          {
            srn: "srn000000000002",
            email: "roger.lee14@det.nsw.edu.au",
            userId: "118088879947219038531",
          },
        ],
        staffs: [
          {
            detUserId: "swati.fursule1",
            email: "swati.fursule1@det.nsw.edu.au",
            userId: "113818857753474343977",
          },
        ],
      },
      classContents: [
        {
          type: "Quiz",
          title: "first quiz",
          instructions: "Read the instructions before the quiz",
          resources: [
            {
              resType: "URH",
              resUrl: "someresource.hub.com",
            },
          ],
        },
      ],
      classId: "74f22a37-87d6-4995-b4c1-4f582a013621",
      classRoomOrTeamsId: "603903774746",
    },
    {
      className: "Copy of New MATHS class",
      classType: "GoogleClassroom",
      classYearLevel: "Year 12",
      schoolCode: "1003",
      classStatus: "Archived",
      klas: ["MATHS Extension"],
      topics: ["Experiment", "Software"],
      classPicture: {
        type: "unsplashed",
        url: "something@resourcehub.com.au",
      },
      subjectCode: "Year 10 Adv",
      classPeople: {
        students: [
          {
            srn: "srn000000000001",
            email: "leon.zhang15@det.nsw.edu.au",
            userId: "107021438866111750863",
          },
          {
            srn: "srn000000000002",
            email: "roger.lee14@det.nsw.edu.au",
            userId: "118088879947219038531",
          },
        ],
        staffs: [
          {
            detUserId: "swati.fursule1",
            email: "swati.fursule1@det.nsw.edu.au",
            userId: "113818857753474343977",
          },
        ],
      },
      classContents: [
        {
          type: "Quiz",
          title: "first quiz",
          instructions: "Read the instructions before the quiz",
          resources: [
            {
              resType: "URH",
              resUrl: "someresource.hub.com",
            },
          ],
        },
      ],
      classId: "0afc688c-6bec-411a-8e8c-d5e092205bb9",
      classRoomOrTeamsId: "603914871672",
    },
  ],
};
