import API from "@/utils/api";
import { API_RESOURCES } from "@/utils/constants";
import { createAuthHeaders } from "../helpers";

export const fetchAttributes = (userId = "") => {
  return API({
    method: API_RESOURCES.GET_USER_ATTRIBUTES.method,
    url: API_RESOURCES.GET_USER_ATTRIBUTES.url + "?userId=" + userId,
    mock: "data/attributes",
    headers: createAuthHeaders({
      "Content-Type": "application/json",
    }),
  });
};

export default {
  fetchAttributes,
};
